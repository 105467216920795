import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox,
  FormControl, FormControlLabel,
  FormGroup, FormHelperText, Grid, IconButton, TextField, Typography
} from "@material-ui/core";
import {
  AddCircleOutline, ChevronLeft, ChevronRight, Delete
} from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Divider } from "antd";
import axios from "axios";
import clsx from "clsx";
import * as htmlToImage from "html-to-image";
import { get } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { uploadImage } from "redux/catalogue/action";
import { openSnackbar } from "redux/snackbarStore";
import {
  createHtmlTemplate,
  createJsTemplate,
  eskimiScriptTemplate
} from "utility/TemplateGenerator";
import useStyle from "../ShoppableEdit/useStyle";
import "./ad.scss";
import RightPanel from "./RightPanel";
import classes from "./shoppableCustomize.module.css";

const ShoppableCustomize = (props) => {
  const classes2 = useStyle();
  // const [progress, setProgress] = useState(0);

  const lang = useSelector((state) => state.lang.lang);
  const products = useSelector(
    (state) => state?.shoppableListData?.selectedProductList
  );

  const [step, setStep] = React.useState(0);
  const [slidestep, setSlidestep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [toUpload, setUpload] = useState(null);
  const [logo, setLogo] = useState(null);
  const [theme, setTheme] = useState({ html: "" });
  const [product, setProduct] = useState({});
  const [download, setDownload] = useState(null);
  const [pixels, setPixels] = useState([]);
  const [state, setState] = useState({
    bgColor: "#000",
    backgroundColor1: "#000",
    backgroundColor2: "#f2f2f2",
    backgroundImage: "",
    fontColor: "#ffffff",
    campaignName: "Campaign Description",
    fontFamily: "'Quicksand', sans-serif",

    whatsAppNumber: null,
  });
  const [markets, setMarkets] = useState([]);
  const [marketCheck, setMarketCheck] = useState([false, false, false])
  // const [adsNum, setAdsNum] = useState(0);

  const [states, setStates] = useState([]);
  const [marketss, setMarketss] = useState([]);
  const [index, setIndex] = useState(0);

  const history = useHistory();

  // const [temp, setTemp] = useState("");

  const mediaType = useSelector(
    (state) => state?.shoppableListData?.shoppableMediaType
  );

  useEffect(() => {
    console.log("<= mediaType", mediaType);
  }, [mediaType]);

  // var temp = product.productDescription;
  // console.log(temp.length > 0 && temp.substring(0, 40));

  // const getLength = (data1) => {
  //   return data1.toString().length;
  // }

  const handleColor1 = (e) => {
    const value = e.target.value;
    setState({ ...state, backgroundColor1: value, bgColor: value });
  };

  const handleChange = (e) => {
    if (e.target) setState({ ...state, [e.target.name]: e.target.value });
    else setState({ ...state, fontFamily: e });
  };

  const handlePixelChange = (e) => {
    let pixelString = e.target.value;
    pixelString = pixelString.replace(/\s/g, "");
    console.log(pixelString);
    let pixels = pixelString.split(",");
    pixels = pixels.filter((px) => px.length);

    console.log(pixelString, pixels);
    setPixels(pixels);
  };

  const handleFileChange = (e) => {
    setUpload(e.target.files[0]);
  };

  const getTheme = (themeId) => {
    axios
      .get(`${process.env.REACT_APP_BASE}/shoptheme/${themeId}`)
      .then((response) => {
        let data = response.data;
        if (["1080x1080", "1080x1920", "1200x628"].indexOf(data.size) > -1) {
          setIsImage(true);
        }

        let newData = {
          ...state,
          bgColor: data.backgroundColor,
          backgroundColor1: data.backgroundColor,
          backgroundColor2: data.backgroundColor2,
          backgroundImage: data.backgroundImage ? data.backgroundImage : "",
          fontColor: data.campaignColor,
          fontFamily: data.fontFamily,
          campaignFont: parseInt(data.campaignFont),
          productFont: parseInt(data.productFont),
          ratingColor: data?.ratingColor,
          actionImageSuffix: data?.actionImageSuffix || "",
        };

        setTheme(data);
        console.log("Data", data);
        setState({ ...newData });

        setStates(products?.map((e) => ({ ...newData })));
        setMarketss(products?.map(e => []))
      });
  };

  const getProduct = (productId) => {
    axios
      .get(`${process.env.REACT_APP_BASE}/catalogue/${productId}`)
      .then((response) => {
        let data = response.data;
        setProduct(data);
      });
  };

  useEffect(() => {
    // console.log("<= product", product);
  }, [product]);

  useEffect(() => {
    // console.log("<= selectedProductList", selectedProductList);
  }, [products]);

  const getLogo = async () => {
    try {
      let { data } = await axios.get(
        process.env.REACT_APP_BASE + "/auth/" + localStorage.getItem("user")
      );

      if (data.businessDetails && data.businessDetails.logo)
        setLogo(data.businessDetails.logo);
    } catch (err) {
      console.log(err);
    }
  };

  const dispatch = useDispatch();

  const handleMarketPlace = idx => (e, v) => {
    let cur = JSON.parse(e.target.value);
    marketCheck[idx] = v
    setMarketCheck([...marketCheck])

    if (v) {
      let mkts = [...markets];
      if (mkts.length === 2) {
        dispatch(
          openSnackbar("You can only add upto 3 market places at time.", "info")
        );
      }
      mkts.push(cur);
      setMarkets(mkts);
    } else {
      let mkts = [...markets];
      mkts = mkts.filter((m) => m._id !== cur._id);
      setMarkets(mkts);
    }
  };

  let query = queryString.parse(window.location.search);

  useEffect(() => {
    (async () => {
      await getLogo();
      if (query.theme) {
        getTheme(query.theme);
      }
      getProduct(query.product);
    })();
    // eslint-disable-next-line
  }, []);
  // }, [temp]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImage = async (file) => {
    try {
      console.log("Uploading to s3");
      const data = await reader(file);
      const values = {
        name: file.name,
        type: file.type,
        encoding: "base64",
        imageData: data,
      };
      const { url } = await uploadImage(values);
      return url;
    } catch (error) {
      console.log(error.message);
      return null;
    }
  };

  const handlePreviewImage = async (data) => {
    try {
      console.log("Uploading to s3", data);
      // const data = await reader(file);
      const values = {
        name: state.campaignName.replace(/\s/g, "") + Date.now() + ".png",
        type: "image/png",
        encoding: "base64",
        imageData: data.file,
      };
      const { url } = await uploadImage(values);
      return url;
    } catch (error) {
      console.log(error.message);
      return null;
    }
  };

  const reader = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
  };

  const uploadBackgroundImage = async (file) => {
    setImgLoading(true);
    const data = await reader(file);
    const values = {
      name: file.name,
      type: file.type,
      encoding: "base64",
      imageData: data,
    };
    const { url } = await uploadImage(values);
    setState({
      ...state,
      backgroundColor1: url,
    });
    setImgLoading(false);
  };

  const uploadBackgroundImage2 = async (file) => {
    setImgLoading(true);
    const data = await reader(file);
    const values = {
      name: file.name,
      type: file.type,
      encoding: "base64",
      imageData: data,
    };
    const { url } = await uploadImage(values);
    setState({
      ...state,
      backgroundImage: url,
    });
    setImgLoading(false);
  };

  // const [scaleForImg, setScaleForImg] = useState(undefined)

  const handleShoppableAdCreation = async () => {
    console.log("....", {
      markets,
      product,
      state,
      products,
      marketss,
      states
    });

    // debugger;
    // if (markets.length < 3) {
    //   dispatch(openSnackbar("Select 3 market places", "error"));
    //   // throw new Error("Select at least 1 marketplace");
    //   return;
    // }

    const getMarkets = (product, market = []) => {
      // if (market.length === 3) return market;
      let m = [...(market || []), ...(product?.marketPlace || [])].slice(0, 3);
      console.log(".....market", { market, m, product })
      return m
    };

    const singleAdCreation = async (product, markets, state) => {
      setLoading(true);
      try {
        //step 1 => Checking image
        // setAdsNum(1);
        setStep(1);
        let logoUrl = "";

        if (toUpload) {
          setStep(2);
          //Step 2 => Uploading your Logo
          logoUrl = await handleImage(toUpload);
        } else logoUrl = logo;

        console.log(logoUrl);
        if (!logoUrl) {
          dispatch(openSnackbar("Business Logo is required!", "error"));
          // throw ("Business Logo is required!")
          throw new Error("err");
        }

        const data = {
          pixels: pixels,
          shopTheme: theme._id,
          product: product._id,
          user: localStorage.getItem("user"),
          backgroundColor: state.backgroundColor1,
          backgroundImage: state.backgroundImage,
          fontColor: state.fontColor,
          campaignName: state.campaignName,
          backgroundColor2: state.backgroundColor2,
          fontFamily: state.fontFamily,
          campaignFont: state.campaignFont,
          productFont: state.productFont,
          logo: logoUrl,
          marketPlaces: markets,
          whatsAppNumber: state.whatsAppNumber,
          // scriptLink: !isImage ? file.script : null,
          // previewImage: isImage ? previewUrl : null,
        };

        // console.log(".... data", data);

        //step 3 creating shoppable ad
        setStep(3);
        const response = await axios.post(
          process.env.REACT_APP_BASE + "/shoppable/add",
          { ...data }
        );
        // console.log(".... Saved Ad: ", response.data);

        setStep(4);
        // step 4 => preparing you eskimi script/image
        let file = null;
        let preview = null;
        if (isImage) {
          file = await getImage();
          preview = file;
        } else {
          preview = await getImage();
          await new Promise((r) => setTimeout(r, 1000));
          file = await getJs(response.data._id, response.data.marketPlaces);
          // tag = await getTag();
        }
        await new Promise((r) => setTimeout(r, 1000));

        setStep(5);
        //step 5 => generating ad preview
        let previewImg = await getImagePng();
        let previewUrl = await handlePreviewImage(previewImg);
        console.log(previewUrl);

        setStep(6);
        //step 6 => Finishing up you Ad
        const response2 = await axios.put(
          process.env.REACT_APP_BASE + "/shoppable/update/" + response.data._id,
          {
            scriptLink: !isImage ? file.script : null,
            previewImage: isImage ? previewUrl : null,
          }
        );
        console.log("Updated Ad: ", response2.data);
        console.log("File ", file);
        return { response, file, preview };

        // history.push({
        //   pathname: "/admin/shoppableSuccess",
        //   state: {
        //     file: file.file,
        //     tag: file.tag,
        //     isImage: isImage,
        //     preview: preview.file,
        //     id: response.data._id,
        //     product: response.data,
        //   },
        // });
      } catch (err) {
        setStep(0);
        // setAdsNum(0);
        console.log(err);
        setLoading(false);
        return false;
        //dispatch(openSnackbar("Could not publish your Ad!", "error"));
      }
    };

    products[index] = { ...product };
    marketss[index] = [...(markets || [])];
    states[index] = { ...state };

    setState(() => states[0]);
    setProduct(() => products[0]);
    setMarkets(() => getMarkets(products[0], marketss[0]));

    // setScaleForImg(1)
    const files = [];
    const previews = [];
    const { response, file, preview } = await singleAdCreation(
      products[0],
      getMarkets(products[0], marketss[0]),
      states[0]
    );
    files[0] = [
      `${products[0]?.productName || "ad"}_${0}${mediaType === "Facebook" || mediaType === "Instagram" ? ".png" : ".txt"}`,
      file.file,
    ];
    previews[0] = preview;
    for (let i = 1; i < products?.length; i++) {
      let product = products[i];
      let markets = getMarkets(product, marketss[i]);
      let state = states[i];
      setState(() => state);
      setProduct(() => product);
      setMarkets(() => markets);
      await new Promise((r) =>
        setTimeout(() => {
          (async () => {
            if (markets?.length === 3) {
              const { file, preview } = await singleAdCreation(
                product,
                markets,
                state
              );
              files[i] = [
                `${product?.productName || "ad"}_${i}.txt`,
                file.file,
              ];
              previews[i] = preview;
            }
            r();
          })();
        }, 5000)
      );
    }
    if (response) {
      let arr = theme.size.split("x");
      console.log("<= arr", { arr, files, previews });
      history.push({
        pathname: "/admin/shoppableSuccess",
        state: {
          files,
          file: file?.file,
          tag: file?.tag,
          isImage: isImage,
          preview: preview?.file,
          id: response?.data?._id,
          product: response?.data,
          previews: previews,
          width: arr[0],
          height: arr[1],
          mediaType: mediaType,
        },
      });
    }
    // setScaleForImg(undefined)
  };

  const getStep = () => {
    switch (step) {
      case 0:
        return null;
      case 1:
        return lang && lang.shbstep1;
      case 2:
        return lang && lang.shbstep2;
      case 3:
        return lang && lang.shbstep3;
      case 4:
        return lang && lang.shbstep4;
      case 5:
        return lang && lang.shbstep5;
      case 6:
        return lang && lang.shbstep6;
      default:
        return null;
    }
  };

  // const adId = "6161454fd1ec1c4f4d25d872";

  const getJs = async (id, marketPlaces) => {
    // let zipfile = new JSZip();
    let d = "";
    setDownload((old) => {
      d = old;
      return old;
    });
    console.log("....", { d, download });
    let htmlTemplate = createHtmlTemplate({
      html: d || download,
      adId: id,
      pixels: pixels,
    });
    console.log(htmlTemplate);
    let jsTemplate = createJsTemplate(htmlTemplate);

    let urlResponse;
    try {
      urlResponse = await axios.post(
        `${process.env.REACT_APP_BASE}/shoppable/eskimi`,
        {
          filename: `${Date.now().toString()}_main.js`,
          content: jsTemplate,
        }
      );
    } catch (err) {
      console.log(err);
      dispatch(openSnackbar("Unable to generate eskimi template!", "error"));
    }
    if (urlResponse && urlResponse.data.success) {
      let url = urlResponse.data.url;
      const eskimiTemplate = eskimiScriptTemplate(
        url,
        marketPlaces[0].marketurl,
        marketPlaces[1] ? marketPlaces[1].marketurl : "",
        marketPlaces[2] ? marketPlaces[2].marketurl : ""
      );
      // console.log(eskimiTemplate);
      // zipfile.file("shoppable-ad.txt", eskimiTemplate);

      // const blob = await zipfile.generateAsync({ type: "blob" });
      const blob = eskimiTemplate;
      console.log(blob);
      return { file: blob, tag: eskimiTemplate, script: url };
    } else return null;

    //saveAs(blob, "template.zip");
  };

  const getImage = async () => {
    let node = document.getElementById("scalableWrapper");

    try {
      let blob = await htmlToImage.toBlob(node);
      return { file: blob };
    } catch (err) {
      console.log("Failing Here", { err });
      dispatch(openSnackbar("Failed to generate image!", "error"));
      return null;
    }
  };

  const getImagePng = async () => {
    console.log("CALLED");
    var node = document.getElementById("scalableWrapper");

    try {
      let png = await htmlToImage.toPng(node);
      console.log(png);
      return { file: png };
    } catch (err) {
      console.log("Failing Here");
      console.log(err);
      dispatch(openSnackbar("Failed to generate image!", "error"));
      return null;
    }
  };

  const handlePrev = () => {
    var themeVar;
    if (theme.themeName.includes("ThemeEleven")) {
      themeVar = {
        width: "1060px",
        height: "1080px",
        right: "-1080px",
        right1: "0px",
        right2: "1080px",
      };
    } else {
      themeVar = {
        width: "280px",
        height: "230px",
        right: "-450px",
        right1: "20px",
        right2: "450px",
      };
    }

    const jsCode = `
    const styles = '.slide { position: absolute; right: ${themeVar["right"]}; width: ${themeVar["width"]}; height: ${themeVar["height"]}; -webkit-animation: slide 0.5s forwards; -webkit-animation-delay: 0.5s; animation: slide 0.5s forwards; animation-delay: 0.5s; } @-webkit-keyframes slide { 100% { right: ${themeVar["right1"]}; } } @keyframes slide { 100% { right: ${themeVar["right1"]}; } } .slideOut { position: absolute; right: ${themeVar["right1"]}; width: ${themeVar["width"]}; height: ${themeVar["height"]}; -webkit-animation: slideOut 0.5s forwards; -webkit-animation-delay: 0.5s; animation: slideOut 0.5s forwards; animation-delay: 0.5s; } @-webkit-keyframes slideOut { 100% { right: ${themeVar["right2"]}; } } @keyframes slideOut { 100% { right: ${themeVar["right2"]};}}';
    console.log(styles);

    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    console.log('here 4');

    var slideIndex = 0;
    slider();
    function slider() {
      var i;
      var x = document.getElementsByClassName('part');
      for (i = 0; i < x.length; i++) {
        x[i].classList.remove('slide');
        x[i].classList.remove('slideOut');
        x[i].className += ' slideOut';
      }
      slideIndex++;
      if (slideIndex > x.length) {
        slideIndex = 1;
      }
      x[slideIndex - 1].classList.remove('slideOut');
      x[slideIndex - 1].className += ' slide';
      // setTimeout(slider, 5000);
    }
    console.log('here 5');
    
  `;

    // eslint-disable-next-line
    new Function(jsCode)();
  };

  const handleNext = () => {
    var themeVar;
    if (theme.themeName.includes("ThemeEleven")) {
      themeVar = {
        width: "1040px",
        height: "1080px",
        right: "-1080px",
        right1: "0px",
        right2: "1080px",
      };
    } else {
      themeVar = {
        width: "280px",
        height: "230px",
        right: "-450px",
        right1: "20px",
        right2: "450px",
      };
    }

    const jsCode = `
    const styles = '.slide { position: absolute; right: ${themeVar["right"]}; width:  ${themeVar["width"]}; height:  ${themeVar["height"]}; -webkit-animation: slide 0.5s forwards; -webkit-animation-delay: 0.5s; animation: slide 0.5s forwards; animation-delay: 0.5s; } @-webkit-keyframes slide { 100% { right:  ${themeVar["right1"]}; } } @keyframes slide { 100% { right:  ${themeVar["right1"]}; } } .slideOut { position: absolute; right:  ${themeVar["right1"]}; width: ${themeVar["width"]}; height: ${themeVar["height"]}; -webkit-animation: slideOut 0.5s forwards; -webkit-animation-delay: 0.5s; animation: slideOut 0.5s forwards; animation-delay: 0.5s; } @-webkit-keyframes slideOut { 100% { right: ${themeVar["right2"]}; } } @keyframes slideOut { 100% { right: ${themeVar["right2"]}; } }';
    console.log('here 3');

    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    console.log('here 4');

    var slideIndex = 0;
    slider();
    function slider() {
      var i;
      var x = document.getElementsByClassName('part');
      for (i = 0; i < x.length; i++) {
        x[i].classList.remove('slide');
        x[i].classList.remove('slideOut');
        x[i].className += ' slide';
      }
      slideIndex++;
      if (slideIndex > x.length) {
        slideIndex = 1;
      }
      x[slideIndex - 1].classList.remove('slide');
      x[slideIndex - 1].className += ' slideOut';
      // setTimeout(slider, 5000);
    }
    console.log('here 5');
  `;

    // eslint-disable-next-line
    new Function(jsCode)();
  };

  const handleSlide = () => {
    console.log(slidestep);
    if (slidestep === 1) {
      handleNext();
      setSlidestep(2);
    } else {
      handlePrev();
      setSlidestep(1);
    }
  };

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 500);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  // const handleShoppableAdNext = () => {
  //   setTemp(
  //     `/admin/shoppable/customize?theme=${query.theme}&product=${products &&
  //     products[1]?._id}`
  //   );
  // };

  // useEffect(() => {
  //   if (temp) history.push(temp);
  //   // eslint-disable-next-line
  // }, [temp]);

  const rightPanel = (product, state = {}, markets) => (
    <RightPanel
      html={theme.html}
      backgroundColor={state.backgroundColor1}
      backgroundImage={state.backgroundImage}
      fontColor={state.fontColor}
      campaignName={state.campaignName}
      backgroundColor2={state.backgroundColor2}
      fontFamily={state.fontFamily}
      productName={product?.productName}
      productImg={get(product, "productImage[0]", "NA")}
      productDescription={product.productDescription.substring(0, 40)}
      marketplace1={get(markets, "[0]", "{}")}
      marketplace2={get(markets, "[1]", "{}")}
      marketplace3={get(markets, "[2]", "{}")}
      campaignFontSize={state.campaignFont}
      descriptionFontSize={state.productFont}
      whatsAppNumber={state.whatsAppNumber}
      ratingColor={state.ratingColor}
      logo={logo || toUpload}
      size={theme.size}
      setDownload={setDownload}
      actionImageSuffix={state?.actionImageSuffix || ""}
    />
  );

  const getScale = (size) => {
    console.log('......size', size)
    switch (size) {
      case "320x50": return 0.75;
      case "320x480": return 0.75;
      case "300x250": return 0.8;
      case '728x90': return 0.375;
      case '1080x1080': return 0.20;
      case '1080x1920': return 0.20;
      case '1200x628': return 0.2;
      case '160x600': return 1;
      default: return 0.5;
    }
  }

  // using this because rightPanel sets setDownload.. of that is set from here it will casue issue while creating eskimi..
  // eskimi gets repeated.. last product eskimi is applied to all products
  const rightRightPanel = (product, state = {}, markets) => (
    <RightPanel
      html={theme.html}
      backgroundColor={state.backgroundColor1}
      backgroundImage={state.backgroundImage}
      fontColor={state.fontColor}
      campaignName={state.campaignName}
      backgroundColor2={state.backgroundColor2}
      fontFamily={state.fontFamily}
      productName={product?.productName}
      productImg={get(product, "productImage[0]", "NA")}
      productDescription={product.productDescription.substring(0, 40)}
      marketplace1={get(markets, "[0]", "{}")}
      marketplace2={get(markets, "[1]", "{}")}
      marketplace3={get(markets, "[2]", "{}")}
      campaignFontSize={state.campaignFont}
      descriptionFontSize={state.productFont}
      whatsAppNumber={state.whatsAppNumber}
      ratingColor={state.ratingColor}
      logo={logo || toUpload}
      size={theme.size}
      actionImageSuffix={state?.actionImageSuffix || ""}
      scaleForImage={getScale(theme.size)}
      setDownload={() => { }}
    />
  );

  return (
    <Box p={2}>
      <Grid container spacing={1} justifyContent="space-evenly">
        <Grid container item xs={12} md={4} className={clsx(classes.leftDiv)} style={{ height: '85vh' }}>
          {/* <Paper
            className={classes.leftPanel}
            elevation={0}
            style={{ paddingLeft: 18 }}> */}
          <Grid item xs={12}>
            <Link to={`/admin/shoppable/createAd/${query.product}`}>
              <ArrowBackIcon style={{ color: "#6C7073" }} />
            </Link>
          </Grid>

          <Grid item xs={12}>
            <p className={classes.heading}>{lang && lang.customize}</p>
          </Grid>

          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <p className={classes.subHeading}> {lang && lang.campaignName}</p>

              <TextField
                size="small"
                name="campaignName"
                variant="outlined"
                onChange={(e) => {
                  if (e.target.value.length <= 60) {
                    handleChange(e);
                  }
                }}
                style={{ color: "#5b5b5b", backgroundColor: "white" }}
                value={state.campaignName}
                placeholder="Name your Campaign"
                fullWidth
              />
              {/* <input
                  className={classes.input}
                /> */}

              <span
                style={{
                  color: "#bbb",
                  float: "right",
                  marginLeft: "1px",
                  paddingTop: 5,
                  fontSize: 12,
                }}
              >
                {state.campaignName.length + "/60"}
              </span>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.subHeading} style={{ marginTop: "0px" }}>
                {" "}
                Product Name
              </p>

              <TextField
                size="small"
                name="campaignName"
                variant="outlined"
                onChange={(e) => {
                  if (e.target.value.length <= 60) {
                    setProduct({ ...product, productName: e.target.value });
                    // handleChange(e);
                  }
                }}
                style={{ color: "#5b5b5b", backgroundColor: "white" }}
                value={product?.productName}
                placeholder="Name your Product"
                fullWidth
              />
              {/* <input
                  className={classes.input}
                /> */}

              <span
                style={{
                  color: "#bbb",
                  float: "right",
                  marginLeft: "1px",
                  paddingTop: 5,
                  fontSize: 12,
                }}
              >
                {(product?.productName?.length || 0) + "/60"}
              </span>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className={classes.subHeading}>Logo</p>
              {toUpload || logo ? (
                <div className={classes.fileName}>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {logo ? "Business Logo" : toUpload.name}
                  </span>{" "}
                  <Delete
                    size="small"
                    onClick={() => {
                      setLogo(null);
                      setUpload(null);
                    }}
                  />
                </div>
              ) : (
                <label className={classes.input}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  Your Logo <AddCircleOutline />
                </label>
              )}
            </Grid>
            {theme && theme.version === "2" ? (
              <Grid item xs={6}>
                <p className={classes.subHeading}>Change Image</p>
                {/* {theme && theme.backgroundColor ? (
                      <div className={classes.fileName}>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {theme && theme.backgroundColor}
                        </span>{" "}
                        <Delete
                          size="small"
                          onClick={() => {
                            // setLogo(null);
                            // setUpload(null);
                          }}
                        />
                      </div>
                    ) : ( */}
                <label className={classes.input}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => uploadBackgroundImage(e.target.files[0])}
                  />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {state.bgColor}
                  </span>
                  <AddCircleOutline />
                </label>
                {/* )} */}
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <p className={classes.subHeading}>Background Colour </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    style={{
                      marginTop: "8px",
                      width: "32%",
                      height: 40,
                      padding: 0,
                      border: "none",
                    }}
                    type="color"
                    value={state.bgColor}
                    onChange={handleColor1}
                  />
                  <input
                    className={classes.input}
                    onChange={handleColor1}
                    allowClear={true}
                    value={state.backgroundColor1}
                    style={{
                      padding: 0,
                      paddingRight: 5,
                      paddingLeft: 5,
                      color: "gray",
                      width: "60%",
                    }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          {theme && theme.version === "3" ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <p className={classes.subHeading}>Change Image</p>

                <label className={classes.input}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => uploadBackgroundImage2(e.target.files[0])}
                  />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {state.backgroundImage}
                  </span>
                  <AddCircleOutline />
                </label>
                {/* )} */}
              </Grid>
              <Grid item xs={6}>
                <p className={classes.subHeading}>WhatsApp Number</p>

                <input
                  name="whatsAppNumber"
                  value={state.whatsAppNumber}
                  // style={{ display: "none" }}
                  onChange={(e) => handleChange(e)}
                  className={classes.input}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className={classes.subHeading}>{lang && lang.selectFont}</p>

              {/*  <input className={classes.input} placeholder="Times New Roman" /> */}
              <select
                className={classes.input}
                value={state.fontFamily !== "" ? state.fontFamily : null}
                placeholder="Select Font Style"
                name="fontFamily"
                onChange={handleChange}
              >
                <option value="'Quicksand', sans-serif">
                  'Quicksand', Sans-Serif
                </option>
                <option value="arial">Arial</option>
                <option value="times new roman">Times New Roman</option>
                <option value="verdana">Verdana</option>
                <option value="helvetica">Helvetica</option>
                <option value="trebuchet">Trebuchet</option>
                <option value="georgia">Georgia</option>
                <option value="poppins">Poppins</option>
              </select>
            </Grid>

            <Grid item xs={12} md={6}>
              <p className={classes.subHeading}>
                {lang && lang.marketplaceColour}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    marginTop: "8px",
                    width: "32%",
                    height: 40,
                    padding: 0,
                    border: "none",
                  }}
                  type="color"
                  name="backgroundColor2"
                  value={state.backgroundColor2}
                  onChange={handleChange}
                />
                <input
                  className={classes.input}
                  onChange={handleChange}
                  allowClear={true}
                  value={state.backgroundColor2}
                  name="backgroundColor2"
                  style={{
                    padding: 0,
                    paddingRight: 5,
                    paddingLeft: 5,
                    color: "gray",
                    width: "60%",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className={classes.subHeading}>{lang && lang.fontColor}</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    marginTop: "8px",
                    width: "32%",
                    height: 40,
                    padding: 0,
                    border: "none",
                  }}
                  type="color"
                  name="fontColor"
                  value={state.fontColor}
                  onChange={handleChange}
                />
                <input
                  className={classes.input}
                  onChange={handleChange}
                  allowClear={true}
                  value={state.fontColor}
                  name="fontColor"
                  style={{
                    padding: 0,
                    paddingRight: 5,
                    paddingLeft: 5,
                    color: "gray",
                    width: "60%",
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className={classes.subHeading}>{lang && lang.fontSizeTitle}</p>
              <input
                name="campaignFont"
                onChange={handleChange}
                value={state.campaignFont}
                className={classes.input}
                type="number"
                placeholder="Font Size"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className={classes.subHeading}>{lang && lang.fontSizeDesc}</p>
              <input
                type="number"
                name="productFont"
                onChange={handleChange}
                value={state.productFont}
                className={classes.input}
                placeholder="Font Size"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p className={classes.subHeading}>{"Tracking Pixels"}</p>
              <TextField
                onChange={handlePixelChange}
                multiline
                maxRows={4}
                fullWidth
                placeholder="Enter Your Pixels Here"
                // className={classes.input}
                variant="outlined"
                style={{
                  marginTop: 5,
                  color: "#6C7073",
                  background: "white",
                  minHeight: 40,
                }}
                size="small"
              />
              <small>Note: Seperate Pixel URLs by commas</small>
            </Grid>
          </Grid>

          <Divider />
          <Grid container>
            <Accordion
              style={{
                background: "transparent",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  style={{
                    color: "#6C7073",
                    fontSize: "22px",
                    fontWeight: 400,
                  }}
                >
                  Marketplace
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ paddingLeft: 0 }}>
                <FormControl>
                  <FormGroup
                    aria-label="position"
                    row
                    style={{
                      width: "100%",
                      paddingRight: 15,
                      maxHeight: "150px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      color: "#6C7073",
                    }}
                  >
                    {product.marketPlace &&
                      product.marketPlace.map((mkt, idx) => {
                        return (
                          <FormControlLabel
                            key={idx}
                            value={JSON.stringify(mkt)}
                            control={
                              <Checkbox
                                disabled={
                                  markets.length === 3 &&
                                  !markets.some((mk) => mk._id === mkt._id)
                                }
                                style={{ marginLeft: "auto" }}
                                color="primary"
                                onChange={handleMarketPlace(idx)}
                                checked={marketCheck[idx]}
                              />
                            }
                            label={mkt.marketPlaceNumber}
                            labelPlacement="start"
                            style={{ width: "100%", fontSize: 12 }}
                          />
                        );
                      })}
                  </FormGroup>
                  <FormHelperText color="primary">
                    You can only choose upto 3 at a time.
                  </FormHelperText>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* </Paper> */}
        </Grid>

        {/* =====================================================  */}
        <Grid
          item
          md={5}
          container
          style={{
            backgroundColor: "#fff",
            borderRadius: "24px",
            height: "85vh",
          }}
        >
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <Box
              position={"relative"}
              justifyContent="center"
              alignItems={"center"}
              display="flex"
              height={1}
            >
              {/* <Paper
              className={classes.rightPanel}
              // style={{ background: "#000" }}
              elevation={0}> */}
              {theme && (theme.version === 2 || theme.version === 3) && (
                <Typography style={{ position: "absolute", left: 10, top: 10 }}>
                  {`Note: Please provide a ${theme.size} pixel image for best results`}
                </Typography>
              )}

              {theme &&
                theme.themeName &&
                (theme.themeName.includes("Ramadan") ||
                  theme.themeName.includes("ThemeEleven")) && (
                  <IconButton
                    size="small"
                    onClick={handleSlide}
                    className={clsx(classes.iconButton, classes.leftBtn)}
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
              {theme &&
                theme.themeName &&
                (theme.themeName.includes("Ramadan") ||
                  theme.themeName.includes("ThemeEleven")) && (
                  <p className={classes2.showStep}>{slidestep} / 2</p>
                )}

              {product &&
                product.productDescription != null &&
                product.productDescription.length > 0 &&
                rightPanel(product, state, markets, undefined)}

              {theme &&
                theme.themeName &&
                (theme.themeName.includes("Ramadan") ||
                  theme.themeName.includes("ThemeEleven")) && (
                  <IconButton
                    size="small"
                    className={clsx(classes.iconButton, classes.rightBtn)}
                    onClick={handleSlide}
                  >
                    <ChevronRight />
                  </IconButton>
                )}
              {/* </Paper> */}
            </Box>
          </Grid>
          <Grid item xs={12} >
            <Box className={classes.buttonBox}>
              <Button
                color="primary"
                size="medium"
                variant="contained"
                className={classes.create__button}
                style={{
                  // position: "fixed",
                  // right: 50,
                  // bottom: 50,
                  backgroundColor: "#00CD9C",
                }}
                onClick={handleShoppableAdCreation}
              // disabled={
              //   !imgLoading
              //   &&
              //   ((theme.version !== "3" && markets.length === 3) ||
              //     (theme.version === "3" && markets.length === 1)
              //     ? false
              //     : true)
              // }
              >
                {lang && lang.createAd}
              </Button>

              {/* <Button
                color="primary"
                size="medium"
                variant="contained"
                className={classes.create__button}
                style={{
                  // position: "fixed",
                  // right: 50,
                  // bottom: 50,
                  backgroundColor: "#00CD9C",
                }}
                onClick={handleShoppableAdNext}
              // disabled={
              //   !imgLoading
              //   // &&
              //   // ((markets.length === 3 && theme.version !== "3") ||
              //   // (theme.version === "3" && markets.length === 1)
              //   //   ? false
              //   //   : true)
              // }
              >
                Next
              </Button> */}

            </Box>
            <Box className={classes.buttonBox}>
              {loading || imgLoading ? (
                <div
                  style={{
                    textAlign: "center",
                    // position: "fixed",
                    // right: 50,
                    // bottom: 50,
                  }}
                >
                  {/* <CircularProgress color="primary" style={{}} /> */}
                  {/* <LinearProgress variant="determinate" value={progress} /> */}

                  {imgLoading ? (
                    <Typography style={{ fontSize: 14 }}>
                      Uploading Image, Please Wait...
                    </Typography>
                  ) : (
                    <Typography>
                      {getStep()}... of {product?.productName?.substring(0, 10)}
                    </Typography>
                  )}
                </div>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          container
          md={2}
          justifyContent="center"
          className={clsx(classes.leftDiv)}
          style={{
            position: "relative",
            padding: "24px",
            borderRadius: '24px',
            backgroundColor: "white",
            height: "85vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {products?.map((e, i) => (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  top: `${(((+theme.height * getScale(theme.size)) + 48) * i) + 24}px`,
                  maxHeight: `${(+theme.height * getScale(theme.size)) + 48}px`,
                }}
              >
                <div style={{ pointerEvents: 'none', position: 'absolute', top: 0, left: -150 }}>
                  {rightRightPanel(e, states[i], marketss[i] || [])}
                </div>
                <div
                  onClick={() => {
                    products[index] = { ...product };
                    marketss[index] = [...(markets || [])];
                    states[index] = { ...state };

                    setProduct(products[i]);
                    setMarkets(marketss[i] || []);
                    setState(states[i]);
                    setIndex(i);
                    setMarketCheck([false, false, false])
                  }}
                  style={{
                    width: '240px',
                    height: `${(+theme.height * getScale(theme.size))}px`,
                    zIndex: '10',
                    position: 'absolute', top: 24, left: -120
                  }}
                />
              </div>

            </>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(ShoppableCustomize);
