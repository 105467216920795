import { Button, Col, Form, Input, Row } from "antd";
import axios from "axios";
import Heading from "components/heading/heading";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "redux/snackbarStore";
import slide1 from "../../../../assets/slide1.png";
import slide2 from "../../../../assets/slide2.png";
import classes from "./ForgotPassword.module.css";
// import slide3 from "../../../assets/slide3.png";
import { pageView } from "container/analytics";
import queryString from "query-string";
import homeVid from "../../../../assets/home.mp4";
import slide4 from "../../../../assets/slide4.png";
import { AuthWrapper } from "./style";

const ForgotPassword = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = () => {
    setLoading(true);
    if (state.password.length < 8) {
      dispatch(
        openSnackbar("Password should be at least 8 characters long!", "error")
      );
      setLoading(false);
    } else if (state.password !== state.confirmPassword) {
      dispatch(openSnackbar("Passwords don't match!", "error"));
      setLoading(false);
    } else {
      axios
        .put(process.env.REACT_APP_BASE + "/auth/updatepass/" + user, {
          ...state,
        })
        .then((response) => {
          dispatch(
            openSnackbar(
              "Password updated successfully! Please login to continue",
              "success"
            )
          );
          props.history.push("/");
          setLoading(false);
        })
        .catch((err) => {
          dispatch(openSnackbar("Failed to update password!", "error"));
          props.history.push("/");
          console.log(err.data);
          setLoading(false);
        });
    }
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const carouselImg = [
    { title: "Image1", url: slide1 },
    { title: "Image2", url: slide2 },
    // {title: "Image3", url: slide3},
    { title: "Image4", url: slide4 },
  ];

  // const [src, setSrc] = useState(slide1);

  const changeSlide = () => {
    var indx = 0;
    setInterval(() => {
      if (indx >= carouselImg.length - 1) indx = -1;
      indx = indx + 1;
      // setSrc(carouselImg[indx].url);
      // console.log(carouselImg[indx].time)
    }, 5000);
  };

  const [isAnimation, setIsAnimation] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!isAnimation) {
      changeSlide();
      TypingAnimation();
      let userObject = queryString.parse(window.location.search);
      if (userObject.userId) setUser(userObject.userId);
    }
    pageView()
    // eslint-disable-next-line
  }, []);

  const TypingAnimation = () => {
    const words = [
      { text: "Insights", color: "#f25a56" },
      { text: "Shoppable Ads", color: "#ae6b75" },
      { text: "One Page Store", color: "#134589" },
    ];
    let i = 0;
    // eslint-disable-next-line
    let timer;

    const typingEffect = () => {
      try {
        let word = words[i].text.split("");
        var loopTyping = function () {
          if (word.length > 0) {
            if (document.getElementById("word")) {
              document.getElementById("word").innerHTML += word.shift();
            }
            document.getElementById("word").style.color = words[i].color;
          } else {
            setTimeout(deletingEffect, 2000);

            return false;
          }
          timer = setTimeout(loopTyping, 2000 / words[i].text.length);
        };
        loopTyping();
      } catch (error) { }
    };

    const deletingEffect = () => {
      let word = words[i].text.split("");
      var loopDeleting = function () {
        if (word.length > 0) {
          word.pop();
          if (document.getElementById("word"))
            document.getElementById("word").innerHTML = word.join("");
        } else {
          if (words.length > i + 1) {
            i++;
          } else {
            i = 0;
          }
          typingEffect();
          return false;
        }
        timer = setTimeout(loopDeleting, 1000 / words[i].text.length);
      };
      loopDeleting();
    };
    typingEffect();
    setIsAnimation(true);
  };

  return (
    <Row className={classes.screen}>
      <Col xxl={14} xl={14} lg={14} md={8} xs={24}>
        {/* <div className={classes.title}>
          <h1
            style={{
              fontSize: "3.5rem",
              color: "#fff",
              fontWeight: "700",
              lineHeight: "1.2",
              marginBottom: 0,
            }}
          >
            Create & <br />
            Publish Your
          </h1>
          <div class="flex">
            <p class="header-sub-title" id="word"></p>
            <p class="header-sub-title blink" style={{ color: "#fff" }}>
              |
            </p>
          </div>
          <p style={{ color: "white" }} className={classes.desktopOnly}>
            Access our userfriendly features to publish your own custom
            <br /> Adds and stores to increase your product’s reach, setup your
            <br /> free account with Modcart.
          </p>
        </div>
        <img className={classes.backgroundImg} src={src} /> */}

        <video style={{ height: "100vh" }} autoPlay muted loop>
          <source src={homeVid} type="video/mp4"></source>
        </video>
      </Col>

      <Col className={classes.form} xxl={10} xl={10} lg={10} md={16} xs={24}>
        <AuthWrapper>
          <div className={classes.parent}>
            <Form
              name="login"
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
            >
              <Heading as="h3">Reset Password</Heading>
              <br />

              <Form.Item
                name="password"
                rules={[
                  {
                    message: "Please input your password!",
                    required: true,
                  },
                ]}
                //initialValue="name@example.com"
                style={{ border: "1px solid #88949E" }}
              >
                <Input.Password
                  type="password"
                  autoFocus
                  placeholder="New Password"
                  id="password"
                  onChange={onChange}
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  {
                    message: "Please input your password!",
                    required: true,
                  },
                ]}
                //initialValue="name@example.com"
                style={{ border: "1px solid #88949E" }}
              >
                <Input.Password
                  type="password"
                  placeholder="Confirm Password"
                  id="confirmPassword"
                  onChange={onChange}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  className="btn-signin"
                  htmlType="submit"
                  type="primary"
                  size="large"
                  style={{ width: "100%", background: "#00ACE9" }}
                >
                  {isLoading ? "Loading..." : "Change Password"}
                </Button>
              </Form.Item>

              {/* <div className="form-divider">
                <span>Or</span> <Link to="/">Sign In</Link>
              </div> */}
            </Form>
          </div>
        </AuthWrapper>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
