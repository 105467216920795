import { LOADING, LOAD_MORE_DATA, SET_SHOPPABLE_DATA, SET_SHOPPABLE_LIST, SET_SHOPPABLE_MEDIA_TYPE } from './action.types';

const initstate = {
    loading: false,
    requestSize: 5,
    shoppableList: [],
    showMore: false,
    shoppableStartIndex: 0,
    shoppableLeft: false,
    page: 1,
    nextPage: 0,
    totalCount: 0,
    selectedProductList: [],
    shoppableMediaType: ""
}

export default function shoppableReducer(state = initstate, action) {
    const { type, payload } = action;
    switch (type) {
        case LOADING: return {
            ...state,
            loading: payload
        }
        case SET_SHOPPABLE_DATA:
            state.shoppableStartIndex = 0;
            state.shoppableLeft = false;
            const end = state.shoppableStartIndex + state.requestSize;
            // console.log("product Left",productLeft,state.requestSize,payload.length)
            // let items = payload.slice(state.shoppableStartIndex, end);
            // const shoppableLeft = (items && items.length) === state.requestSize;
            console.log("data", payload.data.length);
            console.log("count", payload.totalCount)
            const shoppableLeft1 = (payload.data.length !== payload.totalCount);
            console.log("shoppable Left", shoppableLeft1)
            console.log("product Left", payload);
            return {
                ...state,
                data: [],
                shoppableList: payload.data,
                result: payload,
                shoppableStartIndex: end,
                // shoppableLeft: 0,
                nextPage: payload?.pagination?.next?.page,
                totalCount: payload.totalCount,
                shoppableLeft: shoppableLeft1

            }
        case LOAD_MORE_DATA:
            console.log("Loading more amount", payload.data);
            // let endIndex = state.shoppableStartIndex + state.requestSize;
            // console.log("product Left",productLeft,state.requestSize,payload.length)
            let items = state.shoppableList.concat(payload.data);
            //let itemsNew = state.shoppableList.slice(state.shoppableStartIndex, endIndex);
            console.log(items);
            let shoppableLeft = (items.length !== state.totalCount);
            console.log("shoppable Left", shoppableLeft)
            return {
                ...state,
                shoppableList: items,
                nextPage: payload?.pagination?.next?.page,
                shoppableLeft: shoppableLeft
                // data: state.data.concat(itemsNew),
                // shoppableStartIndex: endIndex,
                // shoppableLeft: shoppableLeft1
            }
        case SET_SHOPPABLE_LIST: {
            return { ...state, selectedProductList: payload }
        }
        case SET_SHOPPABLE_MEDIA_TYPE: {
            return { ...state, shoppableMediaType: payload }
        }
        default: return state;
    }
}