import {
  Dialog,
  DialogContent, makeStyles
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { logo } from "utility/marketplaceLogo";
import classes2 from "../ShoppableList.module.css";
// import image from "assets/confirm.png";

const useStyles = makeStyles(theme => ({
  icon: {
    padding: 6,
    border: 20,
    backgroundColor: "#FF1111",
    color: "white",
    borderRadius: 20,
    width: 40,
    height: 40,
    margin: "auto",
  },
  root: {
    textAlign: "center",
    padding: 40,
    paddingBottom: 0,
    marginBottom: 20,
    minWidth: 600,
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%"
    }
  },
  insights: {
    display: "flex",
    textAlign: "center",
    justifyContent: "space-between",
    padding: "10px 15px",
    borderTop: "1px solid #eee",
    borderBottom: "1px solid #eee",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    }

  },
  marketPlaceContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "center"
    }

  },
  marketPlaceContainerImg: {
    height: "35px",
    width: "35px",
    marginTop: "-52px"
  },
  header: {
    textAlign: "left",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center"
    }
  }
}));

function AnalyticsModal({ open, onClose, clicks, markets, trackingId }) {
  const classes = useStyles();
  const lang = useSelector((state) => state.lang.lang);

  // useEffect(()=>{

  // })

  // const [copy, setCopy] = useState(false);

  // useEffect(() => {
  //   if (copy) {
  //     dispatch(openSnackbar("Copied to clipboard!", "success"));
  //     setCopy(false);
  //   }
  // }, [copy]);
  // const dispatch = useDispatch();
  const [totalClicks, setTotalClicks] = React.useState(0);
  // console.log(clicks);
  const sum = () => {
    var tc = 0;
    if (clicks) {
      markets.map(
        (market) => (tc += parseInt(clicks[market.marketPlaceNumber]))
      );
    }
    setTotalClicks(tc);
    // return tc;
  };
  // sum();

  useEffect(() => {
    sum();
    // eslint-disable-next-line
  }, [open]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}
    >
      <DialogContent className={classes.root}>
        <h3 className={classes.header}>
          <b>Insights</b>
        </h3>
        <div className={classes.insights}>
          <div className={classes2.insight}>
            <h5>{lang && lang.Impressions}</h5>
            {trackingId ? trackingId.views : 0}
          </div>
          <div className={classes2.insight}>
            <h5>{lang && lang.totalclicks}</h5>

            {totalClicks}
            {/* {trackingId ? trackingId.clicks : 0}</div> */}
          </div>
          <div className={classes2.insight}>
            <h5>{lang && lang.click_through_rate}</h5>
            {trackingId
              ? ((totalClicks / trackingId.views) * 100).toFixed(2)
              : (0).toFixed(2)}
            %
          </div>
        </div>
        <div className={classes.marketPlaceContainer}>
          {markets.map((market) => (
            <div className={classes2.marketPlace}>
              <div>
                <img alt='' src={logo(market.marketPlaceNumber)} className={classes.marketPlaceContainerImg} />
              </div>
              <div>{clicks ? clicks[market.marketPlaceNumber] : "0"}</div>
              <div variant="overline">Clicks</div>
            </div>
          ))}{" "}
        </div>
      </DialogContent>
      {/* <DialogActions style={{ justifyContent: "center" }}>
        <Button color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default AnalyticsModal;
