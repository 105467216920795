import PageStore from "container/dashboard/OnePageStore/components/pageStores/PageStore";
import ShoppableCustomize from "container/dashboard/Shoppable/ShoppableCustomize/shoppableCustomize";
import ShoppableEmpty from "container/dashboard/Shoppable/shoppableEmpty";
import ShoppableList from "container/dashboard/Shoppable/ShoppableList/ShoppableList";
import Success from "container/dashboard/Shoppable/success";
import Pricing from "container/pricing/Pricing";
import React, { lazy, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import BusinessCard from "../../container/dashboard/business";
import { initGA, pageView } from "./../../container/analytics";
// import ChatPage from "container/dashboard/ChatPage/ChatPage";
// import Receipts from "../../container/dashboard/Receipts/Receipts";
const Receipts = React.lazy(() => import("../../container/dashboard/Receipts/Receipts"));

const Dashboard = lazy(() => import("../../container/dashboard"));
const MarketShare = lazy(() => import("../../container/dashboard/Marketshare"));
const Trends = lazy(() => import("../../container/dashboard/trends"));
const Shoppable = lazy(() => import("../../container/dashboard/shoppable"));
const Shoppable2 = lazy(() =>
  import("../../container/dashboard/Shoppable/shoppableHome")
);
const ShoppableCreate2 = lazy(() =>
  import("../../container/dashboard/Shoppable/ShoppableCreateAd/createAd2")
);

const ShoppableEdit = lazy(() =>
  import("../../container/dashboard/Shoppable/ShoppableEdit/shoppableEdit")
);
// const ShoppableEdit = lazy(() =>
//   import("../../container/dashboard/Shoppable/ShoppableCustomize/shoppableCustomize")
// );
// const Onepagestore =  lazy(() => import('../../container/dashboard/OnePageStore/Onepagestore'));
// const Sales = lazy(() => import('../../container/dashboard/Sales'));
const Cart = lazy(() => import("../../container/dashboard/cart"));
const MarketplaceLinked = lazy(() =>
  import("../../container/dashboard/Marketplacelinked")
);
const Report = lazy(() => import("../../container/dashboard/Reports"));
const AdList = lazy(() => import("../../container/dashboard/Adlist"));
const Preview = lazy(() => import("../../container/dashboard/preview"));
const ProductCatalog = lazy(() =>
  import(
    "container/dashboard/OnePageStore/components/ProductCatalog/ProductCatalog"
  )
);
// const ProductDetailThirdStep = lazy(() => import('container/dashboard/OnePageStore/components/ProductDetailThirdStep/ProductDetailThirdStep'));
const Catalogue = lazy(() =>
  import("container/dashboard/OnePageStore/components/Catalogue")
);
const OnePageStore = lazy(() =>
  import("container/dashboard/OnePageStore/components/CreateStore")
);
const OnePageStoreHome = lazy(() =>
  import(
    "container/dashboard/OnePageStore/components/OnePageStoreHome/SucessfullStoreCreated"
  )
);
const OnePageResult = lazy(() =>
  import(
    "container/dashboard/OnePageStore/components/OnePageStoreHome/OnePageStores"
  )
);
const ProductDetailThirdStep = lazy(() =>
  import(
    "container/dashboard/OnePageStore/components/ProductDetailThirdStep/ProductDetailThirdStep"
  )
);
const StoreSubmitStep = lazy(() =>
  import(
    "container/dashboard/OnePageStore/components/ProductDetailThirdStep/StoreSubmitStep"
  )
);

const ChatPage = lazy(() => import("container/dashboard/ChatPage/ChatPage"));

const HelpPage = lazy(() => import("container/dashboard/helpPage"));

// const VerifyEmail = lazy(() => "../../container/dashboard/verifyEmail");
// #030848
const DashboardRoutes = ({ history }) => {
  // const dispatch = useDispatch();
  const { path } = useRouteMatch();

  // var token = localStorage.getItem("access_token");
  // if (token) var decoded = jwt_decode(token);

  useEffect(() => {
    // if (!token || Date.now() >= decoded.exp * 1000) {
    //   dispatch(logOut());
    //   dispatch(openSnackbar("Session Expired! Please Login Again", "error"));
    //   // console.log("logout");
    // }
  });

  // const profile = useSelector((state) => state.auth.profile);

  // const location = useLocation();

  React.useEffect(() => {
    //analytics.init()
    initGA();
  }, []);

  useEffect(() => {
    // When route changes, history.location.pathname changes as well
    // And the code will execute after this line
    // const currentPath = location.pathname + location.search;
    //analytics.sendPageview(currentPath);
    pageView();
  }, [history.location.pathname]);

  return (
    <>
      {/* <BusinessCard open={profile} /> */}

      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route path={`${path}/home`} component={Dashboard} />
        <Route path={`${path}/business`} component={Dashboard} />
        <Route path={`${path}/marketshare`} component={MarketShare} />
        <Route path={`${path}/trends`} component={Trends} />
        <Route path={`${path}/shoppableOld`} component={Shoppable} />
        <Route exact path={`${path}/shoppable`} component={Shoppable2} />
        <Route exact path={`${path}/shoppableSuccess`} component={Success} />
        <Route exact path={`${path}/shoppableHome`} component={ShoppableList} />
        <Route
          exact
          path={`${path}/shoppable/createAd/:product`}
          component={ShoppableCreate2}
        />

        <Route
          exact
          path={`${path}/shoppable/empty`}
          component={ShoppableEmpty}
        />

        <Route
          exact
          path={`${path}/shoppable/customize`}
          component={ShoppableCustomize}
        />

        <Route
          exact
          path={`${path}/shoppable/edit/:id`}
          component={ShoppableEdit}
        />

        <Route exact path={`${path}/help`} component={HelpPage} />

        <Route path={`${path}/adlist`} component={AdList} />
        <Route path={`${path}/preview`} component={Preview} />
        {/* <Route path={`${path}/sales`} component={Sales} /> */}
        <Route exact path={`${path}/cartabandonment`} component={Cart} />
        <Route
          exact
          path={`${path}/marketplacelinked`}
          component={MarketplaceLinked}
        />
        <Route exact path={`${path}/reports`} component={Report} />
        {/* <Route exact path={`${path}/laststep`} component={ProductDetailThirdStep} /> */}
        <Route
          exact
          path={`${path}/create-product`}
          component={ProductCatalog}
        />
        <Route
          exact
          path={`${path}/edit-product`}
          component={ProductCatalog}
        />
        <Route exact path={`${path}/catalogue`} component={Catalogue} />
        <Route
          exact
          path={`${path}/one-page-store`}
          component={OnePageResult}
        />
        <Route exact path={`${path}/my-receipts`} component={Receipts} />
        {/* <Route exact path={`${path}/my-receipts`} component={Payment} /> */}

        <Route
          exact
          path={`${path}/one-page-store/select-theme`}
          component={OnePageStore}
        />
        <Route
          exact
          path={`${path}/one-page-store/add-detail`}
          component={ProductDetailThirdStep}
        />
        <Route
          exact
          path={`${path}/one-page-store/add-domain`}
          component={StoreSubmitStep}
        />
        <Route
          exact
          path={`${path}/one-page-store/created`}
          component={OnePageStoreHome}
        />

        {/* edit routes for one page store */}
        <Route
          exact
          path={`${path}/one-page-store/edit/:id`}
          component={OnePageStore}
        />
        <Route
          exact
          path={`${path}/one-page-store/edit/add-detail/:id`}
          component={ProductDetailThirdStep}
        />
        <Route
          exact
          path={`${path}/one-page-store/edit/add-domain/:id`}
          component={StoreSubmitStep}
        />
        <Route path={`${path}/connect`} component={ChatPage} />

        <Route path={`${path}/business-profile`} component={BusinessCard} />

        <Route path={`${path}/page-store/:action`} component={PageStore} />

        <Route path={`${path}/pricing`} component={Pricing} />

      </Switch>
    </>
  );
};

export default DashboardRoutes;
