import {
  Button, Dialog,
  DialogContent, makeStyles, Typography
} from "@material-ui/core";
import { get } from "lodash";
import React from "react";
// import { useDispatch } from "react-redux";
import { eskimiScriptTemplate } from "utility/TemplateGenerator";
import { srcToCDN } from "utility/utility";
// import image from "assets/confirm.png";

const useStyles = makeStyles(theme => ({
  icon: {
    padding: 6,
    border: 20,
    backgroundColor: "#FF1111",
    color: "white",
    borderRadius: 20,
    width: 40,
    height: 40,
    margin: "auto",
  },
  root: {
    textAlign: "center",
    padding: 40,
    paddingBottom: 0,
    marginBottom: 20,
    minWidth: 400,
    [theme.breakpoints.down('sm')]: {
      minWidth: 200,
      width: '100%',
      padding: 5,

    }
  },
  scriptContainer: {
    fontSize: "12px",
    [theme.breakpoints.down('sm')]: {
      display: "none"

    }
  }
}));

function ViewModal({ open, onClose, image, link, marketPlaces }) {
  const classes = useStyles();

  // const [copy, setCopy] = React.useState(false);

  // useEffect(() => {
  //   if (copy) {
  //     dispatch(openSnackbar("Copied to clipboard!", "success"));
  //     setCopy(false);
  //   }
  // }, [copy]);
  // const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}
    >
      <DialogContent className={classes.root}>
        {image && (
          <img
            src={srcToCDN(image)}
            alt="img"
            style={{
              maxWidth: 300,
              maxHeight: 600,
              // borderRadius: 30,
              marginBottom: 20,
            }}
          />
        )}

        {link && (
          <>
            <Typography variant="h6">DSP Script - Eskimi</Typography>
            <div className={classes.scriptContainer}>
              {eskimiScriptTemplate(link, marketPlaces[0].marketurl,
                get(marketPlaces, "[1].marketUrl", ""),
                get(marketPlaces, "[2].marketUrl", "")
              )}
            </div>
            <Button
              variant="contained"
              style={{ background: "#05006D", marginTop: 15, color: "#fff" }}
              onClick={() => {
                navigator.clipboard.writeText(eskimiScriptTemplate(link,
                  marketPlaces[0].marketurl,
                  get(marketPlaces, "[1].marketUrl", ""),
                  get(marketPlaces, "[2].marketUrl", "")))
                // dispatch(openSnackbar("Copied to clipboard!", "success"));
                // setCopy(true);
              }}
            >
              Copy
            </Button>
          </>
        )}
      </DialogContent>
      {/* <DialogActions style={{ justifyContent: "center" }}>
        <Button color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default ViewModal;
