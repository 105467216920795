
import {
  Button,
  Grid,
  IconButton, InputAdornment, InputBase, Typography
} from "@material-ui/core";
import { AddOutlined, Close, Search } from "@material-ui/icons";
import axios from "axios";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getShoppableListNew, loadMoreData } from "redux/shoppableList/action";
import StoreListItem from "./components/StoreListItem";
import useStyle from "./useStyle";
// import { selectTheme } from 'redux/theme/action'
import Loader from "components/Loader";
import ShowVerifyComp from "componentsNew/Verify/ShowVerifyComp";
import ShoppableEmpty from "../shoppableEmpty";

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0
}
const OnePageStores = (props) => {
  const currentUser = useSelector((state) => state.auth);
  const moreLoader = useRef(null);
  const { shoppableListData, shoppableList,
    //  totalCount, 
    nextPage, productLeft } = props;
  const classes = useStyle();
  const [page, setPage] = React.useState(1);
  const [value, setValue] = useState("");
  const [all, setAll] = useState([]);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [count, setCount] = useState(0);

  // const limit = 5;

  const dispatch = useDispatch();

  // const handlePage = (event, value) => {
  //   setPage(value);
  // };

  const [onDelete, setOnDelete] = useState(false);
  const lang = useSelector((state) => state.lang.lang);

  // eslint-disable-next-line
  const loadMore = useCallback(
    entries => {
      const target = entries[0];
      console.log("intersection observed", target.isIntersecting, productLeft);
      if (target.isIntersecting && productLeft) {
        console.log("intersecting it");
        dispatch(loadMoreData(nextPage))
      }
      //TODO fix callback 
      // eslint-disable-next-line
    });

  useEffect(() => {
    const observer = new IntersectionObserver(loadMore, options);
    if (moreLoader.current) {
      const target = moreLoader.current;
      observer.observe(target)
    }
    return () => {
      observer.disconnect();
    }
  }, [loadMore])
  useEffect(() => {
    dispatch(getShoppableListNew(shoppableListData.page, setLoading));
    setLoading(() => true);
    // getShoppableList();
  }, [dispatch, shoppableListData.page])

  useEffect(() => {
    if (onDelete) {
      // getShoppableList();
      console.log("Deleted");
    }
  }, [onDelete]);
  useEffect(() => {
    if (shoppableListData.shoppableList.length > 0) {
      // console.log("catalog length", shoppableListData.shoppableList.length);
      //console.log(shoppableList);
      setResult(shoppableList);
      setAll(shoppableList);
      setLoading(() => false);
      // setCount(totalCount)
      //console.log("count",totalCount)
    }
    // eslint-disable-next-line
  }, [shoppableList])

  // const sendVerificationLink = () => {
  //   axios.post(process.env.REACT_APP_BASE + "/auth/verify", { userId: localStorage.getItem('user') }).then(response => {
  //     dispatch(openSnackbar("Verification email sent successfully!", "success"));
  //   }).catch(err => {
  //     dispatch(openSnackbar("Failed to send the email! Please try again", "error"));
  //   })
  // }

  const getShoppableList = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE}/shoppable/?limit=5&page=${page}&user=${localStorage.getItem("user")}&deleted=false`
      )
      .then((response) => {
        setLoading(() => false);
        // setCount(response.data.totalCount)
        setResult(response.data.data);
        setAll(response.data.data);
        console.log(response.data);
        setOnDelete(false);
      })
      .catch((err) => {
        setLoading(() => false);
        console.log(err);
        setOnDelete(false);
      });
  };

  useEffect(() => {
    // getShoppableList();
  }, []);
  // const reloadShoppableList = (e) => {
  //   console.log("Deleted")
  // }

  const handleSearch = (e) => {
    const value = e.target.value;
    setPage(1);
    setValue(value);
    if (value.length === 0) {
      getShoppableList()
    }
    if (value.length > 3)
      getSearchResults(value);
    // let results = all.filter(item => (item.campaignName.toLowerCase()).search(value.toLowerCase()) !== -1)
    // setResult(results);
  };

  const getSearchResults = (value) => {
    axios.post(`${process.env.REACT_APP_BASE}/shoppable/search?text=${value}`).then(response => {
      console.log(response.data)
      setResult(response.data.slice(0, 8));
    })
  }
  const clearSearch = () => {
    setValue("");
    let results = all.filter(item => (item.campaignName.toLowerCase()).search("".toLowerCase()) !== -1)
    setResult(results);
  };
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  if (currentUser.isVerified)
    return (
      <>
        {loading && <Loader show={true} />}
        {(shoppableList && shoppableList.length) > 0 && (
          <div className={clsx(classes.container)}>
            <div className={clsx(classes.innerWidth, classes.bodyMargin)}>
              <div

                className={classes.shoppableAdsTop}
              >
                <div className={classes.header}>
                  <Grid container className={classes.headerContainer}>
                    <Grid item md={3}>
                      <Typography
                        variant="h5"
                        className={classes.shoppableAdsHeader}
                      >
                        {lang && lang.shoppable_ads}
                      </Typography>
                    </Grid>
                    <Grid item md={7}>
                      <div style={{ width: '100%' }}>
                        <Grid container>
                          <Grid item md={12}>
                            <div

                              className={classes.shoppableAdsBox}
                            >
                              <InputBase
                                style={{
                                  height: 34,
                                  marginLeft: "0px",
                                  width: "90%",
                                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                                  borderRadius: 5,
                                  paddingLeft: 10,
                                  background: 'white',
                                  fontFamily: 'Roboto Light'
                                }}
                                className={classes.shoppableAdsSearchContainer}
                                placeholder={lang && lang.searchShoppableAd}
                                value={value}
                                onChange={handleSearch}
                                startAdornment={
                                  <InputAdornment>
                                    <Search />
                                  </InputAdornment>
                                }
                                endAdornment={
                                  value.length > 0 ? (
                                    <InputAdornment>
                                      <IconButton onClick={clearSearch}>
                                        <Close />
                                      </IconButton>
                                    </InputAdornment>
                                  ) : null
                                }
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      <Link to="/admin/shoppable" className={classes.createNewAdButton}>
                        <Button
                          style={{
                            borderRadius: 4,
                            color: "white",
                            height: 29,
                            width: "max-content",
                            fontSize: 10,
                            boxShadow: "none",
                            outline: "none",
                            background: "#0077B6",
                            fontFamily: 'Roboto Light'
                          }}
                          variant="contained"
                          color="primary"
                          startIcon={<AddOutlined />}
                        >
                          {lang && lang.createNewAd}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </div>




              </div>
              <Grid
                container
                direction="column"

                spacing="0" className={classes.shoppableAdsSearchContainer}
              >

                <Grid item style={{ paddingTop: 0 }}>
                  <div
                    style={{ marginTop: 12, borderRadius: 5 }}
                    className={clsx(
                      classes.container,
                      classes.whiteBackgroundStore
                    )}
                  >
                    <div
                      style={{ paddingBottom: 12 }}
                      className={clsx(
                        classes.storeHomeConmtainer,
                        classes.topMargin
                      )}
                    >
                      <Grid
                        container
                        direction="column"
                        spacing="1"
                        style={{ minHeight: 400 }}
                      >
                        {result &&
                          result.length > 0
                          && result
                            // .slice((page - 1) * limit, page * limit)
                            .map((item, i) => (
                              <Grid item key={i} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <StoreListItem
                                  item={item}
                                  onDelete={setOnDelete}
                                />
                              </Grid>
                            ))}
                      </Grid>
                    </div>

                  </div>
                  {/* <div
                    style={{ background: "white", paddingBottom: 12 }}
                    className={clsx(classes.container, classes.bodyMargin)}
                  >
                    <Pagination
                      color="primary"
                      count={Math.ceil(value && value.length ? 1 : (count / limit))}
                      page={page} size="small"
                      onChange={handlePage} className={classes.paginationContainerTiles}
                    />
                  </div> */}
                </Grid>
              </Grid>
              <div ref={moreLoader} className={shoppableList.length ? classes.creativeLoader : classes.creativeInvisible}>
                No More Catalogue Left
              </div>
            </div>
          </div>
        )}
        {all && all.length === 0 && !loading && <ShoppableEmpty />}
      </>
    );
  else
    return (
      <ShowVerifyComp />
      // <>
      //   <Container style={{ textAlign: "center" }}>
      //     <img
      //       src={verifyImg}
      //       style={{ width: "30%", marginBottom: "15px", marginTop: "15vh" }}
      //     />
      //     <h2> Verify your Email To Continue</h2>
      //     <Button
      //       onClick={sendVerificationLink}
      //       style={{ marginTop: 15, background: "#05006D", color: "#fff" }}
      //     >
      //       Verify Email
      //     </Button>
      //   </Container>
      // </>
    );
};

const mapStateToProps = (state) => ({
  shoppableListData: state.shoppableListData,
  shoppableList: state.shoppableListData.shoppableList,
  totalCount: state.shoppableListData.totalCount,
  nextPage: state.shoppableListData.nextPage,
  productLeft: state.shoppableListData.shoppableLeft
});
export default connect(mapStateToProps)(OnePageStores);
