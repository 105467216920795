import {
  Button, Grid, MenuItem, TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Select } from 'antd';
import axios from "axios";
import { industryTypes, marketplaceTypes } from "container/pricing/data";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { uploadImage } from "redux/catalogue/action";
import { openSnackbar } from "redux/snackbarStore";
import FileUpload from "../../components/FileDropZone/DropZone";
import classes from "./business.module.css";
import back from './business_arrow_back.png';

const maxstores = 3;
const emptystore = { url: "" };
const defaultform = {
  businessName: "",
  businessEmail: "",
  contact: "",
  taxid: "",
  address: "",
  businessType: "",
  bankAccountDetails: [],
  storeUrls: [{ ...emptystore }],
};

const Business = () => {
  // const [isEdit, setIsEdit] = React.useState(!sessionStorage.getItem("NEW_USER"));
  const [id, setId] = useState(null);
  const [formdata, setFormdata] = useState(defaultform);
  // const [filesToUpload, setFilesToUpload] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);
  const [categories, setCatagories] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const useStyles = makeStyles({
    titleRoot: {
      padding: "1em 2em",
      display: "flex",
      justifyContent: "space-between",
    },
    cancelButton: {
      backgroundColor: "#e0e0e0",
      color: "white",
      "&:hover": { backgroundColor: "#c0c0c0" },
      padding: "8px 16px"
    },
    saveButton: {
      backgroundColor: "#00CD9C",
      color: "white",
      "&:hover": { backgroundColor: "#44BCFF" },
      padding: "8px 32px"
    },
  });

  const muiClasses = useStyles();

  const getAllCategories = () => {
    axios
      .get(process.env.REACT_APP_BASE + "/catagory/all")
      .then((response) => {
        setCatagories(response.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCategories();
    axios
      .get(process.env.REACT_APP_BASE + "/auth/" + localStorage.getItem("user"))
      .then((response) => {
        if (response.data && response.data.businessDetails) {
          let newformdata = response.data.businessDetails;
          setId(newformdata._id);
          delete newformdata._id;
          setFormdata({ ...defaultform, ...newformdata });
          // if (!sessionStorage.getItem("NEW_USER"))
          //   setIsEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  const handleUpload = (files) => {
    // setFilesToUpload(files);
    handleImage2(files[0]);
  };

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const handleChangeBank = (e, index) => {
    let data = [...formdata.bankAccountDetails];
    data[index][e.target.name] = e.target.value;
    setFormdata({ ...formdata, bankAccountDetails: data });
  };

  const reader = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
  };

  const handleImage2 = async (file) => {
    try {
      console.log("Uploading to s3");
      // setLoading(true);
      const data = await reader(file);
      const values = {
        name: file.name,
        type: file.type,
        encoding: "base64",
        imageData: data,
      };
      const { url } = await uploadImage(values);
      // setLoading(false);
      setFormdata({ ...formdata, logo: url });
      // alert(url)
    } catch (error) {
      // setLoading(false);
      console.log(error.message);
    }
  };

  const handleDeleteImage = () => {
    setFormdata({ ...formdata, logo: null });
  };

  const handleSubmit = () => {
    // setLoading(true);
    let config = {
      method: "POST",
      url: process.env.REACT_APP_BASE + "/business/add",
      data: {
        ...formdata,
        id: localStorage.getItem("user"),
      },
    };

    if (id) {
      config.method = "PUT";
      config.url = process.env.REACT_APP_BASE + "/business/update/" + id;
    }
    axios(config)
      .then((response) => {
        // setLoading(false);
        if (response.data && response.data.status) {
          // console.log("<=======business", response.data)
          dispatch({ type: "BUSINESS_TOGGLE", profile: false });
          dispatch(openSnackbar("Business Created Successfully!", "success"));
          sessionStorage.removeItem("NEW_USER")
          // setIsEdit(true)
        }
      })
      .catch((err) => {
        // setLoading(false);
        dispatch(openSnackbar("Failed to create business!", "error"));
        console.log(err.response);
      });
  };

  const handleAddBank = () => {
    let data = [
      ...formdata.bankAccountDetails,
      {
        bankName: "",
        accountNumber: "",
      },
    ];

    setFormdata({ ...formdata, bankAccountDetails: data });
  };

  const handleSelectChange = name => val => setFormdata({ ...formdata, [name]: val });


  // const handleDeleteBank = (index) => {
  //   let data = [...formdata.bankAccountDetails];
  //   data.splice(index, 1);
  //   setFormdata({ ...formdata, bankAccountDetails: data });
  // };

  const handleAddStore = () =>
    setFormdata({
      ...formdata,
      storeUrls: [...formdata.storeUrls, { ...emptystore }],
    });

  // const handleDeleteStore = (index) =>
  //   setFormdata({
  //     ...formdata,
  //     storeUrls: formdata.storeUrls.filter((a, i) => i !== index) || [],
  //   });

  const handleMarketState = (e, s, i) => {
    // console.log('<==', { e: e.target.value, i })
    s[e.target.name] = e.target.value;
    formdata.storeUrls[i] = { ...s };
    // console.log('<==', { formdata })
    setFormdata({ ...formdata });
  };


  const somecomp1 = (h1, h2) => <Grid item container md={12} justifyContent='space-between'>
    <Grid item container xs={6} md={6}>
      <Grid item xs={11} md={11} className={classes.subHeading}>{h1}</Grid>
    </Grid>
    <Grid item container xs={6} md={6} justifyContent='flex-end'>
      <Grid item xs={11} md={11} className={classes.subHeading}>{h2}</Grid>
    </Grid>
  </Grid>

  const somecomp2 = (c1, c2) => <Grid item container md={12} justifyContent='space-between'>
    <Grid item container xs={6} md={6}>
      <Grid item xs={11} md={11} className={classes.subHeading__noMargin} >
        {c1}
      </Grid>
    </Grid>
    <Grid item container xs={6} md={6} justifyContent='flex-end'>
      <Grid item xs={11} md={11} className={classes.subHeading__noMargin}>
        {c2}
      </Grid>
    </Grid>
  </Grid>

  const somecomponent = (h1, h2, c1, c2) => <Grid item container md={12} >
    {somecomp1(h1, h2)}
    {somecomp2(c1, c2)}
  </Grid>

  const bankAccount = (bd, index) =>
    <Grid item container spacing={1} xs={12} key={bd.accountNumber} style={{ marginBottom: '8px' }}>
      {somecomp2(
        <TextField
          size="small"
          className={classes.whitebg}
          value={bd.bankName}
          onChange={(e) => handleChangeBank(e, index)}
          required
          fullWidth
          placeholder="Bank Name"
          name="bankName"
          variant="outlined"
        />,
        <TextField
          size="small"
          required
          className={classes.whitebg}
          variant="outlined"
          fullWidth
          value={bd.accountNumber}
          autoFocus
          onChange={(e) => handleChangeBank(e, index)}
          name="accountNumber"
          placeholder="Account Number"
        />
      )}
      {/* <Grid item xs={12} md={1} container alignItems="center" justifyContent="center">
        <Grid item>
          <span
            style={{
              cursor: "pointer",
              color: "#44BCFF",
              textAlign: "right",
            }}
            onClick={() => handleDeleteBank(index)}
          >
            Delete
          </span>
        </Grid>
      </Grid> */}
    </Grid>

  const storeurl = (store, i) => (
    <Grid item container xs={12} key={i} spacing={1} alignItems="center">
      {/* <Grid item xs={12} md={4}>
        <TextField
          select
          style={{
            color: store.marketPlaceNumber === "s" ? "#aaa" : "#000",
            backgroundColor: "white",
            color: "gray",
            // height: "41px",
          }}
          // label="Choose Your Destination Link"
          placeholder="Select Your Marketplace"
          // className={classes.root}
          variant="outlined"
          fullWidth
          name="marketPlaceNumber"
          value={
            store.marketPlaceNumber &&
              store.marketPlaceNumber !== "Marketplace "
              ? store.marketPlaceNumber
              : ""
          }
          onChange={(e) => handleMarketState(e, store, i)}
          size="small"
        >
          {marketPlaceOptions.map((item) => (
            <MenuItem
              value={item.value}
              disabled={item.disabled}
              default={item.default}
            >
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid> */}
      <Grid item xs={12} md={12}>
        <TextField
          variant="outlined"
          size="small"
          placeholder={"Store Url [eg- www.marketplacelink.com/product]"}
          fullWidth
          value={store?.url || ""}
          name="url"
          onChange={(e) => handleMarketState(e, store, i)}
          style={{ backgroundColor: "white", marginBottom: '0.5em' }}
        />
      </Grid>
      {/* <Grid item xs={12} md={1} container alignItems="center" justifyContent="center">
        <span
          style={{
            cursor: "pointer",
            color: "#44BCFF",
            textAlign: "right",
          }}
          onClick={() => handleDeleteStore(i)}
        >
          Delete
        </span>
      </Grid> */}
    </Grid>
  );


  // console.log({ formdata })
  // const size = 2;
  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} style={{ padding: "16px 32px" }} >
        <div className="business">
          <Grid item xs={12} style={{ fontSize: '18px', color: "#0077B6", cursor: 'pointer' }} onClick={() => history.push('/admin')}
            continer alignItems="center">
            <img src={back} style={{ height: '12px', objectFit: 'contain', marginRight: '12px' }} alt='back' />
            <span>Back</span>
          </Grid>
          <Grid item xs={12} style={{ fontSize: '32px', color: "#676767", fontWeight: 300 }}>Set Up your store</Grid>

          <div style={{ height: "auto", width: "100%" }}>
            <div>
              <form
                className={classes.profile__form}
                onSubmit={(e) => e.preventDefault()}
              >
                <Grid item container md={12} justifyContent='space-between'>
                  <Grid item container xs={12} md={6}>
                    <Grid item xs={12} md={11} className={classes.subHeading__noMargin} >
                      <Grid item xs={12} md={12} container>
                        <Grid item xs={12} md={12} className={classes.subHeading__noMargin}>Store name *</Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            className={classes.whitebg}
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="businessName"
                            placeholder="Business Name"
                            value={formdata.businessName}
                            onChange={handleChange}
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} md={12} className={classes.subHeading}>Email *</Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            size="small"
                            className={classes.whitebg}
                            fullWidth
                            variant="outlined"
                            value={formdata.businessEmail}
                            placeholder="Business email"
                            onChange={handleChange}
                            name="businessEmail"
                          ></TextField>
                          <br></br>
                        </Grid>

                        <Grid item xs={12} md={12} className={classes.subHeading}>Business Address *</Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            variant="outlined"
                            className={classes.whitebg}
                            fullWidth
                            multiline
                            rows={5}
                            defaultValue="Default Value"
                            value={formdata.address}
                            onChange={handleChange}
                            name="address"
                            placeholder="Business Address"
                          />
                        </Grid>

                        <Grid item md={12} className={classes.subHeading}>Industry/Category *</Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Select
                            value={formdata.industryType}
                            mode="multiple"
                            name='industryType'
                            className={classes.select}
                            onChange={handleSelectChange("industryType")}
                          // onChange={handleChange}
                          >
                            {industryTypes.map((e, i) => (
                              <Select.Option value={e.name} label={e.name} key={e.name}>
                                <div className="demo-option-label-item">{e.name}</div>
                              </Select.Option>
                            ))}
                          </Select>
                        </Grid>

                        <Grid item xs={12} md={12} className={classes.subHeading}>Business logo *</Grid>
                        <Grid item xs={12} md={12} className={`${classes.top_imageprofile__class}`}>
                          <FileUpload
                            className={classes.image__span}
                            handleUpload={handleUpload}
                            existing={formdata.logo}
                            handleDeleteImage={handleDeleteImage}
                          />
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6} justifyContent='flex-end'>
                    <Grid item xs={12} md={11} className={classes.subHeading__noMargin}>
                      <Grid item xs={12} md={12} container>
                        <Grid item xs={12} md={12} className={classes.subHeading__noMargin}>Phone *</Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            size="small"
                            className={classes.whitebg}
                            value={formdata.contact}
                            fullWidth
                            onChange={handleChange}
                            type="number"
                            name="contact"
                            variant="outlined"
                            required
                            placeholder="Phone"
                          />
                        </Grid>

                        {somecomponent(
                          "Business Type *",
                          "Tax ID *",
                          categories.length && (
                            // <div>
                            <TextField
                              select
                              className={classes.whitebg}
                              variant="outlined"
                              size="small"
                              fullWidth
                              // className={classes.inputs}
                              value={formdata.businessType}
                              name="businessType"
                              placeholder="Business Type"
                              onChange={handleChange}
                            >
                              {categories.length &&
                                categories.map((cat) => (
                                  <MenuItem value={cat.catagory} key={cat.category}>
                                    {cat.catagory}
                                  </MenuItem>
                                ))}
                            </TextField>
                            // </div>
                          ),
                          <TextField
                            fullWidth
                            size="small"
                            className={classes.whitebg}
                            value={formdata.taxid}
                            onChange={handleChange}
                            name="taxid"
                            variant="outlined"
                            required
                            placeholder="Tax ID"
                          />
                        )}

                        {somecomp1(
                          "Bank Name *",
                          "Account number *",
                        )}
                        {formdata.bankAccountDetails.map(bankAccount)}
                        {formdata.bankAccountDetails.length < 3 && (
                          <Grid item xs={12} md={10} className={classes.subHeading__noMargin}>
                            <Button
                              fullWidth
                              style={{
                                width: "fit-content",
                                border: "none",
                                color: "#6C7073",
                                fontSize: "1rem",
                                justifyContent: "flex-start",
                                fontWeight: "400",
                              }}
                              onClick={handleAddBank}
                              startIcon={<i className="fa-solid fa-plus"></i>}
                              color="primary"
                              variant="outlined"
                            >
                              Add Additional Bank Account
                            </Button>
                          </Grid>
                        )}

                        <Grid item md={12} className={classes.subHeading}>Marketplaces *</Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Select
                            value={formdata.marketplace}
                            mode="multiple"
                            name='marketplace'
                            className={classes.select}
                            onChange={handleSelectChange("marketplace")}
                          // onChange={handleChange}
                          >
                            {marketplaceTypes.map((e, i) => (
                              <Select.Option value={e.name} label={e.name} key={e.name}>
                                <div className="demo-option-label-item">{e.name}</div>
                              </Select.Option>
                            ))}
                          </Select>
                        </Grid>

                        <Grid item md={12} className={classes.subHeading}>E-commerce Store Link *</Grid>
                        {/* {storeurl(formdata?.storeUrls?.[0] || { url: "" }, 0)} */}
                        {formdata.storeUrls?.map(storeurl)}
                        {formdata.storeUrls.length < maxstores && (
                          <Grid item xs={12} md={10} className={classes.subHeading__noMargin}>
                            <Button
                              fullWidth
                              style={{
                                width: "fit-content",
                                border: "none",
                                color: "#6C7073",
                                fontSize: "1rem",
                                justifyContent: "flex-start",
                                fontWeight: "400",
                              }}
                              onClick={handleAddStore}
                              startIcon={<i className="fa-solid fa-plus"></i>}
                              color="primary"
                              variant="outlined"
                            >
                              Add Additional Store
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={1} justifyContent="center" style={{ marginTop: '1em' }}>
                  <Grid item>
                    <Button
                      classes={{ root: muiClasses.cancelButton }}
                      onClick={() =>
                        history.push("/admin")
                        // dispatch({ type: "BUSINESS_TOGGLE", profile: false })
                      }
                    >
                      CANCEL
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      classes={{ root: muiClasses.saveButton }}
                      onClick={handleSubmit}
                    >
                      SAVE
                      {/* {isEdit ? "Save" : "Create"} */}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
          {/* </DialogContent>
      </Dialog> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(Business);

/* <Dialog open={props.open} maxWidth={"sm"} fullWidth>
        <DialogTitle classes={{ root: muiClasses.titleRoot }} disableTypography> */
/* <Grid container justifyContent="flex-end"> */
/* <Typography variant="h6">
              {isEdit ? "Edit" : "Register"} Your Business Profile
            </Typography> */
/* <Box> */
/* <i
              style={{ color: "#999999", fontSize: 25 }}
              className="fa-solid fa-pen-to-square"
            ></i>
          </Grid> */

/* </Box> */
/* </DialogTitle> */
/* <DialogContent> */
/* ---------------------------- */
