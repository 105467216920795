import { Tooltip } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import chat from "../../src/static/img/newicons/chat.png";
import shoppingcart from "../../src/static/img/newicons/shopping-cart.png";

import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import BarChartIcon from '@material-ui/icons/BarChart';
import { srcToCDN } from "utility/utility";
import './sidenav.css';
import { isAccessablie } from "../utility/pricingplan";
import Upgradeplan from "../container/pricing/upgradeplan";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 80,
    marginTop: "4.5em",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: 55,
      overflowY: "auto",
      marginTop: "5em",
    },
  },
  listItem: {
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "0.5em",
    "&.MuiListItem-button svg:hover": {
      filter: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
  },
  activeNavItem: {
    backgroundColor: ["#00000066", "!important"],
  },
  listItemText: {
    // color: "#0077b6",
    color: "#fff",
    textAlign: "center",
    fontSize: "0.65rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.45rem",
    },
  },
  img: {
    color: "white",
    height: 25,
    width: 25,
    [theme.breakpoints.down("sm")]: {
      height: 25,
      width: 25,
    },
    [theme.breakpoints.down("xs")]: {
      height: 18,
      width: 18,
    },
  },
  others: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: theme.spacing(7) + 1,
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: theme.spacing(7) + 1,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  // drawerOpen: {
  //   width: drawerWidth,
  //   transition: theme.transitions.create('width', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  drawerClose: {
    overflowY: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "none",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

export default function SideNav() {

  const history = useHistory();
  const [activeNavItem, setActiveNavItem] = React.useState(0);
  // useEffect(() => {
  //   console.log("<=== activeNavItem", activeNavItem)
  // }, [activeNavItem])
  // console.log("<=== activeNavItem2", activeNavItem)

  const [upgrade, setUpgrade] = useState(false);
  const [activeNavOthersItem, setActiveNavOthersItem] = useState(null);
  // const currentUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  // const theme = useTheme();
  const { path } = useRouteMatch();
  const { user } = useSelector(state => ({ user: state.user }))

  // const pathName = window.location.pathname;
  // const pathArray = pathName.split(path);
  // const mainPath = pathArray[1];
  // const mainPathSplit = mainPath.split("/");

  const check = level =>
    isAccessablie(user?.businessDetails?.subscription?.userPlan, level, user?.businessDetails?.subscription?.status)

  const clickItem = (item, idx) => {
    if (!check(item.level)) return setUpgrade(true)
    setActiveNavItem(idx);
    setActiveNavOthersItem(null);
    history.push(item.path);
  }

  const sideNavItems = [
    {
      path: `${path}`,
      icon: <DashboardIcon />,
      // icon: dashboard,
      text: "Dashboard",
      button: true,
      tooltip: "",
      reactIcon: true,
      level: 1,
      onClick: clickItem,
    },
    {
      path: `${path}/catalogue`,
      // icon: grid,
      icon: <ListOutlinedIcon />,
      text: "Product Catalogue",
      button: true,
      tooltip: "",
      reactIcon: true,
      onClick: clickItem,
      level: 1,
    },
    {
      path: `${path}/shoppableHome`,
      icon: shoppingcart,
      // elem: props => <  { ...props } />,
      text: "Shoppable Ads",
      button: true,
      tooltip: "",
      reactIcon: false,
      onClick: clickItem,
      level: 1,
    },
    {
      path: `${path}/one-page-store`,
      icon: <StorefrontIcon />,
      text: "One Page Store",
      button: true,
      tooltip: "",
      reactIcon: true,
      onClick: clickItem,
      level: 1,
    },
    {
      path: `${path}/my-receipts`,
      // icon: payment,
      icon: <PaymentOutlinedIcon style={{ fill: 'white' }} />,
      text: "Payments",
      button: true,
      tooltip: "",
      reactIcon: true,
      onClick: clickItem,
      level: 2,
    },
    {
      path: `${path}/connect`,
      icon: chat,
      text: "Connect",
      button: true,
      tooltip: "",
      reactIcon: false,
      onClick: clickItem,
      level: 3,
    },
    {
      path: `${path}/reports`,
      icon: <BarChartIcon />,
      text: "Reports",
      button: true,
      tooltip: "",
      reactIcon: true,
      onClick: clickItem,
      level: 1,
    },
  ];

  const othersNavItems = [
    {
      path: `${path}/help`,
      // icon: circle,
      icon: <HelpOutlineOutlinedIcon style={{ fill: "white" }} />,
      text: "",
      tooltip: "Help",
      reactIcon: true,
      onClick: (idx) => {
        setActiveNavItem(null);
        setActiveNavOthersItem(idx);
      },
    },
    {
      path: `${path}`,
      // icon: settings,
      icon: <SettingsOutlinedIcon style={{ fill: "white" }} />,
      text: "",
      tooltip: "Settings",
      reactIcon: true,
      onClick: (idx) => {
        dispatch({ type: "BUSINESS_TOGGLE", profile: true });
        setActiveNavItem(null);
        setActiveNavOthersItem(idx);
      },
    },
  ];

  useEffect(() => {
    // console.log("....", { loc: window.location.pathname, sideNavItems, ind: sideNavItems.findIndex(i => window.location.pathname.startsWith(i.path)) })
    setActiveNavItem(sideNavItems.findIndex(i => window.location.pathname === i.path) ?? sideNavItems.findIndex(i => window.location.pathname.startsWith(i.path)) ?? -1)
    // eslint-disable-next-line
  }, [])


  return (
    <div className={classes.root}>
      <Upgradeplan open={upgrade} onClose={() => { setUpgrade(false) }} />
      <CssBaseline />

      <Drawer
        variant="permanent"
        anchor="left"
        open={true}
        style={
          { zIndex: 10 }
        }
      >
        <List className={clsx(classes.list) + " scrollthin"} dense>
          {sideNavItems.map((item, idx) => (
            <div key={item.path}>
              <ListItem
                button={item.button}
                disableGutters
                className={clsx(classes.listItem, {
                  [classes.activeNavItem]: idx === activeNavItem,
                })}
                style={{}}
                onClick={() => item.onClick(item, idx)}>
                {item.reactIcon ? (
                  item.icon
                ) : (
                  <img src={srcToCDN(item.icon)} alt="" className={clsx(classes.img)} />
                )}
                <ListItemText
                  className={clsx(classes.listItemText)}
                  disableTypography>
                  {item.text}
                </ListItemText>
              </ListItem>
            </div>
          ))}
        </List>
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }} />
        <List className={classes.others} dense>
          {othersNavItems.map((item, idx) => (
            <LightTooltip title={item.tooltip} placement="right" key={item.path}>
              <NavLink to={item.path}>
                <ListItem
                  button={item.button}
                  disableGutters
                  className={clsx(classes.listItem, {
                    [classes.activeNavItem]: idx === activeNavOthersItem,
                  })}
                  style={{ marginTop: "8px" }}
                  onClick={() => item.onClick(idx)}>
                  {item.reactIcon ? (
                    item.icon
                  ) : (
                    <img src={srcToCDN(item.icon)} alt="" className={clsx(classes.img)} />
                  )}
                </ListItem>
              </NavLink>
            </LightTooltip>
          ))}
        </List>
      </Drawer>
    </div>
  );
}
