import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { useMobileStyle, usePreviewStyle } from './useStyles'
import clsx from 'clsx'

const PagePreview = () => {
  const classes = {
    ...usePreviewStyle(),
    mobile: useMobileStyle()
  }
  return (
    <Grid container justifyContent='space-between' alignItems='center' direction='column' className={classes.mobile.container}>
      <Grid item className={classes.mobile.iphoneX}>

      </Grid>
      <Grid item container justifyContent='space-around'>
        <Button
          className={clsx(classes.button, classes.backButton)}
          onClick={() => { }}>
          BACK
        </Button>
        <Button
          className={clsx(classes.button, classes.nextButton)}
          onClick={() => {
          }}>
          NEXT
        </Button>
      </Grid>
    </Grid>
  )
}

export default PagePreview