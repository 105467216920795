import { Button, Dialog, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import React from 'react';

const useStyles = makeStyles({
  icon: {
    padding: 6,
    border: 20,
    backgroundColor: '#FF1111',
    color: 'white',
    borderRadius: 20,
    width: 40,
    height: 40,
    margin: 'auto',
  },
  root: {
    textAlign: 'center',
    padding: 40,
    paddingBottom: 0,
    marginBottom: 20,
    minWidth: 400
  }
})

function ConfirmationModal({ open, onClose, text }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}
    >
      <DialogContent className={classes.root}>
        <CheckCircleOutlinedIcon fontSize='large' style={{ color: 'rgb(0, 205, 156)' }} />
        {/* <img src={image} alt="img" style={{ width: 50, height: 50, borderRadius: 30, marginBottom: 20 }} /> */}
        <Typography variant="h5">{text}</Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button color="primary" variant="contained" style={{ backgroundColor: '#0077B6' }} onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
