export const logo = (marketplace, logo) => {
  switch (marketplace?.toLowerCase()) {
    case "tokopedialogo":
      // return tokopediaImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/tokopedialogo.png";
    case "shopeelogo":
      // return shopeeImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/shopeelogo.png";
    case "bliblilogo":
      // return BliBliImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/bliblilogo.png";
    case "lazadalogo":
      // return LazadaImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/lazadalogo.png";
    case "flipkartlogo":
      // return FlipkartImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/flipkartlogo.png";
    case "amazonlogo":
      // return AmazonImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/amazonlogo.png";
    case "tikilogo":
      // return TikiImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/tiki.png";
    case "zilingologo":
      // return Zilingo;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/zilingo.png";
    case "zaloralogo":
      // return Zalora;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/zalora.png";
    case "sendologo":
      // return Sendo;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/sendologo.png";
    case "facebooklogo":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/facebook.png";
    case "instagramlogo":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/instagramlogo.png";
    case "pinterestlogo":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/Pinterest.png";
    case "tiktoklogo":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/Tiktok.png";
    case "whatsapplogo":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/whatsapplogo.png";
    case "shpoifylogo":
      return "https://s3.ap-south-1.amazonaws.com/www.modcart.io/shopify.png";
    case "alllogo":
      return 'https://s3.ap-south-1.amazonaws.com/www.modcart.io/all.png';
    case "jd.idlogo":
    case "jdidlogo":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/jdid.png";
    case "youtubelogo":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/youtube.png";
    case "google mapslogo":
    case "googlemapslogo":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/googlemaps.png";
    case "my websitelogo":
    case "mywebsitelogo":
      return logo || "https://modcart-public.s3.us-east-2.amazonaws.com/website.png";
    case "klikindomaretlogo":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/klikindomaretlogo.png"

    case "tokopedia":
      // return tokopediaImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/tokopedia.png";
    case "shopee":
      // return shopeeImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/shopee.png";
    case "blibli":
      // return BliBliImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/BliBli.png";
    case "lazada":
      // return LazadaImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/lazada.png";
    case "flipkart":
      // return FlipkartImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/flipkart.png";
    case "amazon":
      // return AmazonImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/amazon.png";
    case "tiki":
      // return TikiImg;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/tiki.png";
    case "zilingo":
      // return Zilingo;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/zilingo.png";
    case "zalora":
      // return Zalora;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/zalora.png";
    case "sendo":
      // return Sendo;
      return "https://modcart-public.s3.us-east-2.amazonaws.com/sendo.png";
    case "facebook":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/facebook.png";
    case "instagram":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/Instagram.png";
    case "pinterest":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/Pinterest.png";
    case "tiktok":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/Tiktok.png";
    case "whatsapp":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/Whatsapp.png";
    case "shpoify":
      return "https://s3.ap-south-1.amazonaws.com/www.modcart.io/shopify.png";
    case "all":
      return 'https://s3.ap-south-1.amazonaws.com/www.modcart.io/all.png';
    case "jd.id":
    case "jdid":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/jdid.png";
    case "youtube":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/youtube.png";
    case "google maps":
    case "googlemaps":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/googlemaps.png";
    case "my website":
    case "mywebsite":
      return logo || "https://modcart-public.s3.us-east-2.amazonaws.com/website.png";
    case "klikindomaret":
      return "https://modcart-public.s3.us-east-2.amazonaws.com/klikindomaret.png"

    default:
      return null;
  }
};
