import Handlebars from "handlebars";
import React, { useEffect, useRef, useState } from "react";
import { logo } from "utility/marketplaceLogo";

// const getLength = (data1) => {
//   return data1.toString().length;
// };

// const hbr = `<main
//   id="container"
//   style="
//     width: 1080px;
//     height: 1080px;
//     margin: auto;
//     position: relative;
//     box-sizing: border-box;
//     background: no-repeat url({{backgroundImage}});
//     background-position: 0% 0%;
//     background-size: 100% 100%;

//     font-family: {{fontFamily}};
//       "
//   >
//   <a
//     href={{actionUrl1}}
//     style="display: block; text-decoration: none !important;color: inherit;"
//     target="_blank" id="one1" data-site-type={{actionName1}} 
//   >
//     <div style="display: grid; grid-template-columns: 1fr 1.5fr; height: 100%">
//       <div style="min-width: 435px; min-height: 1080px; background-color: {{backgroundColor}}; opacity: 0.4"></div>
//       <div style="position: relative">
//         <img
//           style="
//             max-width: 100px;
//             max-height: 100px;
//             object-fit: fill;
//             position: absolute;
//             top: 50px;
//             right: 50px;
//           "
//           src={{logoImg}}
//           alt=""
//         />
//       </div>
//     </div>

//     <div
//       style="
//         position: absolute;
//         top: 0;
//         left: 0;
//         max-width: 435px;
//         min-width: 435px;
//         min-height: 1080px;
//         padding: 40px;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         box-sizing: border-box;
//       "
//     >
//       <div style="padding-right: 30px">
//         <p style="color: {{fontColor}};
//         font-weight: 600;
//         font-size: {{campaignFontSize}};">{{campaignName}}</p>
//       </div>
//       <div>
//         <hr style="margin: 5px; color: #979797" />
//         <p style="font-weight: 800; margin: 0px; color: red; font-size: {{descriptionFontSize}};

//         ">
//           {{productDescription}}
//         </p>
//         <hr style="margin: 5px; color: #979797" />
//         <p style="font-weight: 800; margin: 0px; font-size: 22px">
//           Product Description
//         </p>
//         <hr style="margin: 5px; color: #979797" />
//         <p
//           style="
//             font-weight: 800;
//             margin: 0px;
//             margin-top: 5px;
//             font-size: 40px;
//           "
//         >
//         {{actionCurrency1}} {{actionDiscount1}}
//         </p>
//         <p style="margin: 5px; font-size: 30px">Old price: {{actionCurrency1}} {{actionPrice1}}</p>
//         <div style="display: flex; align-items: center">
//           <img
//             style="width: 100px; height: 100px; margin-right: 15px"
//             src={{actionImg1}}
//             alt=""
//           />
//           <p style="font-weight: 800; font-size: 40px; margin: 2px">SHOW NOW</p>
//         </div>
//       </div>
//     </div>
//   </a>
//     <div
//       style="
//         padding: 10px;
//         background-color: white;
//         position: absolute;
//         bottom: 30px;
//         right: 50px;
//         border-radius: 50%;
//         min-height: 110px;
//         min-width: 110px;
//         "
//     >
//       {{#ifCond whatsAppNumber}}
//       <a href="https://api.whatsapp.com/send?phone={{whatsAppNumber}}" target="_blank">
//           <img
//           style="max-width: 100px; max-height: 100px"
//           src="https://s3.us-east-2.amazonaws.com/modcart.public/whatsapp-logo.png"
//           alt=""
//           />
//         </a>
//       {{/ifCond}}
//     </div>
//   </main>
//   `;

// const setSize = (hbr, scale) => (scale ? hbr.replace("container", "sidePreview") : hbr);

const RightPanel = (props) => {
  // const [t, st] = React.useState(false);
  let hbr2 = Buffer.from(props.html, "Base64");
  // console.log("hbr2", hbr2);
  // let hbr = setSize(hbr2.toString(), props.scaleForImage);
  let hbr = hbr2.toString();
  // console.log("hbr string", hbr);
  // let htmlAd = new DOMParser().parseFromString(hbr, "text/html");
  // const htmlAd2 = htmlAd.getElementById("container");
  // console.log("htmlad2", htmlAd2);
  const adRef = useRef(null);

  // const doResize = (event, ui) => {
  //   var scale, origin;

  //   scale = Math.min(ui.size.width / elWidth, ui.size.height / elHeight);

  //   // $el.css({
  //   //   transform: "translate(-50%, -50%) " + "scale(" + scale + ")",
  //   // });
  // };

  useEffect(() => {
    // console.log("<= inside useffect", props.scaleForImage, props);
    const mainAd = document.getElementById("container");

    if (props.scaleForImage) {
      return
    }

    if (adRef.current && mainAd) {
      const offsetHeight = mainAd.offsetHeight;
      // const offsetWidth = mainAd.offsetWidth;
      console.log("offsetHeight", offsetHeight);
      // const scalableWrapper = adRef.current;
      // const availableWidth = scalableWrapper.clientWidth;
      // const availableHeight = scalableWrapper.clientHeight;
      // const scale = Math.min(
      //   availableWidth / offsetWidth,
      //   availableHeight / offsetHeight
      // );
      mainAd.classList.add("mainAd");
      // mainAd.style.transform = `translate(-50%,-50%) scale(${props.scaleForImage || scale})`;
      // scalableWrapper.resizable({ resize: doResize });
      // scalableWrapper.
      // const adParentContainer = adRef.current;
      // const mainAdContainer = adParentContainer.children[0];
      // const priceContainer = mainAdContainer.children[1];
      // priceContainer.classList.add("priceContainer");
      // const shopNowStripsContainer = priceContainer.children[1];
      // shopNowStripsContainer.classList.add("shopNowStripsContainer");
      // console.log("price container", shopNowStripsContainer);
    }
  });

  Handlebars.registerHelper("ifCond", function (whatsAppNumber, options) {
    if (whatsAppNumber) {
      return options.fn(this);
    }
    return options.inverse(this);
  });
  let template = Handlebars.compile(hbr);

  const [campaignLogo, setLogo] = useState("");

  useEffect(() => {
    if (props.logo && props.logo.name) {
      setLogo(URL.createObjectURL(props.logo));
    } else if (props.logo) {
      setLogo(props.logo);
    } else setLogo(null);
  }, [props.logo]);

  const price = props.marketplace1.pricing
    ? props.marketplace1.pricing.replace(",", "").replace(".", "")
    : null;
  const discountPrice = props.marketplace1.discount
    ? props.marketplace1.discount.replace(",", "").replace(".", "")
    : null;

  const discount = parseInt(((price - discountPrice) / price) * 100);

  const data = {
    backgroundColor: props?.backgroundColor,
    fontFamily: props?.fontFamily,
    fontColor: props?.fontColor,
    backgroundColor2: props?.backgroundColor2,
    backgroundImage: props?.backgroundImage,
    btnColor: "#ffffff",
    campaignName: props?.campaignName,
    campaignFontSize: props?.campaignFontSize + "px",
    descriptionFontSize: props?.descriptionFontSize + "px",
    productImg: props?.productImg,
    productDescription: props?.productDescription,
    actionImg1: logo(
      props?.marketplace1?.marketPlaceNumber + props?.actionImageSuffix,
      campaignLogo
    ),

    actionUrl1: props?.marketplace1?.marketurl,
    actionName1: props?.marketplace1?.marketPlaceNumber,
    actionCurrency1:
      props?.marketplace1?.currency === "IDR"
        ? "Rp"
        : props?.marketplace1?.currency,
    actionPrice1: props?.marketplace1?.pricing,
    actionDiscount1: props?.marketplace1?.discount,
    actionImg2: logo(
      props?.marketplace2?.marketPlaceNumber + props?.actionImageSuffix,
      campaignLogo
    ),
    actionUrl2: props?.marketplace2?.marketurl,
    actionName2: props?.marketplace2?.marketPlaceNumber,
    actionCurrency2:
      props?.marketplace2?.currency === "IDR"
        ? "Rp"
        : props?.marketplace2?.currency,
    actionPrice2: props?.marketplace2?.pricing,
    actionDiscount2: props?.marketplace2?.discount,
    actionImg3: logo(
      props?.marketplace3?.marketPlaceNumber + props?.actionImageSuffix,
      campaignLogo
    ),
    actionUrl3: props?.marketplace3?.marketurl,
    actionName3: props?.marketplace3?.marketPlaceNumber,
    actionCurrency3:
      props?.marketplace3?.currency === "IDR"
        ? "Rp"
        : props?.marketplace3?.currency,
    actionPrice3: props?.marketplace3?.pricing,
    actionDiscount3: props?.marketplace3?.discount,
    size: props?.size,
    logoImg: campaignLogo,
    discount: discount,
    whatsAppNumber: props?.whatsAppNumber,
    ratingColor: props?.ratingColor,
    productName: props?.productName,
    rating: props?.marketplace1?.rating,
  };

  console.log("<= data", data)

  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (props.size === "1080x1920") setScale(0.3);
    else if (props.size === "1080x1080") setScale(0.45);
    else if (props.size === "728x90") setScale(1);
    else if (props.size === "1200x628") setScale(0.5);
    else if (props.size === "300x250") setScale(1.5);
    else if (props.size === "320x50") setScale(2.25);
  }, [props.size]);

  useEffect(() => {
    props.setDownload(template(data));
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      {/* <div id="adContainer"></div> */}
      <div
        id={props.scaleForImage ? "" : "scalableWrapper"}
        className={props.scaleForImage ? "" : "scalableWrapper"}
        ref={adRef}
        style={{
          top: "0",
          transform:
            "scale(" + Math.min(props.scaleForImage || scale, scale) + ")",
        }}
        dangerouslySetInnerHTML={{ __html: template(data) }}
      ></div>
      {/* <div
        id="demo"
        // ref={adRef}
      >
        <h4>hello world</h4>
      </div> */}
    </>
  );
};

export default RightPanel;
