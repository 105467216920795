/* eslint-disable no-shadow */
import { Layout } from "antd";
import axios from "axios";
import propTypes from "prop-types";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { srcToCDN } from "utility/utility";
import AuthInfo from "../components/utilities/auth-info/info";
// import ChatDialog from "../container/dashboard/ChatDialog";
import { openSnackbar } from "../redux/snackbarStore";
import newlogo from "../static/img/newlogo.png";
import SideNav from "./Sidenav";
import { Div } from "./style";
import "./withAdminLayout.scss";

const { darkTheme } = require("../config/theme/themeVariables");
const { Header,
  // Footer, Sider, 
  Content } = Layout;

// const options = ["one", "two", "three"];
// const defaultOption = options[0];

const ThemeLayout = (WrappedComponent) => {
  // const userData = useSelector((state) => state.user);

  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: true,
        hide: true,
        searchHide: true,
        activeSearch: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
      this.sendVerificationLink = this.sendVerificationLink.bind(this);
    }

    componentDidMount() {
      window.addEventListener("resize", this.updateDimensions);
      // this.updateDimensions();
      this.props.history.listen(() => {
        // const collapsedValue = !this.state.collapsed;
        this.setState({
          // ...this.state,
          collapsed: true,
        });
      });
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    sendVerificationLink() {
      axios
        .post(process.env.REACT_APP_BASE + "/auth/verify", {
          userId: localStorage.getItem("user"),
        })
        .then((response) => {
          this.props.openSnackbar(
            "Verification email sent successfully!",
            "success"
          );
        })
        .catch((err) => {
          this.props.openSnackbar(
            "Failed to send the email! Please try again",
            "error"
          );
        });
    }


    render() {
      // const { classes } = this.props;

      return (
        <Div>

          <div style={{
            position: 'fixed',
            bottom: 0,
            right: 24,
            zIndex: 100,
          }}>
            {/* <ChatDialog /> */}
          </div>

          <Layout className="layout">
            <div style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: 20,
            }}>
              <Header
              // style={{
              //   //   position: "fixed",
              //   //   width: "100%",
              //   //   top: 0,
              //   //   zIndex: 9999,
              //   //   // zIndex: 2000,
              // }}
              >
                <div className="container-fluid">
                  <div className="row justify-content-between">
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}> */}
                    <div className="col-lg-3">
                      <a href='/admin'>
                        <img className="newlogo" style={{ cursor: 'pointer', }} src={srcToCDN(newlogo)} alt="" />
                      </a>
                      {/* Rise */}
                      {/* {userData?.businessDetails?.subscription?.userPlan} */}
                    </div>

                    <div className="col-lg-9">
                      <div>
                        {!this.props.currentUser && (
                          <div style={{ float: "right" }} onClick={this.sendVerificationLink}>
                            {" "}
                            Verify*{" "}
                          </div>
                        )}
                      </div>
                      <div>
                        <AuthInfo />
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </Header>
            </div>

            <Layout className="themeBoxLayout">
              {window.location.pathname !== "/admin/pricing" ?
                <ThemeProvider theme={darkTheme}>
                  {/* Side Nav Here */}
                  <SideNav />
                </ThemeProvider>
                :
                <></>
              }

              <Layout className="layoutLeftBox" >
                <Content>
                  <div style={{ paddingTop: '64px' }}>
                    <WrappedComponent {...this.props} />
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Div >
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      topMenu: state.ChangeLayoutMode.topMenu,
      user: state.auth.user,
      currentUser: state.auth.isVerified,
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  const mdtp = (dispatch) => {
    return {
      openSnackbar: (msg, severity) => dispatch(openSnackbar(msg, severity)),
    };
  };

  return connect(mapStateToProps, mdtp)(LayoutComponent);
};
export default ThemeLayout;
