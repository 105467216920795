import { Grid } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { base } from 'redux/form/actionCreator'
import PageStoreStepper from './components/PageStoreStepper'
import SelectMultiple from './components/SelectMultiple'
import PagePreview from './PagePreview'
import PageSelecton from './PageSelecton'
import { useHeaderStyle, usePreviewStyle, useSelectionStyle } from './useStyles'

const PageStore = () => {
    const { action } = useParams()
    const dispatch = useDispatch();
    const classes = {
        header: useHeaderStyle(),
        selection: useSelectionStyle(),
        preview: usePreviewStyle(),
    }
    const [selectedProducts, setSelectedProducts] = React.useState([])
    const [selection, setSelection] = useState([])
    const productData = async () => {
        const userId = localStorage.getItem("user");
        var data = await axios
            .get(`${base}/catalogue/all?userId=${userId}`)
            .then((res) => {
                return { type: "PRODUCT_DATA", payload: res.data.result };
            })
            .catch((err) => {
                console.log(err);
                return { type: null, payload: [] };
            });
        dispatch(data);
    };
    useEffect(() => {
        //TODO chagnge to store data
        productData();
        // eslint-disable-next-line
    }, [])

    return (
        <div id='adjustment' style={{ marginLeft: '20px', width: '98%' }}>
            <Grid container direction='column'>
                <Grid item container xs={12} direction='column' className={classes.header.main}>
                    <PageHeader action={action} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
                </Grid>
                <Grid item container xs={12} direction='row'>
                    <Grid item xs={12} md={7} direction='column' className={classes.selection.main}>
                        <PageSelecton selection={selection} setSelection={setSelection} />
                    </Grid>
                    <Grid item xs={12} md={5} direction='column' className={classes.preview.main}>
                        <PagePreview />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default PageStore

const PageHeader = ({ action, selectedProducts, setSelectedProducts, classes = {} }) => {
    const options = useSelector((state) => state.ProductData);
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={7}>
                    <SelectMultiple options={options} data={selectedProducts} setData={setSelectedProducts} labelKey={'productName'} />
                </Grid>
                <Grid item xs={12} md={5}>
                    <PageStoreStepper step={0} />
                </Grid>
            </Grid>
        </>
    )
}