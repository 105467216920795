import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useSelectionStyle } from './useStyles'

const PageSelecton = ({ selection, setSelection }) => {
  const classes = useSelectionStyle()

  const select = i => e => {
    console.log('<== name', i)
    if (selection.includes(i)) {
      setSelection([...selection.filter(a => a !== i)])
      return;
    }
    setSelection([...selection, i])
  }

  return (
    <Grid container className={classes.container}>
      {
        ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet', 'purple', 'pink', 'black', 'white', 'gray'].map((c, i) =>
          <Grid item xl={3} lg={4} md={6} sm={4} xs={6} key={i} className={classes.themeItems}>
            <div
              name={i}
              className={clsx(classes.themeUnselected, selection.includes(i) ? classes.themeSelected : '')}
              onClick={select(i)}
            >
              <div style={{ height: '500px', backgroundColor: c, borderRadius: '8px', }}>
              </div>
            </div>
          </Grid>
        )
      }


    </Grid>
  )
}

export default PageSelecton