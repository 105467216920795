import {  createTheme } from '@material-ui/core/styles';

export default createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      // 'sans-serif',
      'Roboto',
    ].join(','),
  },
});
