// import ReactGA from "react-ga";

// const TRACKING_ID = "G-JX95YS09CB";

// function init() {
//   // Enable debug mode on the local development environment
//   const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
//   console.log(isDev)
//   //ReactGA.initialize(TRACKING_ID, { debug: isDev });
//     ReactGA.initialize(TRACKING_ID)

// }

// function sendEvent(payload) {
//   ReactGA.event(payload)
// }

// function sendPageview(path) {
//   ReactGA.set({ page: path })
//   ReactGA.pageview(path)
// }

// export default {
//   init,
//   sendEvent,
//   sendPageview,
// }
import ReactGA from 'react-ga4';

export const initGA = () => {
    // if (process.env.NODE_ENV === 'development') {
    //     return;
    // }
    console.log("first called")
    ReactGA.initialize('G-JX95YS09CB', { debug: true, titleCase: false });
    //ReactGA.ga('set','checkProtocolTask',null)
    pageView();
};

export const pageView = () => {
    // if (process.env.NODE_ENV === 'development') {
    //     return;
    // }
    console.log(window.location.pathname + window.location.search)
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
};

export const fireEvent = (category = '', action = '', label = '') => {
    // if (process.env.NODE_ENV === 'development') {
    //     return;
    // }
    console.log("firing it")
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};