import { Dialog, DialogContent } from '@material-ui/core'
import { plansBadge } from '../pricing/data'
import React from 'react'

const size = '600px'

const TemplateDialog = ({ open, handleClose, themes }) => {

    return (
        <div>
            <Dialog open={!!open} onClose={handleClose} fullWidth maxWidth='lg'>
                <DialogContent>
                    <div style={{ width: '100%' }}>
                        <span style={{ cursor: 'pointer', float: 'right', fontSize: '16px' }} onClick={handleClose}>X</span>
                    </div>
                    <div style={{ display: 'flex', padding: '16px', overflowX: 'auto', width: '100%' }}>
                        {themes.filter(item => item.plan <= open).map(item =>
                            <div style={{ height: size, padding: '16px', position: 'relative' }}>

                                <img src={item.defaultTheme} style={{ height: size, objectFit: 'contain' }} alt="theme" />

                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 8,
                                        padding: '34px 12px',
                                        display: 'grid',
                                        placeItems: 'center'
                                    }}
                                >
                                    <div style={{ ...(plansBadge[item.plan] || {}), width: '60px', textAlign: 'center', fontSize: '12px', fontWeight: 'bold', padding: '4px 8px', borderRadius: '1000px' }}>
                                        {plansBadge[item?.plan]?.name}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 8,
                                        padding: '12px 12px',
                                        display: 'grid',
                                        placeItems: 'center'
                                    }}
                                >
                                    <div style={{ ...({ border: '1px solid white', backgroundColor: '#000000AA', color: 'white' }), fontSize: '12px', fontWeight: 'bold', padding: '4px 16px', borderRadius: '1000px' }}>
                                        {item.themeName}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default TemplateDialog