import axios from "axios"
import { base } from "redux/form/actionCreator"
import { SET_CATALOGUE_DATA, SET_EDIT_DATA, SET_SEARCH_DATA, LOAD_MORE_DATA, LOADING } from "./action.types"

export const getCatalogueData = () => async dispatch => {
    try {
        dispatch({ type: LOADING, payload: true })
        const userId = localStorage.getItem('user')
        console.log("IN REDUX")
        console.log(`${base}/catalogue/all?userId=${userId}`)
        const { data: { result } } = await axios.get(`${base}/catalogue/all?userId=${userId}`)
        dispatch({ type: LOADING, payload: false })

        return dispatch({ type: SET_CATALOGUE_DATA, payload: result })
    } catch (error) {
        if (error && error.response && error.response.data) return console.log(error.response.data);
        console.log(error.message)
        dispatch({ type: LOADING, payload: false })

    }
}

export const createCatalogue = (formdata) => async () => {
    try {
        // const userId = localStorage.getItem('user')
        const valuetosend = {
            ...formdata,
            pageTitle: formdata.pageTitle,
            brandLogo: formdata.logo,
            productDetails: formdata._id,

        }
        console.log(valuetosend)
        const { data } = await axios.post(`${base}/catalogue/add`, valuetosend);
        return data
    } catch (error) {
        if (error && error.response && error.response.data) return console.log(error.response.data)
        console.log(error.message)
    }
}

export const updateCatalogue = (formdata, id) => async () => {
    try {
        const { data } = await axios.put(`${base}/catalogue/update/${id}`, formdata)
        console.log(data)
        return data
    } catch (error) {
        if (error && error.response && error.response.data) return console.log(error.response.data)
        console.log(error.message)
    }
}

export const uploadImage = async (formdata) => {
    try {
        const { data } = await axios.post(`${base}/s3/upload`, formdata);
        return data;
    } catch (error) {
        if (error && error.response && error.response.data) return console.log(error.response.data)
        console.log(error.message)
    }
}

export const getKeywords = async () => {
    try {
        const { data } = await axios.get(`${base}/keyword/all`)
        return data.result
    } catch (error) {
        if (error && error.response && error.response.data) return console.log(error.response.data)
        console.log(error.message)
    }
}

export const createKeywords = async (formdata) => {
    try {
        const { data } = await axios.post(`${base}/keyword/add`, formdata)
        return data;
    } catch (error) {
        if (error && error.response && error.response.data) return console.log(error.response.data)
        console.log(error.message)
    }
}

export const getMarketData = async url => {
    try {
        const { data } = await axios.post(process.env.REACT_APP_BASE + "/ad/addetails", url);
        return JSON.parse(data)[0]
    } catch (error) {
        if (error && error.response && error.response.data) return console.log(error.response.data)
        console.log(error.message)
    }
}

export const searchCatalogue = formdata => dispatch => dispatch({ type: SET_SEARCH_DATA, payload: formdata })
export const setEditData = data => dispatch => dispatch({ type: SET_EDIT_DATA, payload: data })

export const loadMoreData = (result) => dispatch => {
    console.log("inside load More Action")
    dispatch({ type: LOAD_MORE_DATA, payload: result })
}