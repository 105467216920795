import { FETCH_PAYMENT_DATA, SEARCH_PAYMENT, SET_PAYMENT_DETAILS } from './action.types';

const initstate = {
    dataLoaded: false,
    loading: false,
    paymentList: [],
    backupList: [],
    payment_data: []

}

// function getObjects(obj, key, val) {
//     var objects = [];
//     for (var i in obj) {
//         if (!obj.hasOwnProperty(i)) continue;
//         if (typeof obj[i] == 'object') {
//             objects = objects.concat(getObjects(obj[i], key, val));
//         } else
//             //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
//             if (i == key && obj[i] == val || i == key && val == '') { //
//                 objects.push(obj);
//             } else if (obj[i] == val && key == '') {
//                 //only add if the object is not already in the array
//                 if (objects.lastIndexOf(obj) == -1) {
//                     objects.push(obj);
//                 }
//             }
//     }
//     console.log(objects)
//     return objects;
// }

export default function paymentReducer(state = initstate, action) {
    const { type, payload, dataLoaded } = action;
    switch (type) {
        case FETCH_PAYMENT_DATA:
            console.log(payload)
            return {
                ...state,
                data: [],
                dataLoaded: state.dataLoaded || dataLoaded,
                paymentList: payload,
                backupList: payload
            }
        case SEARCH_PAYMENT:
            console.log("inside this one", payload)
            const data = state.backupList?.filter((item) => (item?.name?.toLowerCase())?.search(payload?.toLowerCase()) !== -1) || [];
            // let obj=state.backupList;
            // //var objects = [];
            // const searchedObjects = []
            // obj.forEach((singleHeroObject, index) => {
            //     Object.values(singleHeroObject).every((onlyValues, valIndex) => {
            //     if(onlyValues.toLowerCase().includes(payload.toLowerCase())){
            //         console.log(singleHeroObject)
            //         searchedObjects.push(singleHeroObject)
            //         return;
            //     }
            //     })
            // });
            // console.log(searchedObjects);
            return {
                ...state,
                paymentList: data
            }
        case SET_PAYMENT_DETAILS:
            return {
                ...state,
                payment_data: payload
            }
        default: return state;
    }
}