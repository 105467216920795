import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: 0,
    fontSize: '32px',
    fontWeight: 400,
    color: '#6c7073',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
    },
  },
  mainContainer: {
    padding: 10,
    minHeight: 700,
    color: "#6c7073",
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  subHeading: {
    fontSize: '16px',
    fontWeight: 300,
    marginBottom: 0,
    marginTop: '10px',
    color: "#6c7073",
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },

  productName:{
    color: '#5b5b5b',
    backgroundColor:"#fff"
  },
  input: {
    fontSize: '14px',
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sansSerif",
    color: '#5b5b5b',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '8px',
    padding: '8px 10px',
    width: '100%',
    background: '#fff',
    borderRadius: '5px',
    border: 'none',
    outline: 'none',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },


  input__op: {
    fontSize: '14px',
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sansSerif",
    color: '#5b5b5b',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '8px',
    padding: '8px 10px',
    width: '100%',
    background: '#fff',
    borderRadius: '5px',
    border: 'none',
    outline: 'none',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '107%',
    },
  },





  typography: {
    fontWeight: 'bold',
    color: "#6c7073",
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  checkBoxLabel: {
    fontSize: '12px',
    width: '100%',
    color: "#6c7073",
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  rightPanel: {
    backgroundColor: 'white',
    width: '100%',
    height: '80vh',
    position: 'relative',
    minHeight: '600px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius:"24px",
    [theme.breakpoints.down('sm')]: {
      minHeight: '250px',
    },
  },
  rightPanelPreviewContainer: {
    width: 'fit-content',
    height: 'auto',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(-12%,-0%)scale(0.7) !important',
      top: '5%',
      left: '0%',
    },
  },
  saveChangesBtn: {
    position: 'fixed',
    backgroundColor: '#0077B6',
    right: 50,
    bottom: 85,
    [theme.breakpoints.down('sm')]: {
      right: 70,
    },
  },
  iconButton: {
    height: 30,
    width: 30,
    backgroundColor: '#e5e5e5',
    position: 'absolute',
    opacity: 0.6,
  },
  leftBtn: {
    position: 'absolute',
    left: '20%',
    top: '50%',
    zIndex: theme.zIndex.modal + 1,
  },
  rightBtn: {
    position: 'absolute',
    right: '20%',
    top: '50%',
    zIndex: theme.zIndex.modal + 1,
  },
  showStep: {
    zIndex: 3,
    position: 'absolute',
    top: '10%',
    backgroundColor: '#e5e5e5 !important',
    padding: '5px',
    borderRadius: '5px',
  },



}));

export default useStyles;
