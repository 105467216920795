import actions from "./actions";

const {
  UPLOAD,
  UPLOAD_IMAGE,
  UPLOAD_LOGO,
  UPLOAD_BRAND_LOGO,
  SELECT_TEMPLATE,
  UPDATE_FORM,
  UPDATE_DETAIL,
  CLEAR_FORM,
  GET_LIST,
  UPDATE_MARKETPLACE
} = actions;

const initState = {
  loading: false,
  error: null,
  template: "",
  template1: false,
  template2: false,
  template3: false,
  template4: false,
  title: "",
  shortDescription: "",
  description: "",
  marketplaceName: "",
  marketplaceUrl: "",
  marketplacelogoUrl: "",
  price: "",
  discount: "",
  adDetails: [],
  imageUrl: "",
  brandLogoUrl: "",
  fontStyle: "",
  backgroundColor: "",
  paletteColor: "",
  adlist: [],
  marketplaceData: {}
};

/**
 *
 * @todo impure state mutation/explanation
 */
const FormReducer = (state = initState, action) => {
  const { type, data } = action;
  // console.log("type", type, data)
  switch (type) {
    // case for image upload
    case UPLOAD:
      return {
        ...state,
        imageUrl: data
      };
    case UPLOAD_IMAGE:
      return {
        ...state,
        marketplaceUrl: data
      };
    case GET_LIST:
      return {
        ...state,
        adlist: data
      };
    case UPLOAD_LOGO:
      return {
        ...state,
        marketplaceLogo: data
      };
    case UPLOAD_BRAND_LOGO:
      return {
        ...state,
        brandLogoUrl: data
      };
    case SELECT_TEMPLATE:
      if (data === "template1") {
        return {
          ...state,
          template: data,
          template1: true,
          template2: false,
          template3: false,
          template4: false
        };
      } else if (data === "template2") {
        return {
          ...state,
          template: data,
          template1: false,
          template2: true,
          template3: false,
          template4: false
        };
      } else if (data === "template3") {
        return {
          ...state,
          template: data,
          template1: false,
          template2: false,
          template3: true,
          template4: false
        };
      } else if (data === 'template4') {
        return {
          ...state,
          template: data,
          template1: false,
          template2: false,
          template3: false,
          template4: true
        }
      }
      break;
    case UPDATE_FORM:
      // console.log(data)
      // console.log(data.metaData)
      return {
        ...state,
        ...data,
      };
    case UPDATE_DETAIL:
      // console.log('update running')
      return {
        ...state,
        adDetails: [...state.adDetails, data]
      };
    case UPDATE_MARKETPLACE:
      return {
        ...state,
        marketplaceData: data
      };
    case CLEAR_FORM:
      return {
        ...state,
        template: "",
        template1: false,
        template2: false,
        template3: false,
        title: "",
        shortDescription: "",
        description: "",
        marketplaceName: "",
        marketplaceUrl: "",
        price: "",
        discount: "",
        adDetails: [],
        imageUrl: "",
        brandLogoUrl: "",
        backgroundColor: '',
        backgroundColor1: '',
        paletteColor: '',
        paletteColor1: ''
      };
    case 'CLEAR_IMAGE': return {
      ...state, imageUrl: ''
    }
    default:
      return state;
  }
};
export default FormReducer;
