
import {
  Button, Grid, TextField
} from "@material-ui/core";
import { Link } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/snackbarStore";
import work from "../../../assets/shoppablesuccess.svg";
import classes from "./success.module.scss";

const Success = (props) => {
  const lang = useSelector((state) => state.lang.lang);
  // const classes2 = useStyle();

  useEffect(() => { window.scrollTo(0, 0); }, []);

  // const [showLink, setShowLink] = useState(false);
  const [products, setProducts] = React.useState([]);
  const [selectedOPS, setSelectedOPS] = useState(null);

  const mediaType = useSelector(
    (state) => state?.shoppableListData?.shoppableMediaType
  );

  const getOnePageStore = async () => {
    let ops = await axios.get(
      `${process.env.REACT_APP_BASE}/product/all?userId=${localStorage.getItem("user") ? localStorage.getItem("user") : null
      }`
    );
    setProducts(ops.data.result);
    console.log(ops.data.result);
  };

  useEffect(() => {
    getOnePageStore();
  }, []);

  useEffect(() => {
    if (selectedOPS) console.log(selectedOPS.domain.domain);
  }, [selectedOPS]);

  const handleChange = (e, v) => {
    setSelectedOPS(v);
  };

  useEffect(() => {
    if (props.location.state.product.onePageStore) {
      setSelectedOPS(props.location.state.product.onePageStore);
    }
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();

  const linkOPS = async () => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_BASE +
        `/shoppable/update/${props.location.state.id}`,
        {
          onePageStoreLink: `https://www.modcart.io/${selectedOPS.domain.domain}`,
          onePageStore: selectedOPS._id,
        }
      );
      console.log(response.data);
      // setShowLink(true);

      dispatch(openSnackbar("One Page Store Linked Successfully", "success"));
    } catch (err) {
      dispatch(openSnackbar("One Page Store Linking Failed", "error"));
    }
  };

  const downloadAll = (files, name) => {
    console.log("......", { files, name });
    const zip = JSZip();
    let filesZip = zip.folder("Ads");

    files.forEach((f) => filesZip.file(...f));
    zip
      .generateAsync({ type: "blob" })
      .then((content) => saveAs(content, name));
  };

  // console.log("<= preview", props.location.state.previews);
  console.log("<= files", props.location.state.files);

  return (
    <div className={classes.container}>
      <div className={classes.celebration}>
        <img src={work} className={classes.celebrationImage} alt={""} />
        <p className={classes.title}>{lang && lang.yay1}</p>
        <p className={classes.title}>{lang && lang.yay2}</p>
        <p className={classes.subtitle}>
          Launch your asset {props.location.state.product.shopTheme.size} on
          {props.location.state.isImage ? "Facebook/Instagram" : "Eskimi"} to
          get more reach
        </p>
        {props.location.state.product.shopTheme &&
          (props.location.state.product.shopTheme.themeName.includes(
            "Ramadan"
          ) ||
            props.location.state.product.shopTheme.themeName.includes(
              "ThemeEleven"
            )) && (
            <p style={{ margin: "0px" }}>
              {" "}
              In Eskimi, the slider will play Automatically
            </p>
          )}

        <Grid
          container
          spacing={1}
          alignItems={"center"}
          justifyContent="center"
        >
          <Grid item md={8}>
            <Autocomplete
              fullWidth
              options={products}
              // multiple
              value={selectedOPS ? selectedOPS : ""}
              // value={products}
              getOptionSelected={(option, value) => {
                return option.pageTitle === value.pageTitle;
              }}
              onChange={handleChange}
              getOptionLabel={(option) => {
                const label = Array.isArray(option)
                  ? option[0].pageTitle
                  : option.pageTitle;
                // return option.productName
                return label;
              }}
              // style={{ width: "100%", margin: "auto", marginBottom: 8 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Link Your One Page Store With Shoppable"
                  variant="outlined"
                  size="small"
                // fullWidth
                // InputProps={{
                //   ...params.InputProps,
                // }}
                />
              )}
            />
          </Grid>
          <Grid item md={2}>
            <Button
              size="medium"
              style={{
                background: !selectedOPS ? "#d4d4d4" : "#00CD9C",
                color: "#fff",
                height: "100%",
              }}
              variant="contained"
              fullWidth
              disabled={!selectedOPS}
              onClick={() => linkOPS()}
            >
              Link
            </Button>
          </Grid>
          {selectedOPS && (
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                href={`https://www.modcart.io/${selectedOPS.domain.domain}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`https://www.modcart.io/${selectedOPS.domain.domain}`}
              </a>
              <Link
                style={{
                  color: "grey",
                  marginLeft: 8,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://www.modcart.io/${selectedOPS.domain.domain}`
                  );
                }}
              />
            </Grid>
          )}
        </Grid>

        <div className={classes.links}>
          {props.location.state.files?.length > 0 ? (
            <>
              <span
                style={{
                  background: "#0077B6",
                  padding: "15px 25px",
                  color: "#fff",
                  borderRadius: 5,
                }}
                onClick={() =>
                  downloadAll(props.location.state.files, "modcart-script.zip")
                }
              >
                {/* {lang && lang.downloadEskimiScript} */}
                {mediaType === "Facebook" || mediaType === "Instagram" || props?.location.state?.files?.[0]?.[1]?.type
                  ? "Download Images"
                  : lang && lang.downloadEskimiScript}
                {"  "}
              </span>
            </>
          ) : (
            <>
              {!props.location.state.isImage && (
                <>
                  {/* <div>{props.location.state.tag}</div> */}
                  <span
                    style={{
                      background: "#0077B6",
                      padding: "15px 25px",
                      color: "#fff",
                      borderRadius: 5,
                    }}
                    onClick={() =>
                      saveAs(props.location.state.file, "modcart-script.zip")
                    }
                  >
                    {lang && lang.downloadEskimiScript}
                    {"  "}
                  </span>
                </>
              )}
              {props.location.state.isImage && (
                <span
                  style={{
                    background: "#0077B6",
                    padding: "15px 25px",
                    color: "#fff",
                    borderRadius: 5,
                    height: "40px",
                  }}
                  onClick={() =>
                    saveAs(props.location.state.file, "modcart-ad.png")
                  }
                >
                  {lang && lang.downloadShoppable}
                </span>
              )}
            </>
          )}
        </div>
      </div>

      <div style={{ padding: "8px 16px", width: "60%" }}>
        <div
        // className={classes2.phone}
        >
          {/* {console.log(URL.createObjectURL(props.location.state.preview))} */}
          {/* <IphonePreview adUrl={props.location.state.preview} /> */}

          {/* <Grid container spacing={2} direction="row" >
            {props?.location?.state?.previews &&
              props?.location.state?.previews.map((e) => (
                <Grid item xs={12} sm={12} md={6} >
                  <p dangerouslySetInnerHTML={{ __html: e[1] }} />
                  {e?.file ?
                    <img style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} src={URL.createObjectURL(e.file)} />
                    :
                    <div style={{ width: '100%' }}>Preview Not available</div>
                  }
                </Grid>

        
              ))}
          </Grid> */}

          <Grid container spacing={2} direction="row">
            {props?.location?.state?.files &&
              props?.location.state?.files.map((e, i) => (
                <Grid item xs={12} sm={12} md={6}>
                  {e ? (
                    <Grid item xs={12} container direction='column' justifyContent="center" alignItems="center">
                      <Grid xs={'auto'}>
                        {mediaType === "Facebook" || mediaType === "Instagram" || e[1]?.type ? (
                          <img width="400px" style={{ objectFit: "contain" }} src={URL.createObjectURL(e[1])} alt="ad" />
                        ) : (
                          <iframe
                            title={`${e?.[1]?.[0]}-${i}`}
                            scrolling="no"
                            frameBorder="0"
                            style={{
                              width: `${parseInt(props?.location.state?.width) +
                                24}px `,
                              height: `${parseInt(props?.location.state?.height) +
                                24}px`,
                            }}
                            // src={`data:text/html;charset=utf-8,${e[1]}`}
                            src={`data:text/html;charset=utf-8,${e[1]}`}
                          />
                        )}
                      </Grid>
                      <Grid xs={'auto'}>
                        <h3>
                          {e[0].substring(0, e[0].lastIndexOf('.')).trim()}
                        </h3>
                      </Grid>
                    </Grid>
                  ) : (
                    <div style={{ width: "100%" }}>Preview Not available</div>
                  )}
                </Grid>
              ))}
          </Grid>

          {/* <img
            src={
              props.location.state.preview
                ? URL.createObjectURL(props.location.state.preview)
                : phoneDisplay
            }
            style={{
              // marginTop: "230px",
              // marginLeft: "25px",
              // height: "500px",
              position: "absolute",
              WebkitTransform: "translate(-50%,-50%)",
              top: "45vh",
              left: "45.5%",
              width: "auto",
              maxWidth: "220px",
              maxHeight: "400px",
            }}
            alt=""
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Success;
