const planToLevel = {
    "Start": 1,
    "Rise": 2,
    "Grow": 3,
    "Pro": 4,
    "Enterprise": 5,
}

export const isAccessablie = (userPlanId, minLevel, status = 0) =>
    (+(planToLevel[userPlanId] || 1) >= +minLevel)
    && (minLevel > 1 ? status === 1 : true)