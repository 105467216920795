import { GET_SINGLE_OPS, SEARCH_OPS, SET_ONE_PAGE_STORE,LOADING, LOAD_MORE_DATA1 } from "./action.type";

const initstate = {
    loading: false,
    startIndex: 0,
    requestSize: 5,
    storeList: [],
    productLeft: true,
    data: [],
    result: [],
    
};

export default function onePageStoreReducer(state = initstate, action) {
    const { type, payload } = action;
    switch (type) {
        case LOADING: return{
            ...state,
            loading:payload
        }
        case SET_ONE_PAGE_STORE:
            state.startIndex=0;
            state.requestSize=5;
            state.productLeft=true;
            const end = state.startIndex + state.requestSize;
            let items=payload.slice(state.startIndex,end);
            const productLeft = (items && items.length) === state.requestSize;

        return {
            ...state, 
            result: payload,    
            data: payload.slice(state.startIndex, end), 
            requestSize:5,    
            productLeft:productLeft,
            startIndex:end
        }
        case LOAD_MORE_DATA1:
            let endIndex = state.startIndex + state.requestSize;
            let itemsNew = state.result.slice(state.startIndex, endIndex);
            const productLeft1 = (itemsNew && itemsNew.length) === state.requestSize;
            return {
                ...state,
                data: state.data.concat(itemsNew),
                startIndex: endIndex,
                productLeft: productLeft1
            }
        case 'RESET_STATE': return {}
        case SEARCH_OPS: 
            const data = state.result.filter(item=>(item.pageTitle.toLowerCase()).search(payload.toLowerCase()) !==-1)
            return {...state, data }
        case GET_SINGLE_OPS: return { ...state, single: payload }
        default: return state;
    }
}