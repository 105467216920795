import { SET_CATALOGUE_DATA, SET_EDIT_DATA, SET_SEARCH_DATA, LOAD_MORE_DATA, LOADING } from "./action.types";

const initstate = {
    loading: false,
    requestSize: 8,
    productList: [],
    showMore: false,
    productStartIndex: 0,
    productLeft: true,
    data: []
};

export default function catalogueReducer(state = initstate, action) {
    const { type, payload } = action;
    switch (type) {
        case LOADING: return {
            ...state,
            loading: payload
        }
        case SET_CATALOGUE_DATA:
            state.productStartIndex = 0;
            state.productLeft = true;
            const end = state.productStartIndex + state.requestSize;
            // console.log("product Left",productLeft,state.requestSize,payload.length)
            let items = payload.slice(state.productStartIndex, end);
            const productLeft = (items && items.length) === state.requestSize;
            console.log("product Left", productLeft)

            return {
                ...state,
                data: payload.slice(state.productStartIndex, end),
                productList: payload,
                result: payload,
                productStartIndex: end,
                productLeft: productLeft
            }
        case SET_EDIT_DATA: return { ...state, edit: payload }
        case SET_SEARCH_DATA:
            const data = state.result.filter((item) => (item.productName.toLowerCase()).search(payload.toLowerCase()) !== -1)
            return { ...state, data };
        case LOAD_MORE_DATA:
            console.log("Loading more amount");
            let endIndex = state.productStartIndex + state.requestSize;
            // console.log("product Left",productLeft,state.requestSize,payload.length)
            // let items=payload.slice(state.productStartIndex,end);
            let itemsNew = state.productList.slice(state.productStartIndex, endIndex);
            console.log(itemsNew);
            const productLeft1 = (itemsNew && itemsNew.length) === state.requestSize;
            console.log("product Left", productLeft1)
            return {
                ...state,
                data: state.data.concat(itemsNew),
                productStartIndex: endIndex,
                productLeft: productLeft1
            }
        default: return state;
    }
}