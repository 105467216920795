// import initialStateGroup from "../../demoData/usersGroupData.json";
import { LOAD_THE_USER } from "./actions";

const initialState = {};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_THE_USER: return payload;
    default: return state;
  }
};

export { userReducer };

