import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import styles from './pricing.module.css';
import { Button, Select } from 'antd';
import { currencies, currentPlanStyles, industryTypes, marketplaceTypes, plans, selectedPlanStyles } from './data';
import PricingCard from './PricingCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './pricing.css'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from "redux/snackbarStore";
import { numberToCurrency } from '../../utility/utility';
import { getAllTheme } from '../../redux/theme/action';
import TemplateDialog from './TemplateDialog';

const Pricing = () => {

    const dispatch = useDispatch();
    const history = useHistory()
    const themes = useSelector((state) => state.theme.themes);

    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(null); // business details document id

    const token = localStorage.getItem("access_token");
    if (token) var decoded = jwt_decode(token);

    const [
        currency,
        // setCurrency
    ] = useState(currencies[1])
    const [form, setForm] = useState({
        businessName: "",
        industryType: [],
        marketplace: [],
        // selectedPrice: "Start",
        // email: decoded?.email
    })

    const [form2, setForm2] = useState({
        selectedPrice: "Start",
        email: decoded?.email
    })

    const [states, setState] = useState({
        newUser: !!sessionStorage.getItem("NEW_USER"),
        savedStoreData: !sessionStorage.getItem("NEW_USER"),
        errorSavedStoreData: false,
    })

    const setSelecetdPrice = price => setForm({ ...form, selectedPrice: price })
    const selectedPlan = plans.find(p => p.id === form.selectedPrice) || {}

    const [sendEmail, setSendEmail] = useState(false)
    const [showTemplate, setShowTemplate] = useState(false)

    const newUser = !!sessionStorage.getItem("NEW_USER");

    // eslint-disable-next-line
    useEffect(() => { dispatch(getAllTheme()); }, [])

    // const handleCurrencyChange = v => setCurrency(currencies.find(i => i.id === v))

    // const handleChange1 = e => setForm({ ...form, [e.target.name]: e.target.value })
    const handleChange = e => setForm2({ ...form2, [e.target.name]: e.target.value })
    const handleSelectChange = name => val => setForm({ ...form, [name]: val });
    const selectPrice = id => e => setSelecetdPrice(id)

    const closeDialog = () => setSendEmail(false)
    const sendMail = () => {
        setSendEmail(false)
    }

    const getPaymentData = (sub) => ({
        name: sub?.name,
        email: sub?.email,
        phone: sub?.phoneNo,
        address1: sub?.address?.line1,
        address2: sub?.address?.line2,
        city: sub?.address?.city,
        province: sub?.address?.province,
        postcode: sub?.address?.postcode,
    })

    const loadData = (level = 0) => {
        const base = process.env.REACT_APP_BASE
        axios.get(base + "/auth/" + localStorage.getItem("user"))
            .then((response) => {
                if (response.data && response.data.businessDetails) {
                    let newformdata = response.data.businessDetails;
                    // if (newformdata.subscription && newformdata.subscription.name !== 'Start' && newformdata.subscription.status === 0) {
                    //     let config = {
                    //         method: "PUT",
                    //         url: process.env.REACT_APP_BASE + "/business/update/" + newformdata._id,
                    //         data: { subscription: null }
                    //     };
                    //     axios(config)
                    //         .then((response) => { loadData() })
                    //         .catch((err) => {
                    //             dispatch(openSnackbar("An error occured!", "error"));
                    //             window.location.reload();
                    //         });
                    //     return;
                    // }
                    setId(newformdata._id);
                    delete newformdata._id;
                    setForm({ ...form, ...newformdata, ...getPaymentData(newformdata.subscription || {}), selectedPrice: newformdata?.subscription?.userPlan || "Start" });
                    setForm2({ ...form, ...newformdata, ...getPaymentData(newformdata.subscription || {}), selectedPrice: newformdata?.subscription?.userPlan || "Start" });
                }
            })
            .catch((err) => { if (level < 2) loadData(level + 1); console.log(err); })
    }

    useEffect(() => {
        loadData()
        // eslint-disable-next-line
    }, [])

    // useEffect(() => {
    //     if (!!form.businessName && !!form.industryType && !!form.marketplace) {
    //         setState({ ...states, savedStoreData: true, errorSavedStoreData: false })
    //     }
    //     // eslint-disable-next-line
    // }, [form])

    const handlePay = () => {
        if (!form2.name || form2.name.length < 1) {
            dispatch(openSnackbar("Name is required!", "error"))
        } else if (!form2.email || form2.email.length < 1) {
            dispatch(openSnackbar("Email Address is required!", "error"))
        } else if ((!form2.email.match(/.*@[a-zA-Z0-9]*\.[a-z0-9]*/))) {
            dispatch(openSnackbar("Invalid Email!", "error"))
        } else if (!form2.address1 || form2.address1.length < 1) {
            dispatch(openSnackbar("Shipping Address is required!", "error"))
        } else if (!form2.city || form2.city.length < 1) {
            dispatch(openSnackbar("Shipping City is required!", "error"))
        } else if (!form2.province || form2.province.length < 1) {
            dispatch(openSnackbar("Province is required!", "error"))
        } else if (!form2.postcode || form2.postcode.length < 1) {
            dispatch(openSnackbar("Postal Code is required!", "error"))
        } else if (!form2.phone || form2.phone.length < 1) {
            dispatch(openSnackbar("Phone No is required!", "error"))
        } else {
            let data = {
                name: form2.name,
                address: {
                    line1: form2.address1,
                    line2: form2.address2,
                    city: form2.city,
                    province: form2.province,
                    postcode: form2.postcode,
                },
                phoneNo: form2.phone,
                email: form2.email,
            };
            data.userId = localStorage.getItem('user');
            data.userPlan = selectedPlan.name
            data.status = 0; // Order creation no payment
            data.amount = +selectedPlan.costamount

            // eslint-disable-next-line
            if (data.amount > 0) window.snap?.show();
            ; (async () => {
                const urlbase = process.env.REACT_APP_BASE
                // const urlbase = "http://localhost:5000"
                const id = await axios.post(`${urlbase}/subscription/`, data)
                    .then(res => res.data._id)
                    .catch(() => dispatch(openSnackbar("Error Occured! Try Again")));
                if (data.amount <= 0) {
                    window.location.reload();
                    return;
                }
                const token = await axios.get(`${urlbase}/payment/token-sub/${id}`);
                // eslint-disable-next-line
                window?.snap?.pay((token.data))
            })();

            // axios.post(`${process.env.REACT_APP_BASE}/receipt/add`, data).then(res => {
            //     setLoading(false)
            //     setFormdata({ name: "", address: "" })
            //     dispatch(openSnackbar("Order Created Succesfully!", "success"))
            //     // window.location.replace(`https://wa.me/${marketPlace}?text=${message}${form2.shippingAddress}`)
            // }).catch(err => {
            //     console.log(err.response)
            //     setLoading(false)
            //     dispatch(openSnackbar("Order Unsuccesfull!", "error"))
            // })
        }
    }

    const onSaveData = () => {
        setLoading(true)
        if (!form.businessName || form.businessName.length < 1) {
            return dispatch(openSnackbar("Store Name is required!", "error"))
        } else if (!form.industryType || form.industryType.length < 1) {
            return dispatch(openSnackbar("Industry Type is required!", "error"))
        } else if (!form.marketplace || form.marketplace.length < 1) {
            return dispatch(openSnackbar("Marketplace is required!", "error"))
        }
        let config = {
            method: "POST",
            url: process.env.REACT_APP_BASE + "/business/add",
            data: { ...form, id: localStorage.getItem("user"), businessEmail: decoded?.email }
        };
        axios(config)
            .then((response) => {
                if (response.data && response.data.status) {
                    dispatch(openSnackbar("Data Saved Successfully!", "success"));
                    setState({ ...states, savedStoreData: true, errorSavedStoreData: false });
                    setId(response.data?._id)
                }
            })
            .catch((err) => {
                dispatch(openSnackbar("Failed to save Data!", "error"));
                setState({ ...states, savedStoreData: false, errorSavedStoreData: true });
                console.log(err.response);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onSubmit = save => () => {
        // Skip
        if (!states.savedStoreData) {
            dispatch(openSnackbar("Store data is required", "error"));
            setState({ ...states, errorSavedStoreData: true });
            return;
        }
        if (!save) {
            // loadData();
            if (newUser) history.push("/admin/business-profile"); else history.push("/admin")
            return;
        }

        // setLoading(true);
        // const data = { ...form2, pricingPlan: form2.selectedPlan }
        let postData = { ...form2, pricingPlan: form.selectedPrice, id: localStorage.getItem("user"), }

        if (form.selectedPrice === "Start") {
            postData = { ...postData, subscription: null }
        }
        let config = {
            method: "POST",
            url: process.env.REACT_APP_BASE + "/business/add",
            data: postData
        };
        if (id) {
            config.method = "PUT";
            config.url = process.env.REACT_APP_BASE + "/business/update/" + id;
        }
        axios(config)
            .then((response) => {
                // setLoading(false);
                if (response.data && response.data.status) {
                    dispatch(openSnackbar("Plan Started Successfully!", "success"));
                    // loadData();
                    if (newUser) history.push("/admin/business-profile"); else history.push("/admin")
                }
            })
            .catch((err) => {
                // setLoading(false);
                dispatch(openSnackbar("Failed to start Plan!", "error"));
            });
    }

    // const step = (n, t) => <h1 className={styles.stepHeading}><span>Step {n}: {t}</span></h1>
    const get = (n, t) => <h1 className={styles.stepHeading} key={n}><span>{t}</span></h1>

    const step1 = (edit = false) => <Grid container className={styles.step}>
        {/* <Grid item xs={12}>{step(1, "Store details")}</Grid> */}
        {edit ?
            <Grid item xs={12}>{get(1, "Let's get your Store details")}</Grid>
            :
            <Grid item xs={12}></Grid>
        }
        <Grid item container xs={12} direction='row' spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
                {edit ?
                    <>
                        <span className={styles.label} style={states.errorSavedStoreData ? { color: 'red' } : {}}>Your Store Name *</span>
                        <input name='businessName' value={form.businessName || ""} className={styles.input} onChange={e => setForm({ ...form, businessName: e.target.value })} />
                    </>
                    :
                    <>
                        <span>{form.businessName}'s Store</span>
                    </>
                }
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                {edit ?
                    <>
                        <span className={styles.label} style={states.errorSavedStoreData ? { color: 'red' } : {}}>Select Your Industry/Category *</span>
                        <Select
                            value={form.industryType}
                            mode="multiple"
                            className={styles.select}
                            onChange={handleSelectChange("industryType")}
                        >
                            {industryTypes.map((e, i) => (
                                <Select.Option value={e.name} label={e.name} key={e.name}>
                                    <div className="demo-option-label-item">{e.name}</div>
                                </Select.Option>
                            ))}
                        </Select>
                    </>
                    :
                    <>
                        <span>Industry/Category: {form.industryType.join(", ")}</span>
                    </>
                }
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                {edit ?
                    <>
                        <span className={styles.label} style={states.errorSavedStoreData ? { color: 'red' } : {}}>Your Prefered Marketplaces *</span>
                        <Select
                            value={form.marketplace}
                            mode="multiple"
                            className={styles.select}
                            onChange={handleSelectChange("marketplace")}
                        >
                            {marketplaceTypes.map((e, i) => (
                                <Select.Option value={e.name} label={e.name} key={e.name}>
                                    <div className="demo-option-label-item">{e.name}</div>
                                </Select.Option>
                            ))}
                        </Select>
                    </>
                    :
                    <>
                        <span>Marketplaces: {form.marketplace.join(", ")}</span>
                    </>
                }
            </Grid>
        </Grid>
        {newUser &&
            <Grid item container xs={12} direction='row' spacing={4} justifyContent='flex-end'>
                <Grid item>
                    <button className={`${styles.actionbutton} ${styles.allsetButton}`} style={{ marginBlock: '0.5em' }} disabled={loading} onClick={onSaveData}>Save</button>
                </Grid>
            </Grid>
        }
    </Grid>

    const step2 = () => <Grid container className={styles.step}>
        <Grid item container xs={12} style={{ marginBottom: '1em', marginLeft: '0.75em' }}>
            {/* <Grid item xs={12} md={9} lg={9}>{step(2, "Confirm Pricing")}</Grid> */}
            <Grid item xs={12} md={8} lg={8} container spacing={4}>
                <Grid item xs={12} md={6}>{get(2, "Select Your Plan")}</Grid>
                {/* <Grid xs={10} sm={6} md={6} container>
                        <Grid item xs={3}><span className={styles.label} style={{ margin: 0 }}>Currency:</span></Grid>
                        <Grid item xs={9}>
                            <Select
                                className={`${styles.currency} ${styles.marginBot}`}
                                placeholder="Currency"
                                value={currency.id}
                                onChange={handleCurrencyChange}
                                style={{ margin: 0 }}
                            >
                                {currencies.map(e => (
                                    <Select.Option value={e.id} label={e.label} key={e.id}>
                                        <div className="demo-option-label-item">{e.label}</div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Grid>
                    </Grid> */}
            </Grid>
        </Grid>
        <Grid item xs={12} style={{ fontSize: '0.7em' }}>
            {window.outerWidth < 600 &&
                <>
                    <p>Choose the plan that best suits your business needs</p>
                    <p>If you dont find a plan that you want, we are happy to hear from you. drop us a hello to sales@modcart.io</p>
                </>
            }
        </Grid>
        <Grid item container xs={12}>
            <Grid item xs={12} sm={4} md={2} lg={2} className={styles.cardFeatures}>
                <PricingCard />
            </Grid>
            {plans.map(p =>
                <Grid item xs={12} sm={4} md={2} lg={2} key={p.id}>
                    <PricingCard
                        card={p}
                        newUser={newUser}
                        currency={currency}
                        select={selectPrice}
                        expand={window.outerWidth < 600}
                        showText={window.outerWidth < 960}
                        selected={form.selectedPrice === p.id ? selectedPlanStyles : {}}
                        // selected={form?.selectedPrice === null ? (p.id === 'Start' ? selectedPlanStyles : {}) : (form?.selectedPrice === p.id ? selectedPlanStyles : {})}
                        current={form?.subscription?.userPlan == null ? (p.id === 'Start' ? currentPlanStyles : {}) : (form?.subscription?.userPlan === p.id ? currentPlanStyles : {})}
                        showTemplate={setShowTemplate}
                    />
                </Grid>
            )}
        </Grid>
    </Grid >

    const step3 = (notPaid) => <Grid container className={styles.step}>
        {/* <Grid item xs={12}>{step(3, "Payment details")}</Grid> */}
        <Grid item xs={12}>{get(3, "Payment details")}</Grid>
        <Grid item xs={12}>
            <div className={`${styles.selectedPlanText}`} >
                Your Selected Plan is <span>{selectedPlan.name}: {typeof selectedPlan.cost === 'number' ? `${numberToCurrency(selectedPlan.cost)}/mo` : selectedPlan.cost}</span>
            </div>
        </Grid>
        {/* <Grid item xs={12}><span classname={styles.addPaymentText} style={{ fontSize: '0.5em', fontWeight: '500' }}>ADD NEW PAYMENT METHOD</span></Grid> */}
        <Grid item container xs={12} className={styles.paymentContainer} alignItems='center'>
            <Grid item xs={12} container spacing={4}>
                <Grid item xs={12} md={6}>
                    <span className={styles.paymentSubHeading}>Name</span>
                    <input name='name' value={form2.name || ''} placeholder="Enter Your Name" className={styles.input} onChange={handleChange} />
                </Grid>
                {window.innerWidth > 960 &&
                    <Grid item md={6}></Grid>
                }
                <Grid item xs={12} md={6} style={{ paddingBlock: 0 }}>
                    <span className={styles.paymentSubHeading}>Email</span>
                    <input name='email' value={form2.email || ''} placeholder="Enter Your Email" className={styles.input} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingBlock: 0 }}>
                    <span className={styles.paymentSubHeading}>Phone</span>
                    <input name='phone' value={form2.phone || ''} placeholder="Enter Your Phone" className={styles.input} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <span className={styles.paymentSubHeading}>Address</span>
                    <input name='address1' value={form2.address1 || ''} placeholder="Address Line 1" className={styles.input} onChange={handleChange} />
                    <input name='address2' value={form2.address2 || ''} placeholder="Address Line 2" className={styles.input} onChange={handleChange} style={{ marginTop: '8px' }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <span className={styles.paymentSubHeading} style={{ color: 'white' }}>City</span>
                    <input name='city' value={form2.city || ''} placeholder="City" className={styles.input} onChange={handleChange} />
                    <Grid item xs={12} md={12} container style={{ marginTop: '8px' }}>
                        <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <input name='province' value={form2.province || ''} placeholder="Province" className={styles.input} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: '20px' }}>
                            <input name='postcode' value={form2.postcode || ''} placeholder="Post Code" className={styles.input} onChange={handleChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} container justifyContent='flex-end'>
                    <button className={`${styles.actionbutton} ${styles.allsetButton}`} style={{ margin: 0 }} onClick={handlePay}>SUBSCRIBE</button>
                </Grid>
                <Grid item xs={6} md={6}>
                    <span className={styles.note}>
                        {notPaid ? "* Complete your payment to activate the plan" : ""}
                    </span>
                </Grid>
            </Grid>
        </Grid>
    </Grid>

    return (
        <>
            <div className={styles.root}>
                <TemplateDialog open={showTemplate} handleClose={() => setShowTemplate(false)} themes={themes || []} />
                <Dialog open={sendEmail} onClose={closeDialog}>
                    <DialogTitle>Lets talk for Enterprise</DialogTitle>
                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ backgroundColor: "#44BCFF", color: 'white' }} onClick={sendMail}>SEND</Button>
                        <Button style={{ backgroundColor: '#E4E4E4' }} onClick={closeDialog}>CANCEL</Button>
                    </DialogActions>
                </Dialog>
                <Grid container className={styles.container}>
                    {newUser &&
                        <Grid item xs={11}>
                            <h1 className={styles.welcome}>
                                Welcome, {decoded?.email}!
                            </h1>
                        </Grid>
                    }
                    {newUser &&
                        <Grid item xs={12}>
                            {step1(newUser)}
                        </Grid>
                    }
                    <Grid item xs={12}>{step2()}</Grid>
                    {(
                        // form.selectedPrice !== "Enterprise" &&
                        form.selectedPrice &&
                        form.selectedPrice !== "Start" &&
                        (form.selectedPrice !== form?.subscription?.userPlan ||
                            form?.subscription?.status !== 1)) &&
                        <Grid item xs={12}>{step3(form?.subscription?.status !== 1 && form?.selectedPrice === form?.subscription?.userPlan)}</Grid>
                    }
                    <Grid item container xs={12} justifyContent='center'>
                        <Grid item xs={'auto'}>
                            {newUser && <button className={`${styles.actionbutton} ${styles.skipButton}`} onClick={onSubmit(false)}>SKIP</button>}
                            <button className={`${styles.actionbutton} ${styles.allsetButton}`} onClick={onSubmit(true)}>All SET</button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Pricing