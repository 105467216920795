import { Button, CircularProgress, Container } from "@material-ui/core";
import { Typography } from "antd";
import axios from "axios";
import React, { useEffect, useState, } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import verifiedImg from "../../assets/verified.svg";
import errorImg from "../../assets/email-error.svg";
import { useDispatch } from "react-redux";
import { logOut } from "redux/authentication/actionCreator";

const VerifyEmail = (props) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("userId");

  // console.log(id);

  //   const  id  = useParams()
  //   console.log(id, props)

  const updateUser = (id) => {
    var data = JSON.stringify({
      isVerified: "true",
    });

    var config = {
      method: "put",
      url: process.env.REACT_APP_BASE + "/auth/update/" + id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        dispatch(logOut());
      })
      .catch(function (error) {
        console.log(error);
        setIsError(true);
      });
  };

  useEffect(() => {
    updateUser(id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container style={{ textAlign: "center", marginTop: "10%" }}>
        {loading && !isError && <CircularProgress />}
        {!loading && !isError && (
          <>
            {" "}
            <Typography component="h1">Email Verified Successfully!</Typography>
            <img alt='' src={verifiedImg} style={{ width: "25%" }} /> <br />
            <Link to="/auth">
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "15px", backgroundColor: '#0077b6' }}
              >
                Login
              </Button>
            </Link>
          </>
        )}
        {isError && (
          <>
            {" "}
            <img alt='' src={errorImg} style={{ width: "25%" }} /> <br />
            {/* <Link to="/">
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "15px" }}
              >
                Login
              </Button>
            </Link> */}
            <br />
            <Typography component="h1">Email Verification Failed</Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default VerifyEmail;
