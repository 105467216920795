import { makeStyles } from "@material-ui/core";

export const useHeaderStyle = makeStyles(theme => ({
    main: {
        minHeight: '120px',
    }
}))

export const useSelectionStyle = makeStyles(theme => ({
    main: {
        // border: '1px solid gray',
        minHeight: '700px',
    },
    container: { height: '750px', overflowY: 'scroll', padding: '32px', paddingTop: '0px' },
    themeItems: { padding: '4px', marginBottom: '20px' },
    themeUnselected: { borderRadius: '8px', padding: '8px' },
    themeSelected: { border: '2px solid black' },
}))
export const usePreviewStyle = makeStyles(theme => ({
    main: {
        // border: '1px solid gray',
        minHeight: '700px',
    },
    button: {
        height: 40,
        fontSize: 16,
        minWidth: '200px',
        textTransform: "capitalize",
        [theme.breakpoints.down("sm")]: {
            minWidth: "100px",
        },
    },
    backButton: {
        color: "#ffffff",
        backgroundColor: "#0077B6",
    },
    nextButton: {
        color: "#ffffff",
        backgroundColor: "#0077B6",
    }
}))

export const useMobileStyle = makeStyles(theme => ({
    container: {
        // border: '1px solid black'
        height: '100%',
    },
    iphoneX: {
        height: '575px',
        width: '287px',
        border: '12px solid #fff',
        borderRadius: '44px',
        boxShadow: '0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%)',
        position: 'relative',
        zIndex: '0',
    }
}))