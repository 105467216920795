export const createHtmlTemplate = (data) => {
    // let template = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title><script src="https://unpkg.com/analytics/dist/analytics.min.js"></script> <script src="https://unpkg.com/@analytics/google-analytics/dist/@analytics/google-analytics.min.js"></script> <script type="text/javascript"> var Analytics = _analytics.init({ app: 'analytics-html-demo', debug: true, plugins: [ analyticsGa.default({ trackingId: 'UA-148936248-1' }) ] }) Analytics.page() Analytics.on('*', ({ payload }) => { console.log(payload) }); </script></head><body>${data}</body></html>`;
    let template = `${data.html} 
  ${data?.pixels?.length ? data?.pixels?.map(pixel => `<img height="1" width="1" style="display:none"
  src="${pixel}"
  />`).join(" ") : ''}
  <script>

  const url = 'https://analytics.modcart.io/api/analytics/update';

  const shoppable = '${data?.adId}'

  function postData(data) {

    fetch(url, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
        console.log('Success:', data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });

    }

    window.onload = function() {
        const data = {shoppable};
        postData(data);
    };

    var url1 = document.getElementById('one');
    var url11 = document.getElementById('one1');
    var url12 = document.getElementById('one2');
    var url2 = document.getElementById('two');
    var url3 = document.getElementById('three');
    var url31 = document.getElementById('three1');

    var dataone = url1.getAttribute('data-site-type'); 
    var datatwo = url2.getAttribute('data-site-type'); 
    var datathree = url3.getAttribute('data-site-type'); 


    if(url1 !== null){
        url1.onclick = function() {
            console.log(dataone);
            const data = {site: dataone, shoppable};
            postData(data);
        };
    }
    if(url11 !== null){
        url11.onclick = function() {
            console.log(dataone);
            const data = {site: dataone, shoppable};
            postData(data);
        };
    }
        
    if(url12 !== null){
        url12.onclick = function() {
            console.log(dataone);
            const data = {site: dataone, shoppable};
            postData(data);
        };
    }

    if(url2 !== null){
        url2.onclick = function() {
            console.log(datatwo);
            const data = {site: datatwo, shoppable};
            postData(data);
        };
    }

    if(url3 !== null){
        url3.onclick = function() {
            console.log(datathree);
            const data = {site: datathree, shoppable};
            postData(data);
        };
    }
    if(url31 !== null){
        url31.onclick = function() {
            console.log(datathree);
            const data = {site: datathree, shoppable};
            postData(data);

        };
    }
</script>`;

    return template;
};

export const createJsTemplate = (data) => {
    return `document.write(\`${data}\`)`;
};

export const eskimiScriptTemplate = (data, url1, url2, url3) => {
    return `<ins style="text-decoration: none" class="adcads" data-clicktag-1="${url1}" data-clicktag-4="${url2}" data-clicktag-cta="${url3}" data-has-desktop="yes" data-adc-id="vtjBu28sxzLA" data-unit="vtjBu28sxzLA" data-clickable="yes" data-redir="no" data-click-tracker="{click_url}" data-click-macro="{click_url}" data-responsive="yes" data-width="300" data-height="250" data-onevent-VTR-Video_1-0="{VIDEO_EVENT_URL}start" data-onevent-VTR-Video_1-25="{VIDEO_EVENT_URL}firstQuartile" data-onevent-VTR-Video_1-50="{VIDEO_EVENT_URL}midpoint" data-onevent-VTR-Video_1-75="{VIDEO_EVENT_URL}thirdQuartile" data-onevent-VTR-Video_1-100="{VIDEO_EVENT_URL}complete" data-onevent-VTR-Video_1-Mute="{VIDEO_EVENT_URL}mute" data-onevent-VTR-Video_1-Unmute="{VIDEO_EVENT_URL}unmute" data-onevent-VTR-Video_1-Pause="{VIDEO_EVENT_URL}pause" data-onevent-VTR-Video_1-Play="{VIDEO_EVENT_URL}resume" data-onevent-engaged-start="{EVENT_URL}started" data-onevent-engaged-finish="{EVENT_URL}finished" > <script src="${data}" type="text/javascript" ></script> </ins>`;
};
