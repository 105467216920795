import axios from 'axios';
import { base } from "redux/form/actionCreator";
import { LOADING, LOAD_MORE_DATA, SET_SHOPPABLE_DATA } from './action.types';

export const getShoppableListNew = (page, setLoading) => async dispatch => {
    try {
        dispatch({ type: LOADING, payload: true })
        // const userId = localStorage.getItem('user')
        console.log("IN REDUX")
        console.log(`${base}/shoppable/?limit=5&page=${page}&user=${localStorage.getItem("user")}&deleted=false}`)
        //  const { data: { result } } = await axios.get(`${base}/shoppable/?limit=5&page=${page}&user=${localStorage.getItem("user")}&deleted=false}`)
        //  dispatch({type: LOADING, payload: false})
        //  return dispatch({ type: SET_SHOPPABLE_DATA, payload: result })
        axios
            .get(
                `${process.env.REACT_APP_BASE}/shoppable/?limit=5&page=${page}&user=${localStorage.getItem("user")}&deleted=false`
            )
            .then((response) => {
                // data=response;
                console.log(response.data)
                dispatch({ type: LOADING, payload: false });
                return dispatch({ type: SET_SHOPPABLE_DATA, payload: response.data })
            })
            .finally(() => setLoading(() => false))
    }
    catch (error) {
        if (error && error.response && error.response.data) return console.log(error.response.data);
        console.log(error.message)
        dispatch({ type: LOADING, payload: false })
    }
}

export const loadMoreData = (page) => async dispatch => {
    console.log("observed it", page)
    try {
        dispatch({ type: LOADING, payload: true })
        // const userId = localStorage.getItem('user')
        console.log("IN REDUX")
        console.log(`${base}/shoppable/?limit=5&page=${page}&user=${localStorage.getItem("user")}&deleted=false}`)
        //  const { data: { result } } = await axios.get(`${base}/shoppable/?limit=5&page=${page}&user=${localStorage.getItem("user")}&deleted=false}`)
        //  dispatch({type: LOADING, payload: false})
        //  return dispatch({ type: SET_SHOPPABLE_DATA, payload: result })
        axios
            .get(
                `${process.env.REACT_APP_BASE}/shoppable/?limit=5&page=${page}&user=${localStorage.getItem("user")}&deleted=false`
            )
            .then((response) => {
                // data=response;
                console.log(response)
                dispatch({ type: LOADING, payload: false });
                return dispatch({ type: LOAD_MORE_DATA, payload: response.data })
            })
    }
    catch (error) {
        if (error && error.response && error.response.data) return console.log(error.response.data);
        console.log(error.message)
        dispatch({ type: LOADING, payload: false })
    }
}
