import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { IconButton } from "@material-ui/core";
import classes from "./SellFlow.module.css";
import { useDispatch } from "react-redux";
import { openSnackbar } from "redux/snackbarStore";
import { Delete } from "@material-ui/icons";
import { srcToCDN } from "utility/utility";

const FileUpload = (props) => {
  const dispatch = useDispatch();
  const [toUpload, setToUpload] = useState([]);
  const [files, setFiles] = useState([]);

  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "image/*",
    multiple: 1,
    maxFiles: 1,
    onDragOver: (e) => {
      e.preventDefault();
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        dispatch(
          openSnackbar(
            "Invalid File Format or Multiple Files uploaded!",
            "error"
          )
        );
      }
      let tempToUpload = [...toUpload, ...acceptedFiles];
      setToUpload(tempToUpload);
      props.handleUpload(tempToUpload);
      let modifiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      let tempFile = [...files, ...modifiles];
      setFiles(tempFile);
    },
  });

  const images = files.map((file, index) => (
    <>
      <img
        src={file && file.preview ? file.preview : file}
        className="img-thumbnail my-2"
        style={{
          margin: "0 20px 20px 0",
          objectFit: "contain",
          border: '1px solid gray !important',
        }}
        height={153}
        width={140}
        alt="preview"
      />
      {/* <IconButton color='primary' style={{ height: 'max-content' }} onClick={() => {
        setToUpload([])
        props.handleDeleteImage();
      }}>
        <Delete />
      </IconButton> */}
    </>
  ));

  return (
    <div>
      {/* <div
        style={{
          padding: "2px",
          fontWeight: "bold",
          textAlign: "left",
          paddingBottom: "10px",
        }}
      >
        {props.title}
      </div> */}
      <div className="d-flex">
        <div elevation={0} style={{ display: "flex", alignItems: "center", }}>
          {images && images.length > 0 ? (
            images
          ) : props.existing ? (
            <div
              elevation={0}
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={srcToCDN(props.existing)}
                className="img-thumbnail my-2"
                style={{
                  margin: "0 30px 20px 0",
                  backgroundColor: 'white',
                  border: '1px solid gray !important',
                  objectFit: "contain",
                  maxHeight: '132px'
                }}
                alt="preview"
              />
              <IconButton
                color="#ff0000"
                style={{
                  height: "max-content",
                  alignSelf: "start",
                  marginLeft: "-17%",
                  marginTop: "-8%",
                }}
                onClick={props.handleDeleteImage}
              >
                <Delete />
              </IconButton>
            </div>
          ) : null}
        </div>
        {toUpload.length < 1 && !props.existing && (
          <div
            className="py-2"
            style={{
              width: "max-content",
              height: "max-content",
              display: "inline-block",
              cursor: "pointer",
            }}
            {...getRootProps()}
          >
            {
              <>
                <input {...getInputProps()} />
                <div className={classes.toUploadImages}>Upload Your Business Logo</div>
              </>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
