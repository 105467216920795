// import Cookies from "js-cookie";
import axios from "axios";
import actions from "./actions";
import {
  DataService,
  // DataService1
} from "../../config/dataService/dataService.js";
const {
  Upload,
  UploadImage,
  UploadLogo,
  UploadBrandLogo,
  SelectTemplate,
  UpdateForm,
  UpdateDetail,
  ClearForm,
  GetList,
  GetMarketData
} = actions;
export const base = process.env.REACT_APP_BASE
// export const base = 'http://localhost:5000'
const uploadImage = (formData, type) => {
  return async dispatch => {
    try {
      // console.log(formData, type);
      const result = await DataService.post(
        process.env.REACT_APP_BASE + "/s3/upload",
        formData
      );
      //const result = await DataService.post("http://localhost:5000/s3/upload", formData)
      // console.log("image", result);
      if (result.data.success) {
        switch (type) {
          case "subImage":
            dispatch(UploadImage(result.data.url));
            break;
          case "subLogo":
            dispatch(UploadLogo(result.data.url));
            break;
          case "brandLogo":
            dispatch(UploadBrandLogo(result.data.url));
            break;
          case "mainImage":
            dispatch(Upload(result.data.url));
            break;
          default: break;
        }
      }
    } catch (err) {
      console.log(err);
      // dispatch(loginErr(err));
    }
  };
};
export const updateAd = (formdata, statedata, form, setLoading, history) => async dispatch => {
  try {
    // console.log('action')
    if (setLoading) setLoading(true)
    const localdata = JSON.parse(localStorage.getItem('ad'))
    await axios.post(`${base}/ad/update`, { ...formdata, _id: localdata._id })
    // console.log(data)
    if (statedata) localStorage.setItem('ad', JSON.stringify(statedata))
    // console.log(form)
    if (setLoading) setLoading(false)
    // console.log(history)
    if (form) form.resetFields();
    if (history) history.push("/admin/preview");
  } catch (error) {
    console.log(error.message)
    if (error && error.response) console.log(error.response)
  }
}
const submitForm = (formData, statedata, form, history) => async disptach => {
  try {
    // console.log(statedata)
    const user = localStorage.getItem('user')
    const data = { ...formData, createdBy: user, modifiedBy: user }
    const result = await DataService.post(process.env.REACT_APP_BASE + "/ad/create", data);
    console.log("form data", result.data);
    localStorage.setItem('ad', JSON.stringify({ ...statedata, _id: result.data._id }))
    if (form) form.resetFields();
    if (history) history.push("/admin/preview");
  } catch (error) {
    console.log(error.message)
  }
}

export const clearImage = () => dispatch => dispatch({ type: 'CLEAR_IMAGE' })

const updateForm = formData => {
  return async dispatch => {
    dispatch(UpdateForm(formData));
  };
};

const updateDetails = val => {
  return async dispatch => {
    dispatch(UpdateDetail(val));
  };
};

const selectTemplate = template => {
  return async dispatch => {
    dispatch(SelectTemplate(template));
  };
};

const clearForm = () => {
  return async dispatch => {
    dispatch(ClearForm());
  };
};

const getList = () => {
  return async dispatch => {
    const result = await DataService.post(
      process.env.REACT_APP_BASE + "/ad/list"
      // 'http://localhost:5000/ad/create'
    );
    //const result = await DataService.post("http://localhost:5000/ad/list")
    // console.log("list", result);
    if (result.data.length > 0) {
      dispatch(GetList(result.data));
    }
  };
};

const getAddata = data => {
  return async dispatch => {
    //const res = await DataService.post('http://localhost:5000/ad/addetails',data);
    try {
      console.log(data)
      const res = await DataService.post(process.env.REACT_APP_BASE + "/ad/addetails", data);
      console.log(res.data)
      const marketData = JSON.parse(res.data);
      if (res.data.length > 0) {
        dispatch(GetMarketData(marketData[0]));
      }
    } catch (error) {
      console.log(error.message)
    }
  };
};

export {
  uploadImage,
  submitForm,
  selectTemplate,
  updateForm,
  updateDetails,
  clearForm,
  getList,
  getAddata
};
