import { FETCH_REPORTS } from "./actions";

const initialState = {
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTS: {
        const reports = action.payload;
      return { ...state, reports };
    }
    
    default:
      return state;
  }
};

export default reportsReducer;
