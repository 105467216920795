import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthLayout from "../container/profile/authentication/Index";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
// import { useLocation } from 'react-router-dom';

const Login = lazy(() =>
  import("../container/profile/authentication/overview/Login")
);
const SignUp = lazy(() =>
  import("../container/profile/authentication/overview/Register")
);
const ForgotPassword = lazy(() =>
  import("../container/profile/authentication/overview/ForgotPassword")
);

const NotFound = () => {
  return <Redirect to="/auth" />;
};

const FrontendRoutes = () => {

  // const location = useLocation();

  return (

    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <BrowserRouter>
        <Switch>
          <Route exact path="/auth" component={Login} />
          <Route exact path="/auth/signup" component={SignUp} />
          <Route exact path="/auth/forgot" component={ForgotPassword} />
          <Route exact path="/*" component={NotFound} />
        </Switch>
      </BrowserRouter >
    </Suspense>

  );
};

export default AuthLayout(FrontendRoutes);
