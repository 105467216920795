import React, { useEffect } from "react";
// import slide1 from "../../../assets/slide1.png";
// import slide2 from "../../../assets/slide2.png";
// import slide3 from "../../../assets/slide3.png";
// import slide4 from "../../../assets/slide4.png";

import { Grid } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import bg2 from "assets/authPageBG/BlueBG.jpg";
import bg1 from "assets/authPageBG/GreenBG.jpg";
import bg3 from "assets/authPageBG/PinkBG.jpg";
import bg4 from "assets/authPageBG/PurpleBG.jpg";

const AuthLayout = (WraperContent) => {
  // const carouselImg = [
  //   { title: "Image1", url: slide1 },
  //   { title: "Image2", url: slide2 },
  //   // {title: "Image3", url: slide3},
  //   { title: "Image4", url: slide4 },
  // ];
  const bgImg = [
    { title: "GreenBG", url: bg1, bgColor: "#D0E47D" },
    { title: "BlueBG", url: bg2, bgColor: "#37EBF6" },
    { title: "PinkBG", url: bg3, bgColor: "#FF5CAA" },
    { title: "PurpleBG", url: bg4, bgColor: "#A874FF" },
  ];

  const WrapperComponent = () => {
    // const [src, setSrc] = useState(slide1);
    const [random, setRandom] = React.useState(3);

    const max = 3;
    const min = 0;

    const generateRandomInt = (max, min) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    useEffect(() => {
      setRandom(generateRandomInt(max, min));
    }, []);

    // const changeSlide = () => {
    //   var indx = 0;
    //   setInterval(() => {
    //     if (indx >= carouselImg.length - 1) indx = -1;
    //     indx = indx + 1;
    //     // setSrc(carouselImg[indx].url);
    //     // console.log(carouselImg[indx].time)
    //   }, 5000);
    // };

    // const [isAnimation, setIsAnimation] = useState(false);

    // useEffect(() => {
    //   if (!isAnimation) {
    //     changeSlide();
    //     TypingAnimation();
    //   }
    //   // eslint-disable-next-line
    // }, []);

    // const TypingAnimation = () => {
    //   const words = [
    //     { text: "Insights", color: "#f25a56" },
    //     { text: "Shoppable Ads", color: "#ae6b75" },
    //     { text: "One Page Store", color: "#134589" },
    //   ];
    //   let i = 0;
    //   let timer;

    //   const typingEffect = () => {
    //     try {
    //       let word = words[i].text.split("");
    //       var loopTyping = function () {
    //         if (word.length > 0) {
    //           if (document.getElementById("word")) {
    //             document.getElementById("word").innerHTML += word.shift();
    //           }
    //           document.getElementById("word").style.color = words[i].color;
    //         } else {
    //           setTimeout(deletingEffect, 2000);

    //           return false;
    //         }
    //         timer = setTimeout(loopTyping, 2000 / words[i].text.length);
    //       };
    //       loopTyping();
    //     } catch (error) { }
    //   };

    //   const deletingEffect = () => {
    //     let word = words[i].text.split("");
    //     var loopDeleting = function () {
    //       if (word.length > 0) {
    //         word.pop();
    //         if (document.getElementById("word"))
    //           document.getElementById("word").innerHTML = word.join("");
    //       } else {
    //         if (words.length > i + 1) {
    //           i++;
    //         } else {
    //           i = 0;
    //         }
    //         typingEffect();
    //         return false;
    //       }
    //       timer = setTimeout(loopDeleting, 1000 / words[i].text.length);
    //     };
    //     loopDeleting();
    //   };
    //   typingEffect();
    //   setIsAnimation(true);
    // };

    return (
      <>
        <Grid container style={{ height: "100vh", fontFamily: "Roboto !important" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            style={{
              // maxHeight: "100%",
              // height: "50%",
              backgroundColor: `${bgImg[random]?.bgColor}`,
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <img
              src={bgImg[random]?.url}
              alt="bg"
              style={{
                height: '100%',
                maxWidth: "100%",
                maxHeight: "100vh",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            style={{
              maxHeight: "100%",
              // height: "auto",
              height: '100%',
              backgroundColor: "#fff",
            }}
            container
            justifyContent="center"
          >
            <Grid item lg={8} md={10} sm={7} xs={10} style={{ marginBlock: '2em' }}>
              <WraperContent />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  return WrapperComponent
};

export default AuthLayout;


/* <Row className={classes.screen}>
      <Col
        xxl={14}
        xl={14}
        lg={14}
        md={8}
        xs={24}
        style={{ maxHeight: "100vh" }}
      >
        <div className={classes.title}>
          <h1
            style={{
              fontSize: "3.5rem",
              color: "#fff",
              fontWeight: "700",
              lineHeight: "1.2",
              marginBottom: 0,
            }}
          >
            Create & <br />
            Publish Your
          </h1>
          <div class="flex">
            <p class="header-sub-title" id="word"></p>
            <p class="header-sub-title blink" style={{ color: "#fff" }}>
              |
            </p>
          </div>
          <p style={{ color: "white" }} className={classes.desktopOnly}>
            Access our userfriendly features to publish your own custom
            <br /> Adds and stores to increase your product’s reach, setup
            your
            <br /> free account with Modcart.
          </p>
        </div>
        <img className={classes.backgroundImg} src={src} />
        {/* <video className={classes.backgroundImg} autoPlay muted loop>
          <source src={homeVid} type="video/mp4"></source>
        </video> */
//   </Col>

//   <Col className={classes.form} xxl={10} xl={10} lg={10} md={16} xs={24}>
//     <WraperContent />
//   </Col>
// </Row> */