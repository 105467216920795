import { combineReducers } from "redux";
import { readMessageReducer } from "./message/reducers";
import { readNotificationReducer } from "./notification/reducers";
import authReducer from "./authentication/reducers";
import chartContentReducer from "./chartContent/reducers";
import ChangeLayoutMode from "./themeLayout/reducers";
import { userReducer } from "./users/reducers";
import { headerSearchReducer } from "./headerSearch/reducers";
import FormReducer from "./form/reducers";
import langReducer from "./lang/lang.reducer";
import catalogueReducer from "./catalogue/reducer";
import onePageStoreReducer from "./onPageStore/reducer";
import { ProductData, SelectedProduct } from "./CreateStore/reducer";
import publishedReducer from "./published/reducer";
import themeReducer from "./theme/reducer";
import SnackBarReducer from "./Snackbar/SnackBarReducer";
import paymentReducer from "./payment/reducer";
import shoppableReducer from "./shoppableList/reducer";
import reportsReducer from "./reports/reports.reducer";

const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  ProductData: ProductData,
  SelectedProduct: SelectedProduct,
  notification: readNotificationReducer,
  chartContent: chartContentReducer,
  snackbar: SnackBarReducer,
  user: userReducer,
  auth: authReducer,
  form: FormReducer,
  lang: langReducer,
  catalogue: catalogueReducer,
  onePageStore: onePageStoreReducer,
  shoppableListData: shoppableReducer,
  published: publishedReducer,
  payment: paymentReducer,
  theme: themeReducer,
  ChangeLayoutMode,
  reports: reportsReducer

});

export default rootReducers;
