import Cookies from "js-cookie";
import actions from "./actions";
// import { axios } from "../../config/axios/axios.js";
import axios from "axios";
import { openSnackbar } from "redux/snackbarStore";
import languageData from "utility/lang.json";
// import { useSelector } from "react-redux";
import { setLang } from "redux/lang/action";

const {
  registerBegin,
  registerSuccess,
  registerErr,
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  setStoreBegin,
  loadUser,
} = actions;

const setFirstLanguage = (dispatch) => {
  const jsonData = JSON.stringify(languageData["en"]);
  dispatch(setLang(jsonData, "Indonesia"));
};

const register = (formData, history) => {
  return async (dispatch) => {
    try {
      dispatch(registerBegin());
      //const result = await axios.post("http://localhost:5000/user/signin", formData)
      const { data } = await axios.post(
        process.env.REACT_APP_BASE + "/auth/signup",
        formData
      );
      if (data.userId) {
        setFirstLanguage(dispatch);
        Cookies.set("logedIn", true);
        localStorage.setItem("access_token", data.token);
        localStorage.setItem("user", data.userId);
        // Cookies.set('store', true);
        sessionStorage.setItem("NEW_USER", "true");
        dispatch(registerSuccess(true));
        dispatch(loadUser(data));
        // dispatch(loginSuccess(true));
      }
    } catch (err) {
      console.log(err);
      dispatch(
        openSnackbar(
          err.response
            ? err.response.data.response
            : "Error! Please try again.",
          "error"
        )
      );
      dispatch(registerErr(err));
    }
  };
};

const login = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      //const result = await axios.post("http://localhost:5000/user/signin", formData)
      const { data } = await axios.post(
        process.env.REACT_APP_BASE + "/auth/login",
        formData
      );
      if (data.userId) {
        setFirstLanguage(dispatch);

        Cookies.set("logedIn", true);
        localStorage.setItem("access_token", data.token);
        localStorage.setItem("user", data.userId);
        // Cookies.set('store', true);
        dispatch(loginSuccess(true));
        dispatch(loadUser(data));
      }
    } catch (err) {
      console.log(err);
      if (err)
        dispatch(
          openSnackbar(
            err.response
              ? err.response.data.response.message
              : "Error! Please try again.",
            "error"
          )
        );
      dispatch(loginErr(err));
    }
  };
};

const googleLogin = (data) => {
  return (dispatch) => {
    setFirstLanguage(dispatch);

    Cookies.set("logedIn", true);
    localStorage.setItem("access_token", data.token);
    localStorage.setItem("user", data.userId);
    // Cookies.set('store', true);
    dispatch(loginSuccess(true));
    dispatch(loadUser(data));
  };
};

const facebooklogin = (data) => {
  return (dispatch) => {
    setFirstLanguage(dispatch);

    Cookies.set("logedIn", true);
    localStorage.setItem("access_token", data.token);
    localStorage.setItem("user", data.userId);
    // Cookies.set('store', true);
    dispatch(loginSuccess(true));
    dispatch(loadUser(data));
  };
};

const setStore = (id) => {
  return async (dispatch) => {
    try {
      // Cookies.set('store', false);
      dispatch(setStoreBegin(id));
    } catch (error) { }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove("logedIn");
      // Cookies.remove('store');
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { register, login, logOut, setStore, googleLogin, facebooklogin };

