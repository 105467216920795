import axios from "axios";
import { base } from "redux/form/actionCreator";
import {
  SELECT_THEME,
  SELECT_THEME_VARIANT,
  SET_ALL_THEME,
} from "./action.types";
import jwt_decode from "jwt-decode";
import { isAccessablie } from "utility/pricingplan";

export const getAllTheme = () => async (dispatch) => {
  try {
    let { data: { result } } = await axios.get(`${base}/themes/all`);

    const token = localStorage.getItem("access_token");
    if (token) var decoded = jwt_decode(token);

    const id = decoded.userId || localStorage.getItem("user")
    // console.log("<== decoded", decoded)
    const user = await axios.get(`${base}/auth/${id}`)

    const planId = user.data?.businessDetails?.subscription?.userPlan || "Start"

    const inPlan2 = item => { return isAccessablie(planId, item.plan, user?.businessDetails?.subscription?.status) }
    // this is handled in the CreateStore component
    // const inPlan = item => { return true }

    const filterItem = item => {
      if (!item.accessMan) return item;
      if (!token) return
      item.imageUrl = item.imageUrl.filter(i => i.access.includes(decoded?.email))
      if (!item.imageUrl.length) return
      return item;
    }

    result = result?.map(filterItem)?.filter(i => i).reverse()
    dispatch({ type: SET_ALL_THEME, payload: result });
    const theme = result.map(filterItem).findIndex(inPlan2)
    selectTheme(theme, result[theme].version)(dispatch)
    return
  } catch (error) {
    if (error && error.response && error.response.data)
      return console.log(error.response.data);
    console.log(error.message);
  }
};

export const selectTheme = (theme, version) => (dispatch) => {
  dispatch({ type: SELECT_THEME, payload: theme, version });
};

export const selectVariant = (variant) => (dispatch) =>
  dispatch({ type: SELECT_THEME_VARIANT, payload: variant });
