/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

export { ellipsis };

/**
 * 
 * @param {String} url 
 * @returns 
 */
export const srcToCDN = url => url
// typeof url === 'string'
//   ? url?.replace(
//     "https://modcart-public.s3.us-east-2.amazonaws.com/",
//     "https://cdn.modcart.io/public-modcart/")
//   : url

export function getNumberWithOrdinal(n) {
  let s = ["th", "st", "nd", "rd"], v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const currencyToNumber = c => +(("" + c).replace(/\./g, '').replace(/,/g, "."))
export const numberToCurrency = n => {
  let val = ("" + n).split(".")
  val[0] = val[0].split("").reverse().map((v, i) => (i && i % 3 === 0) ? `${v}.` : v).reverse().join("")
  return `Rp ${val[0]},${val[1] || "00"}`
}
export const numberToCurrency2 = n => {
  let val = ("" + n).split(".")
  val[0] = val[0].split("").reverse().map((v, i) => (i && i % 3 === 0) ? `${v}.` : v).reverse().join("")
  return `${val[0]},${val[1] || "00"}`
}
