import React, { useState } from 'react'
import { numberToCurrency2 } from '../../utility/utility'
import contractIcon from './assets/contract.png'
import expandIcon from './assets/expand.png'
// import { monthlyCost } from './data'
import styles from './pricing.module.css'

const PricingCard = ({ className, card = {}, newUser, showTemplate, currency, select, selected = {}, expand, showText, current }) => {

    const [expanded, setExpanded] = useState(false)

    const block = (content, name, last) => <div className={`${styles.block} ${last ? "" : styles.division}`} style={{ borderColor: card.rootStyles?.color || "#969696" }}>
        {showText ? `${name} ${content}` : content || <b><i>{name}</i></b>}
    </div>

    const check = () => <span className={`${styles.template}`} onClick={() => showTemplate(card.level)}>Check</span>

    return (
        <div className={`${styles.card} ${className}`} style={{ ...card.rootStyles, ...selected.rootStyles, cursor: `${select ? 'pointer' : 'unset'}` }} onClick={select?.(card.id)}>
            <div className={styles.name}>
                {card.name || <i>Features</i>}
            </div>

            <div className={`${styles.pricing}`}>

                {
                    // eslint-disable-next-line
                    card.cost != undefined
                        ?
                        <span className={`${styles.cost}`}>
                            {card.id !== 'Enterprise' && card.id !== 'Start' ?
                                `Rp ${typeof card.cost === 'number' ? numberToCurrency2(card.cost) : card.cost}/mo`
                                :
                                card.cost
                            }
                            {/* {numberToCurrency(typeof card.cost === 'number' ? monthlyCost(card.cost, currency) : card.cost)} */}
                        </span>
                        :
                        <span className={` ${styles.small}`} style={{ color: '#686868' }}>
                            Choose the plan that best suits your business needs
                        </span>
                }
            </div>

            <div className={`${styles.desc}`}>
                {
                    // eslint-disable-next-line
                    card.desc != undefined
                        ?
                        <>
                            <span>{card.desc}</span>
                            <button className={`${styles.actionbutton}`} style={{ ...card.buttonStyles, ...selected.buttonStyles, ...current.buttonStyles, margin: 0 }}>{current.button || selected.button || (newUser ? card.button : card.buttonUp)}</button>
                        </>
                        :
                        <span className={`${styles.small}`} style={{ color: '#686868' }}>
                            If you dont find a plan that you want, we are happy to hear from you. drop us a hello to sales@modcart.io
                        </span>
                }
            </div>

            {expand && <div className={styles.division} style={{ width: '80%', borderColor: card.rootStyles?.color || "#969696" }} />}

            {block(card.shopAdsPerMonth, "Shoppable Ads:")}
            {block(card.opsPerMonth, "One Page Stores:", expand)}

            {expand && expanded && <div className={styles.division} style={{ width: '80%', borderColor: card.rootStyles?.color || "#969696" }} />}

            {expand && expanded === false ?
                <>
                </>
                :
                <>
                    {block(card.visitsPerMonth, "Visits:")}
                    {block(card.level ? check() : undefined, "Templates:")}
                    {block(card.reports, "Reports:")}
                    {block(card.payments, "Online Transaction Payments:")}
                    {block(card.chats, "Single Interface for all Chats:")}
                    {block(card.insights, "Monthly Insights:", true)}
                </>
            }

            {expand && <div className={styles.actionIcon}>
                {expanded ?
                    <img alt='' src={contractIcon} onClick={() => setExpanded(false)} />
                    :
                    <img alt='' src={expandIcon} onClick={() => setExpanded(true)} />
                }
            </div>}

        </div >
    )
}

export default PricingCard