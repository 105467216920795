import axios from "axios";
import { LOAD_THE_USER } from "./actions";
import { base } from "redux/form/actionCreator";

export const loadUser = () => async dispatch => {
    try {
        const id = localStorage.getItem('user')
        // console.log("<== redux user", id)
        const user = await axios.get(`${base}/auth/${id}`)
        dispatch({ type: LOAD_THE_USER, payload: user.data })
    } catch (e) {
    }
};
