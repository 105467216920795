import { makeStyles } from '@material-ui/core';
const useStyle = makeStyles(theme => ({
    bodyMargin: {
        // margin: '25px 0'
    },
    storeItem: {
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 3px',
            color: '#676767',
        },
        padding: 12,
        borderRadius: 0,
        border: '1px solid #80808045',
        minHeight: '133px',
        // paddingTop:0,
        //     paddingBottom:0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            borderBottom: '0px',
            paddingBottom: 0
        }
    },
    topMargin: {
        marginTop: '0px'
    },
    flex: {
        display: 'flex'
    },
    shareButton: {
        marginLeft: 8,
    },
    alignItem: {
        alignItems: 'center'
    },
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    containerSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    innerWidth: {
        width: '100%'
    },
    mainContainer: {
        width: '80%',
        maxWidth: '340px'
    },
    productImg: {
        margin: 'auto',
        height: '100%',
        maxWidth: '90%',
        maxHeight: '100%',
        objectfit: 'contain',
    },
    whiteBackground: {
        backgroundColor: '#fff',
        marginBottom: '40px',
        paddingBottom: 12
    },
    fontSize: {
        fontSize: '22px'
    },
    fontSizeSucess: {
        fontSize: 18
    },
    topFontColor: {
        color: '#59C3FF',
    },
    bottomFontColor: {
        color: '#6F6F6F',
        fontFamily: 'Quicksand'
    },
    buttonColor: {
        backgroundColor: '#2730F8',
        color: '#fff',
        width: '80%',
        minWidth: '170px',
        marginTop: '20px',
        '&:hover': {
            backgroundColor: '#2730F8',
            opacity: '0.8'
        }
    },
    buttonTop: {
        backgroundColor: '#2730F8',
        fontSize: '10px',
        padding: 'auto 20px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2730F8',
            opacity: '0.8'
        }
    },
    whiteBox: {
        background: 'white',
        padding: 24,
        borderRadius: 5
    },
    headingFont: {
        fontFamily: 'Quicksand',
        fontWeight: '600',
        fontSize: '22px'
    },
    storeHomeConmtainer: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '94%'
        },
    },
    whiteBackgroundStore: {
        backgroundColor: '#fff'
    },
    storeTitle: {
        fontFamily: 'Quicksand',
        fontWeight: '600',
        marginLeft: '10px',
        // textDecoration: 'underline'
    },
    storeIcons: {
        width: 30,
        height: 30,
        marginRight: 12
    },
    roundIcon: {
        // border: '2px solid #272B41',
        borderRadius: '50%',
        padding: '3px',
        width: '25px',
        height: 'auto',
        backgroundColor: '#E1E1E1'
    },
    deleteHover: {
        '&:hover': {
            color: 'red',
            border: '2px solid red'
        }
    },
    marginLeft: {
        marginLeft: '15px'
    },
    backToStore: {
        width: '100%',
        position: 'relative',
        top: '33px',
        marginLeft: '10px',
        color: '#aaa'
    },
    fontSucessBottom: {
        marginTop: '20px',
        fontFamily: 'Quicksand',
        fontSize: '15px',
        color: '#EBC338'
    },
    copyShare: {
        marginTop: '20px',
        marginBottom: '40px',
        justifyContent: 'space-around',
        color: '#28B1EC',
        width: '100%',
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '18px',
        letterSpacing: '0em',
        textAlign: 'left',
    },
    share: {
        paddingBottom: '3px',
        borderBottom: '2px solid #28B1EC'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '40%',
        minWidth: 250,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        },
        paddingBottom: 40
    },
    // preview: {
    //     border: '20px solid black',
    //     borderRadius: 40,
    //     width: 240,
    //     margin: 'auto',
    //     marginTop: 12,
    //     overflow: 'hidden',
    //     position: 'relative'
    // },
    // previewCut: {
    //     borderRadius: '0px 0px 12px 12px',
    //     width: 100,
    //     margin: 'auto',
    //     height: 25,
    //     background: 'black',
    //     position: 'absolute',
    //     left: 50,
    //     top: 0,
    //     zIndex: 5
    // },
    preview: {
        border: "5px solid black",
        borderRadius: 20,
        width: 270,
        margin: "auto",
        marginTop: 12,
        overflow: "hidden",
        position: 'relative',
        paddingBottom: 12,
        paddingTop: 12,
        background: 'black'
    },
    previewCut: {
        borderRadius: "0px 0px 12px 12px",
        width: 100,
        margin: "auto",
        height: 25,
        background: "black",
        position: "absolute",
        left: "27%",
        zIndex: 5,
    },
    shoppableAdsTop: {
        display: "flex",
        justifyContent: "space-between",
        width: "90%",
        margin: "auto",
        background: 'transparent',
        paddingLeft: 0,
        paddingRight: 0,
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center"


        }
    },
    shoppableAdsHeader: {
        fontWeight: 'lighter', fontSize: 32, color: '#676767', fontFamily: 'Roboto Light',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            marginBottom: '12px !important'
        }
    },
    shoppableAdsSearchContainer: {
        width: "90%", margin: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "100% !important"
        }
    },
    shoppableAdsBox: {
        display: "flex",
        flexDirection: "column",
        background: "transparent",
        justifyContent: "center",
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            height: 60
        }
    },
    adTitle: {
        fontWeight: '500',
        color: '#676767',
        fontFamily: 'Roboto Light',
        [theme.breakpoints.down('sm')]: {

            textOverflow: "ellipsis",
            maxWidth: "170px",
            overflow: "hidden",
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            paddingLeft: '10%',
            paddingTop: '10%',
            justifyContent: 'center'
        }
    },
    adTitleDetail: {
        fontFamily: 'Roboto Light',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '5px'
        }
    },
    rowItemFont: {
        fontSize: 12,
        color: "#7D7D7D",
        display: "flex",
        flexDirection: "row",
        fontFamily: 'Roboto Light',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
            flexDirection: 'column',
            alignContent: "flex-start",
            justifyContent: "flex-start",
            fontWeight: 600
        }
    },
    matrixGridITem: {
        [theme.breakpoints.down('sm')]: {
            padding: '5px'
        }
    },
    rowItemFontContent: {
        fontSize: 12,
        color: "#0077B6",
        display: "flex",
        flexDirection: "row",
        fontWeight: '500',
        fontStyle: 'normal',
        fontFamily: 'Roboto Light',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: "center",
            fontSize: 11,
            justifyContent: "center"
        }
    },
    rowItemFont1: {
        fontSize: 12,
        color: "#7D7D7D",
        display: "flex",
        flexDirection: "row",

        justifyContent: 'left',
        fontFamily: 'Roboto Light',
        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
            justifyContent: 'center',
            fontWeight: 600
        }
    },
    rowItemFontType: {
        fontSize: 12,
        color: "#7D7D7D",
        display: "flex",
        flexDirection: "row",
        float: "left",

        justifyContent: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
            justifyContent: 'center',
            float: 'none'
        }
    },
    iconsContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            marginTop: "10px",
            borderBottom: "1px solid grey"
        }
    },
    paginationContainerTiles: {
        '& .MuiPaginationItem-root': {
            [theme.breakpoints.down('sm')]: {
                padding: '0 3px'
            }

        }
    },
    imgContainer: {
        display: "inline-block",
        [theme.breakpoints.down('sm')]: {
            paddingTop: '10px'
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        // padding: 20,
        padding: 4,
        paddingLeft: 0,
        paddingRight: 0,
        width: '100%',
        // paddingBottom: 12,
        alignItems: "center",
        background: "transparent",
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',

        }
    },
    headerContainer: {
        alignItems: "center",
        paddingLeft: "0px",
        paddingRight: "0px",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "10px",
            justifyContent: "center"


        }
    },
    matricsContainer: {
        marginTop: '10px',
        fontFamily: 'Roboto Light',
        [theme.breakpoints.down("sm")]: {
            display: 'none',


        }
    },
    matricsContainerM: {
        // display: 'none',
        width: '100%',
        display: 'none',
        fontFamily: 'Roboto Light',
        [theme.breakpoints.down("sm")]: {
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'center',
            marginTop: '10px'


        }
    },
    productImage: {
        objectfit: 'contian',
        maxHeight: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            border: '1px solid grey',
            borderRadius: '5px',
            padding: '10px'
        }
    },
    createNewAdButton: {
        float: "right"
    },
    mobileEditIconsContainer: {
        display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "flex-end"
        }
    },
    storeIconForDesktop: {

        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    lastShownDate: {
        [theme.breakpoints.down('sm')]: {
            display: "none !important"
        }
    },
    creativeLoader: {
        display: 'block',
        width: '100%',
        textAlign: 'center'
    },
    creativeInvisible: {
        display: 'none',
        width: '100%',
        textAlign: 'center'
    },

}))

export default useStyle;