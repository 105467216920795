import React from 'react'
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Chip, Grid, InputAdornment, TextField } from "@material-ui/core";
import { useSelectMultipleStyle } from './useStyles';

const SelectMultiple = ({ options, data, setData, labelKey }) => {
    const classes = useSelectMultipleStyle()
    const handleChange = (e, v) => setData(v);
    const deleteChip = i => e => setData([...data.filter((_, j) => j !== i)])

    return (
        <Grid container className={classes.main}>
            <Grid item xs={12}>
                <Autocomplete
                    id="combo-box-demo"
                    multiple
                    fullWidth
                    options={options}
                    value={data ? data : []}
                    onChange={handleChange}
                    className={classes.autoComplete}
                    getOptionLabel={(option) => {
                        const label = Array.isArray(option)
                            ? option[0][labelKey]
                            : option[labelKey];
                        return label;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder={"Search"}//lang && lang.search_product}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                classes: { notchedOutline: classes.noBorder },
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} className={classes.chipContainer}>
                {data?.map((item, i) => (
                    <Chip
                        key={i}
                        size="medium"
                        className={classes.chip}
                        label={item[labelKey]}
                        onDelete={deleteChip(i)}
                    />
                ))}
            </Grid>
        </Grid>
    )
}

export default SelectMultiple