import React from 'react'
import { Button, Container } from '@material-ui/core'
import verifyImg from 'assets/verify.svg';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'redux/snackbarStore';

const ShowVerifyComp = () => {

    const dispatch = useDispatch()

    const sendVerificationLink = () => {
        axios.post(process.env.REACT_APP_BASE + "/auth/verify", { userId: localStorage.getItem('user') })
            .then(response => {
                dispatch(openSnackbar("Verification email sent successfully!", "success"));
            }).catch(err => {
                dispatch(openSnackbar("Failed to send the email! Please try again", "error"));
            })
    }

    return (
        <>
            <Container style={{ textAlign: "center" }}>
                <img
                    alt=''
                    src={verifyImg}
                    style={{ width: "30%", marginBottom: "15px", marginTop: "15vh" }}
                />
                <h2> Verify your Email To Continue</h2>
                <Button
                    onClick={sendVerificationLink}
                    style={{ marginTop: 15, background: "#0077B6", color: "#fff" }}
                >
                    Verify Email
                </Button>
            </Container>
        </>
    )
}

export default ShowVerifyComp