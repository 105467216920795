const actions = {
  UPLOAD: "UPLOAD",
  UPLOAD_LOGO: "UPLOAD_LOGO",
  UPLOAD_BRAND_LOGO: "UPLOAD_BRAND_LOGO",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  SELECT_TEMPLATE: "SELECT_TEMPLATE",
  UPDATE_FORM: "UPDATE_FORM",
  UPDATE_DETAIL: "UPDATE_DETAIL",
  CLEAR_FORM: "CLEAR_FORM",
  GET_LIST: "GET_LIST",
  UPDATE_MARKETPLACE: "UPDATE_MARKETPLACE",

  GetMarketData: data => {
    return {
      type: actions.UPDATE_MARKETPLACE,
      data
    };
  },
  GetList: data => {
    return {
      type: actions.GET_LIST,
      data
    };
  },
  ClearForm: () => {
    return {
      type: actions.CLEAR_FORM
    };
  },
  UpdateDetail: data => {
    return {
      type: actions.UPDATE_DETAIL,
      data
    };
  },
  UpdateForm: data => {
    return {
      type: actions.UPDATE_FORM,
      data
    };
  },
  Upload: data => {
    return {
      type: actions.UPLOAD,
      data
    };
  },
  UploadLogo: data => {
    return {
      type: actions.UPLOAD_LOGO,
      data
    };
  },
  UploadBrandLogo: data => {
    return {
      type: actions.UPLOAD_BRAND_LOGO,
      data
    };
  },
  UploadImage: data => {
    return {
      type: actions.UPLOAD_IMAGE,
      data
    };
  },
  SelectTemplate: data => {
    return {
      type: actions.SELECT_TEMPLATE,
      data
    };
  }
};

export default actions;
