// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
// import {globalHistory} from '@reach/router';
import { ConfigProvider } from "antd";
import store from "./redux/store";
import Admin from "./routes/admin";
// import StoreInfo from './routes/storeinfo.js';
import ProtectedRoute from "./components/utilities/protectedRoute";
import config from "./config/config";
import Auth from "./routes/auth";
import "./static/css/style.css";
// import PublishedTheme from "container/dashboard/OnePageStore/components/theme/PublishedTheme";
import { pageView } from "container/analytics";
import VerifyEmail from "container/dashboard/verifyEmail";
import ForgotPassword from "container/profile/authentication/overview/resetPassword";
import { loadUser } from "redux/users/actionCreator";
import MySnackbar from "./components/Snackbar/Snackbar.js";
// import Forgot from "container/profile/authentication/overview/Forgot";

const { theme } = config;

const ProviderConfig = () => {
  const dispatch = useDispatch();
  // const currentPath = location.pathname + location.search;
  //  analytics.sendPageview(currentPath);
  // console.log("firing it",currentPath)
  pageView();
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });

  // const [path, setPath] = useState(window.location.pathname);

  // const setFirstLanguage = (dispatch) => {
  //   const jsonData = JSON.stringify(languageData["en"]);
  //   dispatch(setLang(jsonData, "India"));
  // };

  // useEffect(() => {
  //   setFirstLanguage()
  // },[])

  // eslint-disable-next-line
  useEffect(() => { dispatch(loadUser()) }, [])

  // useEffect(() => {
  //   const loadData = () => {
  //     const base = process.env.REACT_APP_BASE
  //     axios.get(base + "/auth/" + localStorage.getItem("user"))
  //       .then((response) => {
  //         if (response.data && response.data.businessDetails) {
  //           let newformdata = response.data.businessDetails;
  //           if (newformdata.subscription && newformdata.subscription.name !== 'Start' && newformdata.subscription.status === 0) {
  //             let config = {
  //               method: "PUT",
  //               url: process.env.REACT_APP_BASE + "/business/update/" + newformdata._id,
  //               data: { subscription: null }
  //             };
  //             axios(config)
  //               .then((response) => { loadData() })
  //               .catch((err) => {
  //                 dispatch(openSnackbar("An error occured!", "error"));
  //                 window.location.reload();
  //               });
  //             return;
  //           }
  //         }
  //       })
  //       .catch((err) => { console.log(err); })
  //   }
  //   loadData();
  // }, [])

  // useEffect(() => {
  //   let unmounted = false;
  //   if (!unmounted) {
  //     // setPath(window.location.pathname);
  //   }
  //   // eslint-disable-next-line no-return-assign
  //   console.log("inside", window.location.pathname)
  //   return () => (unmounted = true);
  // }, [setPath]);
  let test = window.location.pathname.slice(0, 15) === "/one-page-store";

  

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router>
          <Switch>
            {/* //<RouteChange> */}
            {/* {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/admin" component={Admin} />} */}
            {/* {(!isLoggedIn || isLoggedIn === undefined) &&
              !test &&
              window.location.pathname !== "/verify" &&
              window.location.pathname !== "/reset" && (
                <Route path="/auth" component={Auth} />
              )} */}
            {/* {!isLoggedIn &&
              <Route exact path="/auth" component={Auth} />
            } */}
            <Route exact path="/verify" component={VerifyEmail} />
            <Route exact path="/reset" component={ForgotPassword} />
            <ProtectedRoute path="/admin" component={Admin} />
            {/* <Route exact path="/pricing" component={Pricing} /> */}

            {isLoggedIn && window.location.pathname === "/auth/signup" && <Redirect to='/admin/pricing' />}

            {isLoggedIn &&
              (window.location.pathname === "/auth"
                || window.location.pathname === "/"
                // || window.location.pathname === "/auth/signup"
              ) &&
              !test && <Redirect to="/admin" />}
            {/* {window.location.pathname === "/" && <Redirect to="/auth" />} */}
            <Route exact path="/auth" component={Auth} />
            <Route path="/"><Redirect to="/auth" /></Route>
            {/* <Route path="/*"><Redirect to="/auth" /></Route> */}
            {/* <Route
              exact
              path="/:name"
              component={PublishedTheme}
            /> */}

            {/* </RouteChange> */}
          </Switch>
        </Router>
        <MySnackbar />
      </ThemeProvider>
    </ConfigProvider>
  );
};
// function usePageViews() {
//   // let location = useLocation()

//   // useEffect(() => {
//   //   //ga.send(['pageview', location.pathname]);
//   //   console.log(location.pathname);
//   // }, []);
// }
function App() {
  // usePageViews();
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

// export default App;
export default hot(App);