import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Upgradeplan = ({ open, onClose }) => {
    const history = useHistory()
    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle style={{ paddingBottom: 0 }}>
                    <span >Upgrade Plan!</span>
                </DialogTitle>
                <DialogContent style={{ paddingBottom: '32px' }} >
                    <span>You need to upgrade your plan to have access to this Template.</span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} style={{ border: '1px solid #00BABA', textTransform: 'none', padding: '4px 32px' }}>Not now</Button>
                    <Button onClick={() => history.push('/admin/pricing')} style={{ backgroundColor: '#00BABA', color: 'white', textTransform: 'none', padding: '4px 32px' }}>Upgrade</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Upgradeplan