import { makeStyles } from "@material-ui/core";

export const useSelectMultipleStyle = makeStyles(theme => ({
    main: {
        padding: '4px'
    },
    autoComplete: {
        width: "90%",
        margin: "auto",
        marginBottom: 8,
        backgroundColor: 'white',
    },
    noBorder: {
        border: "none",
        height: 37,
        margin: "auto",
        borderRadius: 5,
        paddingLeft: 10,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    },
    chipContainer: {
        width: "90%",
        margin: "auto",
        display: "flex",
        flexWrap: "wrap",
        maxHeight: '90px',
        overflowY: 'scroll',
        paddingLeft: '44px',
    },
    chip: {
        height: '22px',
        marginRight: 8,
        marginBottom: 8
    },
}))