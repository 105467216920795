export const monthlyCost = (amt, curr = currencies[0]) => `${curr.symbol || curr.id}${curr.multiplier * amt}/mo`

export const selectedPlanStyles = {
    rootStyles: {
        border: '2px solid #00BABA',
        borderColor: "#00BABA",
    },
    buttonStyles: {
        backgroundColor: "#00BABA",
    },
    button: "Select Plan"
}

export const currentPlanStyles = {
    buttonStyles: {
        backgroundColor: "#00BABA",
    },
    button: "Current Plan"
}

export const plans = [
    {
        rootStyles: {
            backgroundColor: "#FFFFFF",
            color: "#676767",
            borderColor: "#969696",
        },
        id: "Start",
        name: "Start",
        level: 1,
        cost: "Free",
        costamount: 0,
        desc: "I am a small store",
        button: "Get Started",
        buttonUp: "Upgrade",
        buttonStyles: {
            backgroundColor: "#44BCFF",
            color: "#FFFFFF",
            borderColor: "#C4C4C4",
        },
        shopAdsPerMonth: "5",
        opsPerMonth: "5",
        visitsPerMonth: "500",
        reports: "Basic Reports",
        payments: "-",
        chats: "-",
        insights: "-"
    },
    {
        rootStyles: {
            backgroundColor: "#FFFFFF",
            color: "#676767",
            borderColor: "#969696",
        },
        id: "Rise",
        name: "Rise",
        level: 2,
        cost: ('750K'),
        costamount: 750000,
        desc: "My business is picking up",
        button: "Get Started",
        buttonUp: "Upgrade",
        buttonStyles: {
            backgroundColor: "#44BCFF",
            color: "#FFFFFF",
            borderColor: "#C4C4C4",
        },
        shopAdsPerMonth: "20",
        opsPerMonth: "20",
        visitsPerMonth: "20,000",
        reports: "Advance Reports",
        payments: "Domestic",
        chats: "-",
        insights: "-"
    },
    {
        rootStyles: {
            backgroundColor: "#FFFFFF",
            color: "#676767",
            borderColor: "#969696",
        },
        id: "Grow",
        name: "Grow",
        level: 3,
        cost: ('2M'),
        costamount: 2000000,
        desc: "Looking for new customers",
        button: "Get Started",
        buttonUp: "Upgrade",
        buttonStyles: {
            backgroundColor: "#44BCFF",
            color: "#FFFFFF",
            borderColor: "#C4C4C4",
        },
        shopAdsPerMonth: "30+ Custom themes & DCO active",
        opsPerMonth: "30+ Custom themes & DCO active",
        visitsPerMonth: "50,000",
        reports: "Advance Reports",
        payments: "Domestic",
        chats: "Enabled",
        insights: "-"
    },
    {
        rootStyles: {
            backgroundColor: "#FFFFFF",
            color: "#676767",
            borderColor: "#969696",
        },
        id: "Pro",
        name: "Pro",
        level: 4,
        cost: ('7.5M'),
        costamount: 7500000,
        desc: "My customers want more",
        button: "Get Started",
        buttonUp: "Upgrade",
        buttonStyles: {
            backgroundColor: "#44BCFF",
            color: "#FFFFFF",
            borderColor: "#C4C4C4",
        },
        shopAdsPerMonth: "50+ Custom themes & DCO active",
        opsPerMonth: "50+ Custom themes & DCO active",
        visitsPerMonth: "Unlimited",
        reports: "Advance Reports + 1P Data Collection (Via Google & FB tags)",
        payments: "Global",
        chats: "Enabled",
        insights: "Competition, Discount impact, Marketshare, sales uplift analysis"
    },
    {
        rootStyles: {
            backgroundColor: "#969696",
            color: "#F3F3F3",
            borderColor: "#969696",
            marginInline: "0.6em",
        },
        id: "Enterprise",
        name: "Enterprise",
        level: 5,
        cost: "Lets talk",
        costamount: -2,
        desc: "Mail us at Sales@modcart.io",
        button: "Get in touch",
        buttonUp: "Get in touch",
        buttonStyles: {
            backgroundColor: "#FFFFFF",
            color: "#000000",
            borderColor: "#C4C4C4",
        },
        shopAdsPerMonth: "50+ Custom themes & DCO active",
        opsPerMonth: "50+ Custom themes & DCO active",
        visitsPerMonth: "Unlimited",
        reports: "Advance Reports + 1P Data Collection (Via Google & FB tags)",
        payments: "Global",
        chats: "Enabled",
        insights: "Competition, Discount impact, Marketshare, sales uplift analysis"
    },
]

export const industryTypes = [
    { name: "Fashion" },
    { name: "Beauty" },
    { name: "Electronics" },
    { name: "Fashion Accessories" },
    { name: "Home Furnishing" },
    { name: "Food" },
    { name: "Grocery" },
    { name: "Toys" },
    { name: "Medical" },
    { name: "Nutrition" },
    { name: "Baby Nutrition" },
    { name: "Cat food" },
    { name: "Dog food" },
    { name: "Fish food" },
    { name: "CPG" },
    { name: "Others" },
]

export const marketplaceTypes = [
    { name: "Shopee" },
    { name: "Tokopedia" },
    { name: "Lazada" },
    { name: "BliBli" },
    { name: "Shopify" },
    { name: "Tiki" },
    { name: "Sendo" },
    { name: "Bukaplak" },
    { name: "Flipkart" },
    { name: "Amzaon" },
    { name: "Neon" },
    { name: "Daraz" },
    { name: "Myntra" },
    { name: "Others" },
]

export const currencies = [
    { id: "USD", label: "USD - US", multiplier: 1, symbol: "$" },
    { id: "IDR", label: "IDR - Indonesia", multiplier: 1 },
    { id: "INR", label: "INR - India", multiplier: 1 },
    { id: "MYR", label: "MYR - Malaysia", multiplier: 1 },
    { id: "SGD", label: "SGD - Singapore", multiplier: 1 },
    { id: "AED", label: "AED - UAE", multiplier: 1 },
    { id: "Dong", label: "Dong - Vietnam", multiplier: 1 }
]

export const plansBadge =
    [
        {},
        {
            name: 'Start',
            color: '#ffffff',
            backgroundColor: '#0077B6',
        },
        {
            name: 'Rise',
            color: '#000000',
            backgroundColor: '#55FF85',
        },
        {
            name: 'Grow',
            color: '#ffffff',
            backgroundColor: '#E74657',
        },
        {
            name: 'Pro',
            color: '#000000',
            backgroundColor: '#FFB628',
        },
    ]
// {
//     Start: {
//         id: "Start",
//             name: "Start",
//                 cost: 0,
//                     shopAdsPerMonth: 5,
//                         opsPerMonth: 5,
//                             level: 1,
//     },
//     Rise: {
//         id: "Rise",
//             name: "Rise",
//                 cost: 50,
//                     shopAdsPerMonth: 20,
//                         opsPerMonth: 20,
//                             level: 2,
//     },
//     Grow: {
//         id: "Grow",
//             name: "Grow",
//                 cost: 135,
//                     shopAdsPerMonth: "30+ Custom themes & DCO active",
//                         opsPerMonth: "30+ Custom themes & DCO active",
//                             level: 3,
//     },
//     Pro: {
//         id: "Pro",
//             name: "Pro",
//                 cost: 500,
//                     shopAdsPerMonth: "50+ Custom themes & DCO active",
//                         opsPerMonth: "50+ Custom themes & DCO active",
//                             level: 4,
//     },
//     Enterprise: {
//         id: "Enterprise",
//             name: "Enterprise",
//                 cost: -2,
//                     shopAdsPerMonth: "50+ Custom themes & DCO active",
//                         opsPerMonth: "50+ Custom themes & DCO active",
//                             level: 5,
//     },
// }