import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles({
  icon: {
    padding: 6,
    border: 20,
    backgroundColor: "#FF1111",
    color: "white",
    borderRadius: 20,
    width: 40,
    height: 40,
    margin: "auto",
  },
  root: {
    textAlign: "center",
    padding: 40,
    paddingBottom: 0,
  },
});

function DeleteAdModal({ open, onClose, deleteFunc }) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.root}>
        <Delete className={classes.icon} />
        <Typography variant="h5">
          You are about to delete a Shoppable Ad
        </Typography>
        <DialogContentText id="alert-dialog-description">
          This will delete your Shoppable Ad from database
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={deleteFunc} variant="contained" color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAdModal;
