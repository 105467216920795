import axios from "axios";
import FeatherIcon from "feather-icons-react";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import Gravatar from "react-gravatar";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setLang } from "redux/lang/action";
import languageData from "utility/lang.json";
import { srcToCDN } from "utility/utility";
import { logOut } from "../../../redux/authentication/actionCreator";
import india from "../../../static/img/flag/india.png";
import indonesia from "../../../static/img/flag/indonesia.svg";
import phillipines from "../../../static/img/flag/philli.png";
import vietnam from "../../../static/img/flag/vietnam.png";
import { Dropdown } from "../../dropdown/dropdown";
import Heading from "../../heading/heading";
import { Popover } from "../../popup/popup";
import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";

const AuthInfo = (props) => {
  const { lang: language } = props;
  const [hide, setHide] = useState(false);
  const dispatch = useDispatch();
  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut());
  };
  const lang = localStorage.getItem("lang");
  const flag = localStorage.getItem("country");

  useEffect(() => {
    if (lang && lang !== null && lang !== undefined && !props.lang.lang) {
      dispatch(setLang(lang, flag && flag !== undefined ? flag : "Indonesia"));
    }
    if (!lang) {
      const jsonData = JSON.stringify(languageData["en"]);
      dispatch(setLang(jsonData, "Indonesia"));
    }
    // eslint-disable-next-line
  }, [dispatch, lang, props.lang]);

  var token = localStorage.getItem("access_token");
  if (token) var decoded = jwt_decode(token);

  const [logo, setLogo] = React.useState(null);
  const [loading, setLoading] = useState(true);

  const getLogo = async () => {
    try {
      let { data } = await axios.get(
        process.env.REACT_APP_BASE + "/auth/" + localStorage.getItem("user")
      );

      if (data.businessDetails && data.businessDetails.logo)
        setLogo(data.businessDetails.logo);
      setLoading(false);
    } catch {
      setLoading(false);
      setLogo(null);
    }
  };

  // const history = useHistory()

  // const handlePopOver = (path) => () => {
  //   // dispatch({ type: "BUSINESS_TOGGLE", profile: true });
  //   history.push(path)
  //   setHide(false);
  // };

  useEffect(() => {
    getLogo();
  }, []);

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {!logo && !loading && <Gravatar email={decoded?.email} />}
          {logo && !loading && (
            <img
              src={srcToCDN(logo)}
              alt='logo'
              style={{ height: "42px", width: "auto" }}
            />
          )}
          <figcaption style={{ width: "150px", wordWrap: "break-word" }}>
            <Heading as="h5">{decoded?.email}</Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li style={{ cursor: 'pointer' }}
          //  onClick={handlePopOver("/admin/business-profile")}
          >
            <a href="/admin/business-profile">
              <FeatherIcon icon="user" /> Business Profile
            </a>
          </li>
          <li style={{ cursor: 'pointer' }}
          // onClick={handlePopOver("/admin/pricing")}
          >
            <a href="/admin/pricing">
              <FeatherIcon icon="dollar-sign" /> Pricing
            </a>
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, country) => {
    dispatch(setLang(JSON.stringify(value), country));
  };

  const country = (
    <NavAuth>
      <Link
        onClick={() => onFlagChangeHandle(languageData["en"], "Indonesia")}
        to="#"
      >
        <img style={{ width: 25 }} src={indonesia} alt="" />
        <span>English</span>
      </Link>
      <Link
        onClick={() => onFlagChangeHandle(languageData["en"], "India")}
        to="#"
      >
        <img style={{ width: 25 }} src={india} alt="" />
        <span>English</span>
      </Link>

      <Link
        onClick={() => onFlagChangeHandle(languageData["indo"], "Indonesia")}
        to="#"
      >
        <img style={{ width: 25 }} src={indonesia} alt="" />
        <span>Bahasa</span>
      </Link>
      <Link
        onClick={() => onFlagChangeHandle(languageData["en"], "Phillipines")}
        to="#"
      >
        <img style={{ width: 25 }} src={phillipines} alt="" />
        <span>English</span>
      </Link>
      <Link
        onClick={() => onFlagChangeHandle(languageData["en"], "Vietnam")}
        to="#"
      >
        <img style={{ width: 25 }} src={vietnam} alt="" />
        <span>English</span>
      </Link>
    </NavAuth>
  );

  const countryFlag = (country) => {
    switch (country) {
      case "India":
        return india;
      case "Indonesia":
        return indonesia;
      case "Phillipines":
        return phillipines;
      case "Vietnam":
        return vietnam;

      default:
        return indonesia;
    }
  };

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link
            to="#"
            className="head-example"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              style={{ width: 35, marginRight: 5, height: "100%" }}
              src={countryFlag(flag)}
              alt=""
            />{" "}
            <span style={{ marginTop: 0 }}>
              {language && language.lang !== null
                ? language.lang.language_title
                : ""}
            </span>
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author" style={{ marginRight: '4px', height: "40px", width: "40px", maxHeight: '42px', maxWidth: '42px', aspectRation: 1, borderRadius: "50%" }}>
        <Popover
          placement="bottomRight"
          content={userContent}
          action="click"
          hide={hide}
          setHide={setHide}
        >
          <Link to="#" className="head-example" >
            {!logo && !loading && (
              <Gravatar
                email={decoded?.email}
                style={{ height: "35px", width: "auto", maxHeight: '35px', maxWidth: '35px', minHeight: '35px', objectFit: 'contain', borderRadius: "50%" }}
              />
            )}
            {logo && !loading && (
              <img
                alt='logo'
                src={logo}
                style={{ height: "42px", width: "auto", maxHeight: '42px', maxWidth: '42px', minHeight: '42px', objectFit: 'contain', borderRadius: "50%" }}
              />
            )}
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(AuthInfo);
