import { SET_ALL_THEME, SELECT_THEME, SELECT_THEME_VARIANT } from "./action.types";
const initstate = {
  selected: 0,
  variant: 0,
  version: 1
};

export default function themeReducer(state = initstate, action) {
  const { type, payload, version } = action;
  // console.log("<== reducer", action)
  switch (type) {
    case 'RESET_STATE': return {
      selected: 0,
      variant: 0,
      version: 1
    }
    case SET_ALL_THEME:
      return { ...state, themes: [...payload] };
    case SELECT_THEME:
      return { ...state, selected: payload, variant: 0, version: version };
    case SELECT_THEME_VARIANT: return { ...state, variant: payload }
    default:
      return state;
  }
}

/*
{
  "_id": "62a305bcc191396550dfd343",
  "isDev": false,
  "themeName": "Nestle Theme",
  "variant": "1",
  "type": "",
  "category": "",
  "subCategory": "",
  "imageUrl": [
    {
      "_id": "620684567934255cdc6aecdb",
      "themeColor": "#0588CA",
      "discountColor": "#F8E646", 
      "priceColor": "#F8E646",
      "bgColor": "#0588CA",
      "imageUrl": "https://modcart-public.s3.us-east-2.amazonaws.com/lactogrow_theme_background.png",
      "fontDescription": "Poppins",
      "fontDescriptionColor": "#000000",
      "fontTitle": "Poppins",
      "fontTitleColor": "#000000",
      "shareB": "#ffffff"
    }
  ],
  "defaultTheme": "https://modcart-public.s3.us-east-2.amazonaws.com/lactogrow_theme_background.png",
  "version": "9",
  "deleted": false,
  "deletedAt": null,
  "__v": 0
}
*/

// const dummyTheme = {
//   "_id": "620684567934255cdc6aecda",
//   "isDev": false,
//   "themeName": "Nestle Theme 2",
//   "variant": "1",
//   "type": "",
//   "category": "",
//   "subCategory": "",
//   "imageUrl": [
//     {
//       "_id": "620684567934255cdc6aecdb",
//       "themeColor": "#FED500",
//       "discountColor": "#EC001D",
//       "priceColor": "#000000",
//       "bgColor": "#FED500",
//       "imageUrl": "https://modcart-public.s3.us-east-2.amazonaws.com/theme11variant1.png",
//       "fontTitle": "Poppins",
//       "fontTitleColor": "#030B8F",
//       "shareB": "#ffffff",
//       "optionsBackground": "#F71C16",
//       "selectionColor": "#FFFFFF",
//       "optionsText": '#000000',
//       "ratingColor": "#040B90"
//     },
//     {
//       "_id": "620684567934255cdc6aecdb",
//       "themeColor": "#E0CEB0",
//       "discountColor": "#EC001D",
//       "priceColor": "#000000",
//       "bgColor": "#E0CEB0",
//       "imageUrl": "https://modcart-public.s3.us-east-2.amazonaws.com/theme11variant2.png",
//       "fontTitle": "Poppins",
//       "fontTitleColor": "#040B90",
//       "shareB": "#ffffff",
//       "optionsBackground": "#040B8F",
//       "selectionColor": "#F4D86D",
//       "optionsText": '#000000',
//       "ratingColor": "#F71C15"
//     },
//   ],
//   "defaultTheme": "https://modcart-public.s3.us-east-2.amazonaws.com/theme11.png",
//   "version": "10",
//   "deleted": false,
//   "deletedAt": null,
//   "__v": 0
// }