import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import cardImage1 from "../../../assets/card11.svg";

const ShoppableEmpty = () => {
  // const [t, st] = React.useState(false);

  return (
    <>
      <div style={{ margin: 15 }}>
        <Grid container>
          <Grid md={6} style={{ textAlign: "center", paddingTop: "30vh" }}>
            <Typography
              variant="h4"
              style={{ fontWeight: "bolder", color: "#59C3FF" }}
            >
              There are no Ads
              <br /> created Yet!
            </Typography>
            <br />
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", color: "#6F6F6F" }}
            >
              Quickly Create one
            </Typography>
            <Link to={`/admin/shoppable`}>
              <Button
                variant="contained"
                style={{
                  marginTop: 15,
                  padding: "10px 40px",
                  background: "#0077b6",
                }}
                color="primary"
              >
                Let's Get Started
              </Button>
            </Link>
          </Grid>
          <Grid md={6}>
            <img src={cardImage1} style={{ width: "80%" }} alt='' />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ShoppableEmpty;
