const initstate = [];
const productData = {};
export function ProductData(state = initstate, action) {
  const { type, payload } = action;
  switch (type) {
    case "PRODUCT_DATA":
      return [...payload];
    default:
      return [...state];
  }
}
export function SelectedProduct(state = productData, action) {
  const { type, payload } = action;
  switch (type) {
    case "RESET_STATE":
      return {};
    case "PRODUCT_SELECTED":
      return { ...state, products: payload };
    case "THEME_INDEX":
      return { ...state, themeIndex: payload };
    case "DATA_TITLE":
      return { ...state, title: payload };
    case "DATA_DESCRIPTION":
      return { ...state, description: payload };
    case "DATA_LOGO":
      return { ...state, logo: payload };
    default:
      return { ...state };
  }
}
