import axios from "axios";
import { base } from "redux/form/actionCreator";
export const SET_PUBLISHED_DATA = 'SET_PUBLISHED_DATA'
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const SET_PRICE_DATA = 'SET_PRICE_DATA'

export const getPublishedData = (params) => async dispatch => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await axios.get(`${base}/product/show?DomainId=${params}`)
        dispatch({ type: STOP_LOADING })
        return dispatch({ type: SET_PUBLISHED_DATA, payload: data.result[0] })
    } catch (error) {
        dispatch({ type: STOP_LOADING })
        console.log(error.message)
    }
}

export const setPriceData = data => dispatch => dispatch({ type: SET_PRICE_DATA, payload: data })