import React, { useEffect, useState } from "react";
import useStyle from "../useStyle";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Typography, Grid } from "@material-ui/core";
import moment from "moment";
import { get } from "lodash";
import axios from "axios";
import ConfirmationModal from "components/ConfirmationModal";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import {
  getShoppableListNew,
  //  loadMoreData
} from "redux/shoppableList/action";
import {
  EditOutlined,
  // ShareOutlined,
  Delete,
  VisibilityOutlined,
  Storefront,
  BarChart,
  Facebook,
  Instagram,
} from "@material-ui/icons";
// import downloadLogo from "../../../../../assets/download.svg";
import DeleteAdModal from "components/DeleteAdModal";
// import { saveAs } from "file-saver";
// import UrlImageDownloader from "react-url-image-downloader";
import ViewModal from "./ViewModal";
import AnalyticsModal from "./Analytics";
import { srcToCDN } from "utility/utility";
// var download = require("file-download");

const StoreListItem = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [view, setView] = useState(false);
  const [analytics, setAnalytics] = useState(false);
  const lang = useSelector((state) => state.lang.lang);

  // const [state, setState] = useState({
  //   heading: "",
  //   url: "",
  // });

  const deleteAd = async (id) => {
    axios.post(process.env.REACT_APP_BASE + `/shoppable/delete/` + id).then(response => {
      dispatch(getShoppableListNew(1))
    }).catch(err => {
      console.log(err);
    })
    // try {
    //   let response = await axios.post(
    //     process.env.REACT_APP_BASE + `/shoppable/delete/` + id
    //   );
    //   axios.post(process.env.REACT_APP_BASE + `/shoppable/delete/` + id).then(response=>{
    //     dispatch(getShoppableListNew(1))
    //   }).catch(err=>{
    //     console.log(err);
    //   })

    //   // console.log(response.data);
    // } catch (err) {
    //   console.log(err);
    // }
  };

  // const history = useHistory();
  const toggleDeleteModal = () => setOpen(!open);
  const deleteItem = async (id) => {
    try {
      setLoading(true);
      // await deleteOnePageStore(props.item._id);
      // await deleteDomain(props.item.domain._id);
      await deleteAd(props?.item?._id);
      setLoading(false);
      toggleDeleteModal();
      setConfirm(true);

      // dispatch(getOnePageStore());
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const downloadImg = (data) => {
  //   var element = document.createElement("a");
  //   var file = new Blob([data], { type: "image/*" });
  //   element.href = URL.createObjectURL(file);
  //   console.log(file);
  //   element.download = "shoppable-ad";
  //   element.click();

  //   var url = data;

  //   var options = {
  //     directory: "",
  //     filename: "ad.png",
  //   };
  //   download(url, options, function (err) {
  //     if (err) throw err;
  //     console.log("meow");
  //   });
  // };

  const [totalClicks, setTotalClicks] = useState(0);

  const sum = () => {
    var tc = 0;
    // console.log(clicks);
    if (props?.item?.trackingId) {
      // console.log(clicks);
      // eslint-disable-next-line
      props?.item?.marketPlaces.forEach(
        (market) =>
        (tc += parseInt(
          props?.item?.trackingId.sites[0][market.marketPlaceNumber]
        ))
      );
    }
    setTotalClicks(tc);
    // return tc;
  };

  useEffect(() => {
    sum();
    // eslint-disable-next-line
  }, [props]);

  return (
    <Grid container item className={classes.storeItem}>
      <Loader show={loading} />

      <ConfirmationModal
        open={confirm}
        onClose={() => {
          setConfirm(false);
          props.onDelete(true);
        }}
        text="Shoppable Ad deleted successfully"
      />

      <ViewModal
        open={view}
        onClose={() => {
          setView(false);
        }}
        image={props?.item?.previewImage ? props.item.previewImage : null}
        link={props?.item?.scriptLink ? props.item.scriptLink : null}
        marketPlaces={props?.item?.marketPlaces}
      // text="Shoppable Ad deleted successfully"
      />

      <AnalyticsModal
        open={analytics}
        onClose={() => {
          setAnalytics(false);
        }}
        clicks={props?.item?.trackingId ? props.item.trackingId.sites[0] : null}
        markets={props?.item?.marketPlaces ? props.item.marketPlaces : []}
        trackingId={props?.item?.trackingId}
      // text="Shoppable Ad deleted successfully"
      />

      <DeleteAdModal
        open={open}
        onClose={toggleDeleteModal}
        deleteFunc={() => deleteItem(props.item._id)}
      />
      <Grid item xs={12} className={classes.mobileEditIconsContainer}>
        <Link to={`/admin/shoppable/edit/${props?.item?._id}`}>
          <IconButton
            className={classes.storeIcons}
            style={{ background: "#E1E1E1" }}
          >
            <EditOutlined style={{ fontSize: 20 }} />
          </IconButton>
        </Link>
        <IconButton
          onClick={toggleDeleteModal}
          style={{ background: "#E1E1E1" }}
          className={classes.storeIcons}
        >
          <Delete style={{ fontSize: 20 }} />
        </IconButton>
      </Grid>
      <Grid
        item
        md={2}
        sm={1}
        xs={6}
        className={classes.productImage}
      >
        <img alt='' src={srcToCDN(get(props?.item, props?.item?.previewImage ? "previewImage" : "product.productImage[0]", null))} className={classes.productImg} />
      </Grid>
      <Grid item md={8} sm={10} xs={6}>
        <Grid item md={12} className={classes.adTitle}>
          <Typography className={classes.adTitleDetail}>
            {props?.item?.campaignName}</Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item md={12} xs={12} >
            &nbsp;
            {/* <Typography
              style={{ fontSize: 12, color: "#7D7D7D", display: "inline" }}
            >
              Last Updated: {moment(props.item.createdAt).format("DD/MM/YYYY")}
            </Typography> */}
          </Grid>

        </Grid>
        <Grid container direction="row" className={classes.matricsContainer}>
          <Grid item md={2} xs={6}>
            <Grid md={6} sm={12}>
              <Typography
                className={classes.rowItemFont1}
              >
                {props?.item?.previewImage ? (
                  <>
                    <Facebook style={{ fontSize: 12 }} />/
                    <Instagram style={{ fontSize: 12 }} />
                  </>
                ) : (
                  "Eskimi"
                )}

              </Typography>
            </Grid>
            <Grid md={6} sm={12}>

              <Typography
                className={classes.rowItemFontType}
              >
                {props?.item?.shopTheme ? props.item.shopTheme.size : ""}
              </Typography>
            </Grid>
            {/* <Typography
                className={classes.rowItemFont}
              >
                {props.item.previewImage ? (
                  <>
                    <Facebook style={{ fontSize: 12 }} />/
                    <Instagram style={{ fontSize: 12 }} />
                  </>
                ) : (
                  "Eskimi"
                )}
                : {props.item.shopTheme ? props.item.shopTheme.size : ""}
              </Typography> */}
          </Grid>
          <Grid item md={2} xs={6} >
            <Grid sm={12} md={12}>
              <span
                className={classes.rowItemFont}
              >
                {lang && lang.Impressions}

              </span>
            </Grid>
            <Grid sm={12} md={12}>
              <Typography
                className={classes.rowItemFontContent}
              >

                {props?.item?.trackingId ? props.item.trackingId.views : 0}
              </Typography>
            </Grid>

          </Grid>
          <Grid item md={2} sm={6} xs={6}>
            <Grid md={12} sm={12}>
              <label
                className={classes.rowItemFont}
              >
                {lang && lang.totalclicks}
              </label>
            </Grid>
            <Grid md={12} sm={12}>
              <Typography
                className={classes.rowItemFontContent}
              >
                {totalClicks}
              </Typography>
            </Grid>
            {/* <Typography
               className={classes.rowItemFont}
            >
              {lang && lang.totalclicks}: {totalClicks}
            </Typography> */}
          </Grid>
          <Grid item md={3} sm={6} xs={6}>
            <Grid md={12} sm={12}>
              <label
                className={classes.rowItemFont}
              >
                {lang && lang.click_through_rate}

              </label>
            </Grid>
            <Grid md={12} sm={12}>
              <Typography
                className={classes.rowItemFontContent}
              >
                {props?.item?.trackingId
                  ? ((totalClicks / props.item.trackingId.views) * 100).toFixed(2)
                  : (0).toFixed(2)}
                %
              </Typography>
            </Grid>

          </Grid>
        </Grid>


      </Grid>
      <Grid container className={classes.matricsContainerM}>
        <Grid item xs={6} className={classes.matrixGridITem}>
          <Grid md={12} sm={12}>
            <Typography
              className={classes.rowItemFont1}
            >
              {props?.item?.previewImage ? (
                <>
                  <Facebook style={{ fontSize: 12 }} />/
                  <Instagram style={{ fontSize: 12 }} />
                </>
              ) : (
                "Eskimi"
              )}

            </Typography>
          </Grid>
          <Grid md={12} sm={12} >
            <Typography
              className={classes.rowItemFontType}
            >
              {props?.item?.shopTheme ? props.item.shopTheme.size : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.matrixGridITem}>
          <Grid sm={12} md={12}>
            <span
              className={classes.rowItemFont}
            >
              {lang && lang.Impressions}

            </span>
          </Grid>
          <Grid sm={12} md={12}>
            <Typography
              className={classes.rowItemFontContent}
            >

              {props?.item?.trackingId ? props.item.trackingId.views : 0}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.matrixGridITem}>
          <Grid md={12} sm={12}>
            <label
              className={classes.rowItemFont}
            >
              {lang && lang.totalclicks}
            </label>
          </Grid>
          <Grid md={12} sm={12}>
            <Typography className={classes.rowItemFontContent}>
              {totalClicks}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.matrixGridITem}>
          <Grid md={12} sm={12}>
            <label
              className={classes.rowItemFont}
            >
              {lang && lang.click_through_rate}

            </label>
          </Grid>
          <Grid md={12} sm={12}>
            <Typography
              className={classes.rowItemFontContent}
            >
              {props?.item?.trackingId
                ? ((totalClicks / props.item.trackingId.views) * 100).toFixed(2)
                : (0).toFixed(2)}
              %
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        md={2}
        sm={2}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >

        <Typography
          style={{ fontSize: 12, color: "#7D7D7D", display: "flex", alignItems: 'flex-end', justifyContent: "flex-end", marginRight: "20px", fontFamily: 'Roboto Light' }} className={classes.lastShownDate}
        >
          Last Updated: {props?.item?.createdAt ? moment(props.item.createdAt).format("DD/MM/YYYY") : ""}
        </Typography>

        <div className={classes.iconsContainer}>
          {props?.item?.onePageStoreLink && (
            <a
              href={props?.item?.onePageStoreLink ? props.item.onePageStoreLink : "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                // onClick={toggleDeleteModal}
                style={{ background: "#E1E1E1" }}
                className={classes.storeIcons}
              >
                <Storefront style={{ fontSize: 20 }} />
              </IconButton>
            </a>
          )}
          <IconButton
            size="small"
            onClick={() => setView(true)}
            className={classes.storeIcons}
          >
            <VisibilityOutlined style={{ fontSize: 20 }} />
          </IconButton>

          <IconButton
            size="small"
            onClick={() => setAnalytics(true)}
            className={classes.storeIcons}
          >
            {/* <a download href={props.item.previewImage ? props.item.previewImage : ""}> */}
            <BarChart style={{ fontSize: 20 }} />
            {/* </a> */}
            {/* <UrlImageDownloader imageUrl="http://modcart-public.s3.amazonaws.com/CampaignDescription1634115921875.png" /> */}
          </IconButton>
          {/* </a> */}
          <Link to={`/admin/shoppable/edit/${props?.item?._id}`}>
            <IconButton
              className={[classes.storeIconForDesktop, classes.storeIcons]}
              style={{ background: "#E1E1E1" }}
            >
              <EditOutlined style={{ fontSize: 20 }} />
            </IconButton>
          </Link>
          <IconButton
            onClick={toggleDeleteModal}
            style={{ background: "#E1E1E1" }}
            className={[classes.storeIconForDesktop, classes.storeIcons]}
          >
            <Delete style={{ fontSize: 20 }} />
          </IconButton>
        </div>
      </Grid>
    </Grid>
  );
};

export default StoreListItem;
